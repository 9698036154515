.drop-down-option {
  display: inline-block;
  margin: 0 -1rem;
  position: relative;
  ul {
    position: absolute;
    top: 100%;
    box-shadow: 0 3px 12px 1px rgba(44,43,63,.1), 0 0 0 1px rgba(44,43,63,.13);
    margin-top: 8px;
    border-radius: .2rem;
    border-top-right-radius: 0;
    display: none;
    transition: nth($transitions, 2);
    padding-right: 1rem;
    background: #fff;
    z-index: 2;
    margin: 0;
    padding: 0;
  }
  .centered-list{
    text-align: center;
    width: 100%;
    text-transform: uppercase;
  }
  li{
    margin: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    color: #000;
    line-height: 2rem;
    transition: nth($transitions, 3);
    position: relative;
    top: 1px;
    cursor: pointer;
    white-space: nowrap;
    a{
      color: #000;
      white-space: nowrap;
    }
    &:first-letter {
      text-transform: uppercase
    }
    &:hover{
      background-color: $gray-88;
    }
  }
  &__left{
    right: 1rem;
  }
  &:hover ul {
    display: block;
  }
  .main-page-options{
    font-size: 1.3rem;
  }
  .menu__dropdown-group{
    padding: 0;
    line-height: 1.5rem;
    &:hover{
      background-color: transparent;
    }
    .group-name{
      padding: .3rem 1rem;
      background-color: #f1f1f1;
      display: block;
      font-size: .7rem;
      font-weight: 500;
      cursor: default;
    }
    .group-options{
      display: flex;
      flex-direction: column;
      div{
        padding: .3rem 5rem;
        text-align: start;
        font-size: .8rem;
        text-transform: uppercase;
        &:not(:first-child) {
          border-top: 1px solid #f5f5f5;
        }
        &:hover{
          background-color: rgba(241, 241, 241,.5);
        }
      }
    }
  }
  &:hover {
    .drop-down-option__left {
      right: 2rem;
    }
    .drop-down-option__right {
      left: 2rem;
    }
  }
  .icon-menu{
    padding: 2rem;
  }
}
