.newsletter-area__left {
    background-color: #f9f9f9;
    border-left: 1px solid rgb(199, 199, 199);
    height: calc(100vh - 64px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 42px;

    .tool-header {
        position: fixed;
        z-index: 3;
        width: 34%;
        top: 64px;
    }

    .layout-item__is-over {
      .counter {
        animation: shake 0.7s cubic-bezier(.36,.07,.19,.97) infinite;
      }
    }
    .layout-item {
        position: relative;
        margin: 1rem 1rem 2rem;
        cursor: pointer;
        .drop-down-option {
            margin: 0;
            &:hover {
                .drop-down-option__left {
                    right: 0;
                }
            }
        }
        &__img {
            position: relative;
            display: flex;
            width: 100%;
            overflow: hidden;
            border: 1px solid;
            align-items: flex-start;
            img {
                box-shadow: 0px 1px 6px -5px rgb(0, 0, 0);
                flex-grow: 1;
                width: 0;
            }
            &:hover {
                .select-overlay {
                    z-index: 0;
                    opacity: .7;
                }
            }
            &.speaker-layout{
                background: white;
                img {
                    box-shadow: none;
                }
                &.padding-x-9 {
                    padding: 0 9rem;
                }
                &.padding-x-5 {
                    padding: 0 5rem;
                }
                &.padding-x-2 {
                    padding: 0 2rem;
                }
            }
        }
        .drop-down-option:hover .drop-down-option__left{
            top: inherit;
        }
        .icon-options-vertical {
            font-size: 1.5rem;
            &:hover {
                top: inherit;
            }
        }
        img {
            border-radius: .2rem;
        }
        .counter {
            display: flex;
            background-color: $pickled-bluewood;
            padding: .8rem;
            position: absolute;
            z-index: 2;
            border-radius: 50%;
            width: 2rem;
            height: 2rem;
            text-align: center;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 700;
            top: -.8rem;
            left: -.8rem;
            font-size: 1.2rem;
        }
        &:hover {
            .counter {
                animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
            }
        }
        .colspan-container {
            justify-content: space-around;
            padding-bottom: .5rem;
            .colspan-item {
                border: 1px solid;
                width: 2rem;
                text-align: center;
                font-weight: 700;
                cursor: pointer;
                background-color: transparent;
            }
            .colspan-item.active {
                background-color: white;
                &:hover {
                    background-color: $pickled-bluewood;
                    color: #fff;
                }
            }
            .colspan-item.disabled {
                cursor: not-allowed;
                opacity: .8;
            }
        }
    }
    .layout-selected {
        .counter {
            background-color: #18a0fb;
        }
        .layout-item__img {
            border: 3px solid #18a0fb;
        }
    }
    &.is-fixed {
        position: fixed;
        overflow: auto;
        top: 0;
        height: 100vh;
    }
    .drop-down-option {
        margin: auto 0;
        cursor: pointer;
        &:hover ul{
            top: 80%;
        }
    }
}
.templates-settings__body {
    .template {
        height: 30rem;
    }
}

.newsletter-template {
    .template-selector {
        background: #FAFBFB;
        border: 1px solid #EEF6FF;
        box-sizing: border-box;
        border-radius: 0 0 4px 4px;
        padding: 2rem 1rem;
        .slick-list .slick-slide {
            max-width: calc(100%/3);
            div {
                outline: none;
            }
        }
        .template-slider {
            position: relative;
            box-shadow: 0 13px 21px -5px rgba(0, 0, 0, 0.3);
            height: 25rem;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            &:hover {
                animation-name: bg-scroll-up-down;
                animation-duration: 10s;
                animation-direction: alternate;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                .template-title {
                    opacity: 0;
                }
            }
            .template-title {
                position: absolute;
                bottom: -3px;
                right: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.5);
                color: white;
                opacity: 1;
                transition: opacity .3s;
                height: 3rem;
                padding: 1rem 0;
                text-transform: uppercase;
                font-size: 0.9rem;
                text-align: center;
                font-weight: 600;
            }
        }
        .template-item-selected {
            border: 3px solid #55b9e3;
            animation: growing .3s alternate .3 ease-in;
        }
    }
    &.templates-settings__body {
        overflow: auto;
        height: 100%;
    }
}

.flex-dir-row {
  margin-left: 64px;
}
