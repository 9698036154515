.custom-creatable {
  &__input {
    background: none !important;
    border: none !important;
    padding: 0;
    margin: 0;
    max-width: calc(100% - 6px);
    height: 28px;
  }
}
