.ebox-explorer {
    background: #F1F2F4;
    border-radius: 4px;
    min-height: 156px;
    padding-left: 50px;
    align-items: center;

    &__search {
        position: relative;
        display: flex;
        flex-grow: 2;
        align-items: center;
        background:#FFFFFF;
        border: 1px solid#F8F9FA;
        box-sizing: border-box;
        border-radius: 5px;
        height: 44px;
        justify-content: space-between;
        padding: 10px;
        z-index: 1;
        .react-autosuggest__container {
            position: unset;
            width: 100%;
            border: none;
        }
        .react-autosuggest__suggestions-container--open {
            position: absolute;
            left: 0;
            right: 0;
            padding: 0;
            min-height: 37px;
            z-index: 1;
            .react-autosuggest__suggestions-list .react-autosuggest__suggestion {
              padding: 0;
            }
        }
        input, input:focus {
            border: none;
            box-shadow: none;
        }

        .icon {
            font-size: 20px;
            color: $slate-gray;
        }
        .loading-suggestion {
            position: absolute;
            text-align: center;
            padding: .5rem;
            background-color: #fff;
            right: 0;
            left: 0;
        }
    }

    &__details {
        width: 40%;
        text-align: center;
        transition: width ease-in .5s;
        &.fill {
            width: 60%;
        }
        img, .empty-avatar {
            border-radius: 50%;
            background: rgba(109, 127, 146, 0.5);
            background-blend-mode: normal;
            border: 5px solid #FFFFFF;
            width: 156px;
            height: 156px;
      }
      .ebox-owner {
          display: flex;
          &__avatar {
            margin: 0 40px;
          }
          &__name {
            color: #29394D;
            font-weight: 500;
            font-size: 16px;
            text-transform: capitalize;
            margin: 10px 0 0;
            text-align: left;
          }
          &__data {
            flex-grow: 1;
          }
          &__info {
              margin: 0;
                li {
                    display: flex;
                    margin: 5px 0;
                    div {
                        display: flex;
                        flex-direction: column;
                        padding: 0 0 0 14px;
                        margin: 0 0 0 14px;
                        border-left: 1px solid rgba(109, 127, 146, 0.2);
                        span {
                            margin-right: 4px;
                            text-align: left;
                            width: fit-content;
                        }
                    }
                    & > span:first-child {
                        text-transform: capitalize;
                        text-align: right;
                        width: 20%;
                    }
                    .flag-icon {
                        margin-right: 7px;
                    }
                }
          }
      }
    }
}