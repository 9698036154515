.header-details {
  margin: 7rem 0 0;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background:#F3FAFF;
  border-radius: 5px;
  padding: 30px;
  transform: translateY(-50%);
  h4 {
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: underline;
    a {
      color: #fff;
    }
  }
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #29394D;
    span {
      text-transform: capitalize;
      margin-right: 1rem;
    }
  }
  h2 {
    color: #3C4E64;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 1rem 0 0;
  }
  .badge {
    font-size: 10px;
    color: #fff !important;
    padding: 4px;
    border-radius: 4px;
    margin-left: .5rem;
    display: inline-block;
    &.success{
      background-color: rgb(44, 162, 95);
    }
    &.warning{
      background-color: #FFAF10;
    }
  }
}