.banner{
  position: relative;
  color: #2495E1;
  background-color: #F3FAFF !important;
  font-size: 14px !important;
  font-weight: normal !important;
  padding: 4px 0 0 !important;
  width: 100%;

  .banner-close {
    position: absolute;
    right: 1rem;
    cursor: pointer;

    svg {
      path {
        fill: #6d7f92;
      }
    }
  }

  .title {
    font-weight: bold;
  }
}
