.text-model-add-button {
  background: $dodger-blue;
  font-size: 3rem;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.25);
  color: #fff;
  border-radius: 55%;
  height: 3.7rem;
  width: 3.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 10;
  transition: transform .3s;

  &:hover {
    transform: scale(1.06) rotate(-90deg);
  }

  i {
    cursor: pointer;
  }
}
