@import "./theme-manager";
@import "./theme-actions";
@import "./theme-item";
@import "./theme-category";
@import "./filter/theme-category-filter";

#template-page {
  min-height: calc(100vh - 154px);

  .ttp-align-center{
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .checkbox .ttp-checkbox{
    border-color: $main-color;
  }
  .checkbox input[type="checkbox"]:checked + .ttp-checkbox {
    border-color: #2095F0;
  }
  .icon-options-vertical{
    font-size: 1.2rem;
  }
  .template-list{
    line-height: 1.5rem;
    margin-bottom: 2rem;
    &__item{
      position: relative;
      text-align: center;
      padding: 0;
    }
  }

  .add-settings{
    margin-bottom: 1.3rem;
    text-transform: uppercase;
    float: right;
    .icon{
      padding-right: .2rem;
    }
  }
  .table-header{
    border-top: 1px solid #e0e0e0;
    padding: .6rem 0;
    border-bottom: 1px solid #e0e0e0;
    text-transform: uppercase;
    background-color: $main-light-gray;
    font-weight: 700;
  }

  .manage-lists-btn{
    display: inline-block;
    span{
      line-height: 1.875rem;
      display: inline-block;
      vertical-align: middle;
      height: 1.875rem;
      width: 1.875rem;
      margin-right: 5px;
      text-align: center;
      border: 1px solid #C3BCBC;
      border-radius: 50%;
      &:hover{
        background-color: #373C44;
        color: #ffffff;
      }
    }
  }
  .theme-category__filter {
    margin-top: 3.5rem;
  }
}

.templates-settings{
  &__body{
    color: #000;
    margin-bottom: 6rem;
    .radio label{
      display: flex;
    }
    h3{
      font-size: 1.4rem;
      color: $slate-gray;
      text-align: center;
      margin-bottom: 1.5rem;
    }
    .radio .ttp-radio{
      top: 0;
    }
    .radio input[type=radio]{
      width: 0;
      margin: 0;
    }
    .user-suggestion{
      padding-top: 0rem;
      padding-left: .5rem;
      padding-right: .5rem;
      margin-left: 18px;
      .react-autosuggest__container{
        border: none;
        input {
          margin: 0;
          box-shadow: none;
          font-size: .9rem;
          border: none;
          height: auto;
          background-color: #FFF;
        }
      }
    }
    .Select-control{
      height: 12px;
    }
  }
  &__item{

    h4{
      font-size: 1rem;
      letter-spacing: .01rem;
    }
    h5{
      font-size: 14px;
    }
    margin: .5rem auto;
    padding: .5rem 1rem;
    background-color: #fcfcfc;
  }
  &__footer{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    border-top: 1px solid #ddd;
    .secondary {
      margin-left: 0;
    }
  }
}

#template-page {
  &.in-modal {
    overflow: scroll;
    max-height: 100%;
  }
}
