.add-block-side-bar {
    position: fixed;
    top: 69px;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    overflow-y: scroll;
    padding: 3rem 0 0;
    background-color: #f9f9f9;
    border-left: 1px solid #c7c7c7;
    transform: translateX(100%);
    transition: transform .5s ease-in;

    &.display {
        transform: translateX(0);
    }

    &__header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        padding: 10px 0px;
        background-color: #eaeaea;
        color: #293039;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        .icon {
            cursor: pointer;
            font-size: 1rem;
            font-weight: 700;
            width: 10%;
        }
    }
}
