.auto-suggestion-loader {
  .react-autosuggest__suggestions-container--open {
    .react-autosuggest__suggestions-list {
      &::after {
        content: '';
        font-size: 45px;
        position: sticky;
        bottom: 0;
        width: 100%;
        height: 40px;
        display: block;
        text-align: center;
        line-height: 15px;
        color: #18a0fb;
        background-color: white;
        animation: loading 1000ms infinite
      }
    }
  }

}

.react-autosuggest__suggestions-container--open {
  padding: 0;
  z-index: 4;
  background-color: #fff;
  max-height: 400px;
  box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1), 0 0 0 1px rgba(44, 43, 63, 0.13);
  overflow: scroll;
  border-radius: 0 0 3px 3px;

  .react-autosuggest__suggestions-list {
    margin: 0;
    max-height: 13rem;

    .react-autosuggest__suggestion {
      padding: 1rem;

      &:hover, &--highlighted {
        background-color: $main-light-gray;
        cursor: pointer;
      }
    }
  }
}

.react-autosuggest__container {
  position: relative;

  input {
    margin: 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: $slate-gray;

    &::placeholder {
      color: $slate-gray;
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
  }

  .loading-suggestion {
    padding: 8px;
    position: relative;

    .loader {
      position: absolute;
      top: 20px;
      right: 20px;
      transform: scale(.4);
    }
  }
}

@keyframes loading {
  0% {
    content: '';
  }
  25% {
    content: '\2024';
  }
  50% {
    content: '\2024' '\2024';
  }
  100% {
    content: '\2024' '\2024' '\2024';
  }
}
