.checkbox {
  margin-bottom: .5rem;
}
.checkbox label, .radio label{
  display: block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1;
}

.checkbox input[type="checkbox"], .radio input[type="radio"]{
  top: 0;
  left: 0;
  visibility: hidden;
}
.checkbox .ttp-checkbox{
  display: inline-block;
  vertical-align: top;
  margin: 0 6px 0 0;
  width: 18px;
  height: 18px;
  border: 1px solid #1583cc;
  position: relative;}
.checkbox .ttp-checkbox {
  background: #1583cc;
  box-shadow: inset 0 0 0 12px #fff;
  top: -2px; }
.checkbox .icon-check , .checkbox .icon-indeterminate {
  width: 20px;
  height: 20px;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0);
  transition: all 180ms ease;
  &:before {
  position: absolute;
  width: 12px;
  font-size: 12.66667px;
  text-align: center;
  line-height: 16px;
  color: #fff;
  font-weight: bold; }
}
.icon-indeterminate:before{
  content: '\2014';
}
.checkbox .icon-check:before{
  content: '\2713';
}
.checkbox input[type="checkbox"]:checked + .ttp-checkbox {
  border-color: #2095F0;
  box-shadow: none; }
.checkbox input[type="checkbox"]:checked + .ttp-checkbox .icon-check , .checkbox .icon-indeterminate{
  transform: scale(1);
}
.checkbox .disabled, .radio .disabled{
  border: 1px solid #2199e8;
  opacity: 0.35;
  cursor: not-allowed;
  pointer-events: none; }

 .radio .ttp-radio {
  background: #FAFBFB;
  border-radius: 50%;
  transition: all 180ms ease;
  top: -2px; 
  display: inline-block;
  vertical-align: top;
  margin: 0 6px 0 0;
  width: 20px;
  height: 20px;
  position: relative;
  border: 3px solid #F1F1F1;
}
.radio input[type="radio"]:checked + .ttp-radio {
  background: #18A0FB;
}
