.item__header {
  background: #F8F9FA;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  padding-left: 1rem;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #6D7F92;

  .app-icon {
    color: #6D7F92;
    height: 14px;
    margin-right: 6px;
  }

  .app-name {
    text-transform: uppercase;

    i {
      margin-right: 8px;
    }
  }

  .scope {
    background: #FFFFFF;
    border-radius: 50px;
    padding: 4px 12px;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &__status {
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-right: 8px;
    }

    .scope__status.public {
      background: #62DEB0;
    }

    .scope__status.private {
      background: #FF5656;
    }
  }

  &.fetching {
    .app-name {
      height: 20px;
      width: 120px;
    }
    .scope {
      height: 20px;
      width: 80px;
    }
  }
}
