.tab-container {
    height: 100%;
    padding: 2rem 2rem;
    overflow-y: auto;

    .tab-header {
        padding: 1rem 0 0.5rem;
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        color: #29394d;
        text-transform: uppercase;
    }

    .team-list {
        display: flex;
        flex-direction: column;
        margin-top: 18px;
        margin-bottom: 3rem;

        &-item {
            cursor: pointer;
            margin: .3rem;

            &__name {
                font-size: 1.2rem;
                color: #29394D;
            }

            .param-label {
                text-indent: 0;
            }

            p {
                color: #505050;
                margin: 0;
            }

            .team-header {
                border-top: 1px solid #E1E4E8;
                border-bottom: 1px solid #E1E4E8;

                .icon {
                    padding-right: 0.2rem;
                    font-size: .6rem;
                }

                &--members {
                    font-size: .7rem;
                    padding: 0 .5rem;
                    justify-content: flex-end;
                    color: var(--dark-1, #29394D);
                    text-align: right;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 2.2;

                    &-avatars {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: center;

                        .small-avatar {
                            background-size: cover;
                            vertical-align: middle;
                            position: relative;
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 2px solid white;
                            margin-left: -8px;
                            cursor: default;
                            background-color: gray;

                            &:hover {
                                z-index: 1;
                            }
                        }

                        .empty-avatar span {
                            font-size: 1rem;
                        }
                    }

                    .icon {
                        font-size: 1.3rem;
                    }
                }

                .team-avatar {
                    display: inline-block;
                    color: #fff;
                    text-align: center;
                    margin: 10px 1px 10px 1px;
                    width: 44px;
                    height: 44px;
                    font-size: 1.8rem;
                    line-height: 2.8rem;
                    text-transform: uppercase;
                    border-radius: 50%;
                    font-weight: bold;
                }

            }
        }

        &-item--open {
            .team-setting {
                padding: 1.5rem 1rem;
                height: auto;
                overflow: auto;
                background-color: #fff;
            }
        }
    }

    .version-select>.ttp-select__control {
        border: 0;
        outline: 0;
        border-radius: 30px;
        background-color: #6D7F92;
        color: #FFF !important;
        padding-left: 8px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.24px;

        .ttp-select__single-value {
            color: white !important;

            .ttp-select__control {
                border-color: #6D7F92;
                box-shadow: 0 0 0 1px #6D7F92;

            }
        }
    }

    .fixed-loader {
        top: 60% !important;
    }
    
}
.btn-upload {
    display: inline-flex;
    padding: 9px;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px dashed #B2BCC6;
    background: #F8F9FA;
    cursor: pointer;
  
    .svg-upload {
      width: 13.5px;
      height: 9.75px;
      flex-shrink: 0;
      fill: #6D7F92; // var(--dark4)
    }
  
    .label-text {
      color: #6D7F92; // var(--dark4)
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  
    &:hover {
      border: 1px dashed #29394D; // var(--dark-1)
      background: #F1F2F4; // var(--gris-3)
    }
  }
  .btn-clear {
    position: absolute ;
    top: 18px ;
    right: 25px;
    transform: translateY(-50%) ;
    background: none;
    border : none ;
    cursor: pointer ;
    font-size: 16px ;
    color: rgb(254, 55, 69); ;
  }
  