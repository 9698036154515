@import "add_buttons";

#blocks-tool {
  overflow: hidden;
  .tool-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(234, 234, 234);
    text-align: center;
    color: rgb(41, 48, 57);
    margin: 0;
    height: 42px;
    h5 {
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        margin: .5rem;
        cursor: pointer;
        padding: 5px;
    }
    &__item {
      border: .01rem solid #fff;
      border-top: none;
      border-bottom: none;
      transition: all .3s ease-out;
      flex: 1;
      &.active {
        background-color: #18A0FB;
        color: #fff;
      }
    }

    &__progress-bar {
      display: flex;

      span {
        padding: 0 2rem;
      }

      .sh-progress {
        &-content {
          width: 14rem;
          border-right: none;
          margin-right: 2rem;
          padding: 4px 0 0 1rem;
        }

        &-status {
          background-color: #29394d;
          border-radius: 5px;
          &-value {
            border-radius: 5px;
          }
        }

        &-value2 {
          top: -10%;
          left: 15rem;
        }

        &-value1 {
          margin-bottom: -1px;
        }
        &-value1, &-value2 {
          color: unset;
        }
      }
    }
  }

  .select-overlay {
      position: absolute;
      display: flex;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0.8;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s all ease-in-out;

    .apply-button {
      height: 100%;
      top: calc(100% + 32px);
      pointer-events: none;
      background: linear-gradient(45deg, rgba(199,232,254,0.6) 0%, rgba(24,160,251,0.5) 100%);
      a {
        font-size: 16px;
        font-weight: 500;
        color: white;
        top: 36px;
        display: none;
      }
      .apply-icon {
        top: 0;
        transform: translate(-50%,-50%);
      }
    }
  }


  .selected {
    .select-overlay {
      .apply-button {
        top: 0 !important;
        .apply-icon {
          top: 50%;
        }
      }
    }
  }

  .selected{
    transform: scale(1.04);
    .select-overlay {
      opacity: 1;
      .apply-button {
        .apply-icon {
          width: 62px;
          height: 62px;
        }
      }
    }
  }

  .top-scroller, .hide-selected {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    position: fixed;
    background-color: #fff;
    left: 0;
    box-shadow: 6px 1px 12px 10px rgba(0, 0, 0, 0.12);
    transition: 0.2s all ease-in-out;
    color: #B9B9B9;
    bottom: 1%;
    z-index: 3;
    text-align: center;
    cursor: pointer;
    display: flex;
    span {
      margin: auto;
      font-size: 1.5rem;
      color: black;
    }
  }
  .hide-selected {
    bottom: 4rem;
  }
}

.blocks-container {
    max-width: 100%;
    position: fixed;
    right: 0;
    left: 0;
    top: 64px;
    bottom: 0;
    z-index: 0;

    #feeds-container {
      overflow-x: hidden;

      .feeds-list {
        display: flex;
        flex-wrap: wrap;
      }
    }

    #cd-filter-horizontal-container {
      &.is-fixed {
        &.ttp-medium-9 {
          .list__filter-wrapper {
            max-width: 66.5%;
          }
        }
      }
    }

  .masonry-separator {
    height: 2px;
    margin: 4px 0 10px 0;
    background: #F1F2F4;
    border-radius: 2px;
    order: 500;
  }
}
