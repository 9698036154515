$ep-toolbar-height : rem-calc(40px);
$ep-padding-top: $ep-toolbar-height + 2rem;

.text_area{
  width: 500px;
}

#campaign-text {
  background: #fff;
  z-index: 2;
  .campaign-text {
    &__left {
      transition: nth($transitions, 3);
      position : relative;
      .object-container {
        //margin: 5rem 0 1rem;
      }
      .body-container {
        padding: .5rem 1rem;
        min-height: 8rem;
        .public-DraftStyleDefault-block {
          margin: 0;
          color: #3C4E64;
        }
      }
    }
  }

  .tool-bar {
      position: absolute;
      left: 0px;
      width: 100%;
      min-width: 590px;
      z-index: 3;
      top: 0;
      line-height: $ep-toolbar-height;
      color: $h-color;
      text-align: center;
      span {
        cursor: pointer;
        text-transform: uppercase;
      }

      &__popup-container{
        position: relative;
      }
  }

  .fixed-bar{
      position: fixed !important;
      width:  inherit;
      max-width: inherit;
      left: 0;
  }
  .dnd-preview-container {
    iframe {
      margin: .3rem auto;
      padding: .3rem;
      width: 100%;
      height: 100%;
      background: #F3FAFF;
      border: 1px solid #f3faff;
      border-radius: 4px;
      pointer-events: none;
    }
  }
}

.object-container {
  margin-bottom: 1rem;
  .subject-box {
    margin: 0;
    box-shadow: none;
    font-size: 16px;
    line-height: 19px;
    color: $pickled-bluewood;
    padding: .5rem 1rem;
    resize: none;
    &.empty-box {
      border: 1px solid #F1F2F4;
    }

    &:focus {
      background: #FFFFFF;
      border: 1px solid #18A0FB;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    }
  }
}

#campaign-text .toolbar {
  position: absolute;
  top: 0;

  &.fixed-bar {
    right: 0;
    z-index: 1;
    justify-content: center;
  }
}
