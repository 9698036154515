#footer {
  font-family: $main-body-font;

  & > * {
    max-width: inherit;
  }

  .main-footer {
    background-color: #F8F9FA;
    color: #6D7F92;
    font-weight: 600;

    & > * {
      align-items: center;
      justify-content: center;
      display: flex;

      &:not(:last-child) {
        border-right: 1px solid #F1F2F4;
      }
    }

    h3 {
      font-weight: 800;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
    p {
      font-weight: 400;
      font-size: 14px;
    }
    a {
      color: $footer-color;
    }
    ul {
      margin: 0;
      display: flex;
      justify-content: center;
    }
    .footer__contact {
      li {
        margin-bottom: 1rem;
      }
      i {
        font-size: 1.8rem;
        margin: 0 8px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        color: #6d7f9280;
        cursor: pointer;
        &:hover {
          color: #4198d8;
        }
      }
    }
    .footer__apps {
      li {
        display: flex;
        margin-bottom: 1rem;
        & > span {
          background-color: $footer-color;
          color: $footer-bg-color;
          height: 3rem;
          width: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.3rem;
          border-radius: 50%;
          margin-right: 1rem;
        }
      }
      &-name {
        display: flex;
        flex-direction: column;
        a {
          font-weight: 800;
          font-size: 1rem;
          margin-bottom: 2px;
        }
        span {
          font-size: .8rem;
          opacity: 0.8;
        }
      }
    }
  }
}

.copyright {
  background-color: #F1F2F4;
  color: #6d7f9280;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  p {
    margin: 0;
  }
}
