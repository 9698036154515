#mailing-lists-form {
  .sidebar {
    width: 535px;
    right: 0;
    padding: 48px 24px;
    margin-top: 2.7rem;
    background-color: $athens-gray-lighter;
    overflow: hidden;

    &__close {
      position: absolute;
      top: 0;
      font-size: medium;
      right: 0;
      cursor: pointer;
    }

    &__header {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #6D7F92;
    }

    &__form {
      .param__title {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 19px;
        color: #3c4e64;
        display: block;
        text-indent: 1.2rem;
        margin: 1rem 0 .3rem;
      }

      .switch {
        display: flex;

        .switch-container {
          margin: 1rem;
        }
      }

      .buttons-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-top: 1.5rem;
        margin-top: 5rem;

        &:after {
          position: absolute;
          height: 1px;
          background: #EAEDF0;
          content: '';
          top: 610px;
          width: 460px;
        }

        button {
          border-radius: 5px;
          margin: 0;

          &:first-child {
            border: 1px solid rgba(109, 127, 146, 0.5);
            color: #6D7F92;
            background: transparent;
            min-width: 126px;
          }

          &.primary {
            margin-left: .6rem;
          }
        }
      }
      .empty-box {
        background: white;
        border: 1px solid #B2BCC6;
      }

      .filled-box {
        border: 1px solid #18A0FB;
      }

      .simple-tabs {
        width: 94%;
        margin: 1rem 0 1rem 1rem;;

        &__info {
          font-size: 12px;
          line-height: 14px;
          color: #6D7F92;
          margin: 2rem 0 0 1rem;
        }

        &__content {
          display: block;
        }
      }
    }

    .row-top {
      padding-top: 2rem;
    }
  }
}
