.react-tagsinput {
  padding: 0;
  border: none;
}

.react-tagsinput--focused {
  border-color: $main-medium-gray;
}

a.react-tagsinput-remove {
  font-size: 1.2rem;
  color: #2495E1;
}

.react-tagsinput-input {
  margin-bottom: 0;
}

.react-tagsinput-tag {
  align-items: center;
  border-radius: 3px;
  border: none;
  color: $tag-color;
  font-family: $tag-font-family;
  background-color: $tag-bg-color;
  margin: 0.3rem .1rem;
  padding: $tag-padding;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.8rem;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  &.alert {
    background: #FFD5D5;
    color: $persimmon;
    opacity: .4;
    a {
      color: $persimmon;
    }
  }
}
