#campaign-recipent__tab {
  .recipient-filters {
    margin: 0 0 2.5rem;
  }
  .payment-type__option {
    text-transform: capitalize;
    color: #29394D;
    font-weight: normal;
    font-size: 14px;

    i {
      width: 20px;
      display: inline-block;
      height: 20px;
      color: #fff;
      border-radius: 50%;
      text-align: center;
      margin-left: 10px;
      font-weight: 700;
    }

    .order {
      background: #03B593;
    }

    .invoice {
      background: #2495E1;
    }

    .credit_note {
      background: #A5AFBA;
    }

    .manual {
      background: #326a71;
    }
  }

  .slider-container {
    display: block ruby;
    margin: 2rem 0 1rem 1rem;

    h1 {
      text-indent: 0;
      padding-right: 2rem;
      display: block ruby;
    }

    .input-range {
      width: 87%;
    }
    .input-range__track--active {
      background: #2495E1;
    }

    .input-range__slider {
      background: #2495E1;
      border: 1px solid #2495E1;
    }
  }
}

.email-tags__input {
  border: 1px solid;
  min-height: 8rem;
  border: 1px solid #F1F2F4;
  box-sizing: border-box;
  border-radius: 4px;
  .react-tagsinput-tag {
    margin: .1rem;
  }
  input, input:focus{
    border: none;
    box-shadow: none;
  }
  &.empty-box input:focus {
    background: #FAFBFB;
  }
  &.light.empty-box input:focus {
    background: #fff;
  }
  &.light, &.light input {
    background: #fff;
  }
  &.light {
    min-height: 20rem;
  }
}
