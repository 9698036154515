.theme__item {
  position: relative;
  overflow: hidden;
  padding: 0;
  z-index: 0;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
  border-radius: 4px;
  height: 290px;
  width: 220px;
  margin: .5rem auto 2rem;
  &__preview {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    border-radius: 4px;

    background-color: gainsboro;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% auto;
    overflow: hidden;
  }

  .masked {
    border: 3px solid #F3FAFF;
  }

  &__status {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 0px 0px 4px 4px;
    position: absolute;
    top: calc(100% - 74px);
    height: 100%;
    width: 100%;
    transition: all 300ms ease-in;
    padding: 0.8rem 1rem;

    .item-name {
      line-height: 21px;
      color: $pickled-bluewood;
      margin-bottom: 1rem;
      text-align: left;
      font-weight: normal;
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 140px;
      word-break: break-all;
      
      .item-lng {
        position: absolute;
        right: 14px;
        top: 12px;
      }
    }

    .item-tags {
      font-family: Roboto;
      font-style: italic;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #6D7F92;
      display: flex;

      .item-tag {
        background: #F1F2F4;
        border-radius: 2px;
        padding: 3px 8px;
        margin-right: 6px;
        display: block;
        white-space: nowrap;
        overflow: hidden;

        i {
          margin-right: 4px;
        }
      }
    }

    .item-avatar {
      margin-top: .5rem;
      display: flex;
      align-items: center;
      font-family: Roboto;
      font-style: normal;
      font-size: 12px;
      line-height: 114.69%;
      color: #3C4E64;

      img,
      .empty-avatar {
        width: 44px;
        height: 44px;
        border: 1px solid #F1F2F4;
        box-sizing: border-box;
        border-radius: 50%;
        margin-right: 12px;
      }

      span {
        display: block;
        text-align: left;
      }

      span:first-child {
        font-weight: 600;
      }
    }

    .theme-actions {
      top: 8.2rem;
    }
  }

  .theme__item__actions {}

  &:hover {
    .theme__item__status {
      top: 0;

      .item-name {
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
      }
    }

    .apply-button {
      bottom: 0;
    }
  }

  &.in-generic-emailing {
    .theme__item__preview {
      top: 44px;
      height: calc(100% - 44px);
    }
    .theme__item__status {
      top: calc(100% - 44px);
      height: calc(100% - 44px);
    }

    &:hover {
      .theme__item__status {
        top: 44px;
      }

      .apply-button {
        bottom: 0;
      }
    }
  }

  &.fetching {
    .item__header {
      .app-name {
        width: 80px;
        height: 20px;
        display: inline-block;
      }
      .scope {
        width: 70px;
        height: 20px;
      }
    }
    .item-name {
      width: 100%;
      display: inline-block;
      height: 22px;
      margin-bottom: 2px;
    }
    
    .item-tag {
      width: 60px;
      height: 20px;
      display: inline-block;
    }
  }
}
.theme__item-container {
  flex: 0 0 20%;
}
