.approval-response {
  margin: 3rem auto;
  width: 60%;
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
  textarea {
    box-shadow: none;
  }
  .attachments-container .icon-trash {
    margin-left: .5rem;
  }
  .accept-block, .approval-warning {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding: 1rem;
    span{
      text-transform: uppercase;
    }
    .icon {
      margin-right: .5rem;
      font-size: 1.2rem;
    }
    h6{
      margin: 1rem;
    }
    a {
      color: #fff;
    }
  }
  .accept-block {
    background-color: #dff4e5;
    color: #36bd5f;
  }
  .approval-warning {
    background: #FCFAF2;
  }
  .buttons-block {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
  img {
    height: 3rem;
    margin-bottom: 1rem;
  }
}