.fields-selector {
  margin: 0 3rem 2rem;
  background: #FAFBFB;
  height: 100%;
  &__items {
    display: flex;
    flex-wrap: wrap;
    span {
      margin: .4rem;
      background: #F3FAFF;
      border: 1px solid #C7E8FE;
      padding: 10px 15px;
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 3px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #29394D;
      cursor: pointer;
      &:hover {
        box-shadow: none;
        background: #2495E1;
        color: #fff;
      }
    }
  }
}
