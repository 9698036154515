$btn-info-color: #18A0FB;
$btn-success-color: #02AF8E;
$btn-success-border-color: #06D9B1;

.ttp-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    background: transparent;
    padding: 0.8rem 1rem;
    cursor: pointer;
    outline: none;
    &.info {
        color: $btn-info-color;
        border: 0.5px solid $btn-info-color;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
        border-radius: 4px;
        padding: 0.8rem 5rem;
    }
    &.success {
        color: $btn-success-color;
        border: 0.5px solid $btn-success-border-color;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
        border-radius: 4px;
        padding: 0.8rem 5rem;
    }
    &.disabled {
      background: #F8F9FA;
      color: #6D7F92;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
      border-radius: 4px;
      padding: 0.8rem 5rem;
      &:hover {
        cursor: not-allowed;
      };
    }
}
.blue {
  color: $dodger-blue;

  &:hover {
    background: linear-gradient(180deg, #18A0FB 0%, #2495E1 100%);
    color: #fff;
  }
}

.btn {
    min-width: 180px;
    padding: 0 1rem;
    height: 44px;
    border: 1px solid rgba(199, 232, 254, 0.5);
    box-sizing: border-box;
    border-radius: 30px;
    font-family: Roboto;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    margin-left: 22px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    }

    img {
      margin-right: 12px;
    }

    &.secondary {
      background: #FFFFFF;
      letter-spacing: 0.06em;
      color: #2495E1;
    }

    &.small {
      font-size: 12px;
      min-width: unset;
      padding: 0 17px;
      height: 26px;
      border: 1px solid #18A0FB;
    }

    &.primary {
      background: $btn-success-border-color;
      letter-spacing: 0.04em;
      color: #FFFFFF;
      .btn-badge {
        background: #fff;
        color: $btn-success-border-color;
      }
      &:hover {
        background: #02AF8E;
      };
    }

    &.success {
      background: $btn-info-color;
      letter-spacing: 0.04em;
      color: white;
      .btn-badge {
        background: #fff;
        color: $btn-info-color;
      }
      &:hover {
        background: #2495E1;
      };
    }

    &.primary-steps {
      background: $sea-buckthorn;
      letter-spacing: 0.04em;
      color: white;
      &:hover {
        background: $tango;
      };
    }

    &.disabled {
      background: #F8F9FA;
      letter-spacing: 0.04em;
      color: #6D7F92;
      &:hover {
        cursor: not-allowed;
      };
    }
    &-badge {
      padding: .3rem .4rem;
      border-radius: 4px;
      margin-left: .5rem;
      font-weight: 600;
    }
  }

  .ttp-small-buttons {
    display: flex;
    justify-content: center;
    button {
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      color: #FFFFFF;
      padding: 12px 30px;
      cursor: pointer;
      &.success {
        background: $btn-success-border-color;
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        &:hover {
          background: #02AF8E;
        }
      }
      &.info {
        background: #18A0FB;
        &:hover {
          background: #2495E1;
        }
      }
      &.cancel {
        margin-right: 7px;
        border: 1px solid rgba(109, 127, 146, 0.5);
        color: #6D7F92;
        background: transparent;
        min-width: 126px;
        &:hover {
          box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
        }
      }
    }
  }
