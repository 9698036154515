// Gray blue colors
$pickled-bluewood: #29394D;
$fiord: #3C4E64;
$slate-gray: #6D7F92;
$cadet-blue: #B2BCC6;

// Gray colors
$mischka : #D7DBE0;
$athens-gray : #F1F2F4;
$athens-gray-lighter : #F8F9FA;

// Blue colors
$curious-blue: #2495E1;
$dodger-blue: #18A0FB;
$french-pass: #C7E8FE;
$alice-pass: #F3FAFF;

// Red colors
$coral-red: #FE3745;
$persimmon: #FF5656;
$cornflower-lilac: #FFABAB;
$cosmos: #FFD5D5;

// Green colors
$persian-green: #02AF8E;
$caribbean-green: #06D9B1;
$magic-mint: #B0EED7;
$foam: #F1FEF9;

// Orange colors
$tango : #EF8318;
$sea-buckthorn: #F7953E;
$bridal-heath: #FFFAF3;
$bianca: #FCFAF2;
