.toolbar {
  background: #F8F9FA;
  margin-bottom: 55px;
  &__option-custom {
    width: 26px;
    height: 26px;
    background-color: #F1F2F4;
    border-radius: 2px;
    padding: 0;
    border: none;
    box-shadow: none;
    margin: 0;

    &:first-of-type {
      margin-left: 4px;
    }

    &:last-of-type {
      margin-right: 4px;
    }

    img {
      max-height: 14px;
      max-width: 14px;
    }

    &.rdw-option-active, &:hover {
      background: #3C4E64;
      box-shadow: none;

      img {
        filter: brightness(3);
      }
    }
  }

  &__option-custom-wide {
    height: 26px;
    background-color: #F1F2F4;
    border-radius: 2px;
    padding: 0;
    border: none;
    box-shadow: none;

    a {
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #6D7F92;

      .rdw-dropdown-carettoopen {
        border-top-color: #6D7F92;
        border-radius: 1px;
      }

      .rdw-dropdown-carettoclose {
        border-bottom-color: #6D7F92;
        border-radius: 1px;
      }

      i.icon {
        margin-right: 4px;
      }
    }

    &:hover {
      background: #3C4E64;
      box-shadow: none;
      & > .rdw-dropdown-selectedtext {
        filter: brightness(3);
      }
    }
  }

  &__dropdown-custom {
    &:hover {
      box-shadow: none;
    }
  }

  .toolbar__popup-custom {
    padding: 0;
    width: 184px;
    height: 204px;
    background: #FFFFFF;
    border: 1px solid #DCE1E5;
    box-shadow: 0px 2px 8px rgba(41, 57, 77, 0.2);
    border-radius: 4px;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;

    .rdw-colorpicker-modal-header {
      .rdw-colorpicker-modal-style-label {
        background: #DCE1E5;
        border-radius: 4px 0px 0px 0px;
        border: none;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #6D7F92;
        padding: 6px;

        &:last-of-type {
          border-radius: 0 4px 0px 0px;
        }
      }

      .rdw-colorpicker-modal-style-label-active {
        background: #3C4E64;
        color: #FFFFFF;
      }

    }

    .rdw-colorpicker-modal-options {
      margin: 0;
      padding: 16px 0px;
      display: flex;
      justify-content: center;

      span {
        height: 22px;
        width: 22px;
        border-radius: 2px;
        transition: transform .2s;
        border: 1px solid #f1f2f4;

        &.rdw-option-active, &:hover {
          box-shadow: 1px 1px 2px 2px rgba(109, 127, 145, 0.05);
          transform: scale(1.2);
        }
      }

      .rdw-option-active {
        box-shadow: 1px 1px 2px 2px rgba(109, 127, 145, 0.05);
        transform: scale(1.2);
      }
    }

    &.rdw-image-modal {
      width: 314px;
      height: auto;

      .rdw-image-modal-header {
        margin: 0;
        padding: 0;
        border-radius: 4px 4px 0px 0px;
        text-transform: uppercase;
        background: #3C4E64;

        & > span {
          padding: 6px;
          width: 100%;
          border: none;
          color: #FFFFFF;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;

          span {
            display: none;
          }
        }
      }

      .rdw-image-modal-url-section {
        padding: 20px 12px 12px;

        input {
          height: 32px;
          background: #FFFFFF;
          border: 1px solid #18A0FB;
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
          border-radius: 3px;
          margin: 0;
          width: 100%;
        }
      }

      .rdw-image-modal-size {
        font-size: 21px;
        color: #3C4E64;
        margin: 0;
        padding: 12px 12px 20px;
        border-bottom: 1px solid #F1F2F4;

        input {
          background: #F8F9FA;
          border: 0.5px solid #6D7F92;
          border-radius: 3px;
          height: 32px;
          font-size: 14px;
          text-transform: lowercase;
          color: #29394D;
        }
      }

      .rdw-image-modal-btn-section {
        margin: 10px 25px 12px auto;

        .rdw-image-modal-btn {
          border: 1px solid rgba(109, 127, 146, 0.5);
          border-radius: 5px;
          height: 36px;
          font-family: Roboto;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #6D7F92;
          margin: 0 0 0 10px;

          &:first-of-type {
            background: #18A0FB;
            box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
            border: none;
            border-radius: 5px;
            color: #FFFFFF;
          }

          &:hover {
            box-shadow: none;
          }
        }
      }

      .rdw-image-mandatory-sign {
        font-size: 16px;
        padding: 6px 0 0;
      }
    }

    &.rdw-link-modal {
      width: 314px;
      padding: 10px 16px;
      height: auto;

      .rdw-link-modal-label {
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #3C4E64;
        padding: 8px;
      }

      .rdw-link-modal-label:first-of-type {
        position: relative;
        margin-top: 20px;
        &:before {
          content: "URL";
          position: absolute;
          top: -31px;
          left: 50%;
          display: block;
          margin: 0;
          border-radius: 4px 4px 0px 0px;
          text-transform: uppercase;
          background: #3C4E64;
          width: 335px;
          transform: translateX(-50%);
          text-align: center;
          padding: 6px;
          border: none;
          color: #FFFFFF;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
        }
      }

      .rdw-link-modal-input {
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #18A0FB;
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        border-radius: 3px;
        margin: 0 0 8px;
        width: 100%;
      }

      .ttp-select__control {
        background: #FFFFFF;
        border: 1px solid #18A0FB;
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        border-radius: 3px;
        margin: 0 0 8px;

        &, .ttp-select__value-container, .ttp-select__indicators {
          min-height: 32px;
          height: 32px;
        }
        .custom-creatable__input {
          min-height: 28px;
          height: 28px;
          box-shadow: none;
        }
      }

      .rdw-link-modal-target-option {
        display: none;
      }

      .rdw-link-modal-buttonsection {
        margin: 8px -16px 0;
        padding: 10px 10px 0 0;
        border-top: 1px solid #F1F2F4;
        display: flex;
        justify-content: flex-end;

        .rdw-link-modal-btn {
          border: 1px solid rgba(109, 127, 146, 0.5);
          border-radius: 5px;
          height: 36px;
          font-family: Roboto;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-transform: uppercase;
          color: #6D7F92;
          margin: 0 0 0 10px;

          &:first-of-type {
            background: #18A0FB;
            box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
            border: none;
            border-radius: 5px;
            color: #FFFFFF;
          }

          &:hover {
            box-shadow: none;
          }
        }
      }
    }
  }
}


.rich-editor-container {
  .rich-editor-tabs {
    position: relative;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    z-index: 0;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 3px;
      background: #B2BCC6;
      border-radius: 2px;
      z-index: 0;
    }

    div {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #6D7F92;
      width: 150px;
      text-align: center;
      padding: 10px 2px;
      position: relative;
      cursor: pointer;
      &.active {
        color: #18A0FB;
      }
    }

    span {
      position: absolute;
      bottom: 0;
      width: 150px;
      height: 3px;
      background: #18a0fb;
      border-radius: 2px;
      transition: left .3s ease-in-out;
      z-index: 1;
    }

    img {
      cursor: pointer;
      width: 12px;
    }
  }

  .rich-editor {
    position: relative;
    margin: 1rem 0;

    &__subject {
      background: #FFFFFF;
      border: 1px solid rgba(109, 127, 146, 0.2);
      height: 46px;
      width: 100%;

      input {
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        padding: 8px 14px;
        font-size: 12px;
        line-height: 14px;
        color: #3C4E64;
        box-shadow: none;
      }

      &.is-active {
        border-color: #18a0fb;
      }

      span {
        width: 100%;
        text-align: right;
        position: absolute;
        top: 24px;
        font-size: 12px;
        padding: 0 14px;
      }
    }

    &__text-area {
      background: #FFFFFF;
      border: 1px solid rgba(109, 127, 146, 0.2);
      box-sizing: border-box;
      border-radius: 0px 0px 4px 4px;
      font-size: 12px;
      line-height: 14px;
      color: #3C4E64;
      min-height: 12rem;
      padding: 6px 14px;

      &.is-active {
        border-color: #18a0fb;
      }

      .public-DraftStyleDefault-block {
        margin: 0;
      }
    }

    &.reduced {
      .toolbar {
        margin-bottom: 46px;
        padding: 0;
        border: none;
        display: flex;
        justify-content: space-between;

        &__option-custom {
          margin: 0;
        }
      }
      .rich-editor__subject {
        top: 32px;
      }
    }
  }

  .rich-editor-preview {
    position: relative;
    width: 100%;
    min-height: 230px;

    iframe {
      position: absolute;
      width: 150%;
      height: calc(150% + 2rem);
      transform: scale(calc(1/1.5));
      transform-origin: 0 0;
      top: 0;
      background-color: #fff;
      overflow-y: hidden;
      overflow-x: auto;
      border: 1px solid #D8DDE2;
      border-radius: 5px;
      padding: 1rem;
    }
  }
}

.rich-editor-model {
  #ttp-horizontal-filter {
    .filter-wrapper {
      &__tabs {
        border-right: 1px #D8DDE2 solid;
        padding-right: 5px;
      }

      &::after {
        content: none;
      }
    }

    .filter-container {
      .ttp-page-size.select-filter {
        width: 10rem;
      }
    }
  }
}


