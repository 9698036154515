.ge-settings-list {
  line-height: 1.5rem;
  margin-bottom: 2rem;
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  position: relative;
  z-index: 0;
  flex-flow: row wrap;
}

.ge-settings-item {
  background-image: url('/img/folder.svg');
  width: 301px;
  height: 253px;

  .status_top {
    position: relative;
    left: 17px;
    top: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    width: 100%;

    %icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-left: 11px;
      margin-top: 10px;
    }

    .icon-published {
      @extend %icon;
      background-color: #3adb76;
    }

    .icon-draft {
      @extend %icon;
      background-color: #B5BEC7;
    }

    .icon-pending {
      @extend %icon;
      background-color: #FFB06A;
    }

    p {
      padding-bottom: -5px;
      top: -6px;
      font-size: 10px;

    }
    .application {
      position: absolute;
      right: 0;
      bottom: 10px;
      right: 10%;
    }
  }

  p {
    font-size: 12px;
    line-height: 14px;
    color: #3C4E64;
    position: relative;
    margin-left: 15px;
    margin-top: -5px;

  }

  .labels {
    z-index: 1;
    position: relative;
    width: 231px;
    height: 175px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 120px;
    top: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #6D7F92;

    * {
      font-weight: 500;
      margin: 0;
    }

    h4 {

      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #6D7F92;


    }

    h5 {

      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #6D7F92;

    }

    h3 {
      font-size: 18px;
      text-transform: uppercase;
      margin-top: 6px;
      color: $pickled-bluewood;
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      padding-top: 10px;
      padding-bottom: 9px;
      color: #29394D;
    }


    .lng {
      background: rgba(109, 127, 146, 0.1);
      border: 0.5px solid #F1F2F4;
      box-sizing: border-box;
      border-radius: 2px;
      height: 24px;
      display: inline-block;
      margin-left: 5px;
      margin-bottom: 5px;

      .lng-item {
        margin-top: 2px;
        margin-left: 8px;
        margin-right: 8px;

        span {
          font-style: normal;
          font-weight: normal;
          margin-left: 8px;
          font-size: 12px;
          color: #3C4E64;
        }
      }
    }

  }

  &:hover {
    background-image: url('/img/folder-hover.svg');
    cursor: pointer;
  }
}

