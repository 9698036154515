.dropdown-button{
    position: relative;
    display: flex;
    box-shadow: 0 0.06rem 0.28rem 0 rgba(0,0,0,.1);
    color: #fff;
    background-image: linear-gradient(104deg,#57d0f2,#5081be);
    padding: 1rem;
    padding: .85em 0;
    font-size: .9rem;
    text-transform: uppercase;
    margin: 0;
    cursor: pointer;
    align-items: center;

    .action-label {
      width: 90%;
      text-align: center;
      white-space: nowrap;
      &.separator {
        border-right: 2px solid;
      }
    }

    .arrow-label{
      display: flex;
      width: 15%;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;
    }

    .icon{
      font-weight: bold;  
    }
    ul{
      position: absolute;
      cursor: pointer;
      text-align: center;
      line-height: 2.5;
      width: 100%;
      padding: 0;
      box-shadow: 0 3px 12px 1px rgba(44,43,63,.1), 0 0 0 1px rgba(44,43,63,.13);
      background: #fff;
      margin: 0;
      font-size: .9rem;
      color: black;
      top: 100%;
      li:not(:last-child){
        border-bottom: 1px solid rgba(44,43,63,.13);
      }
      li{
        &:hover{
          background-color:rgba(241, 241, 241,.5)
        }
      }
    }
    &.disabled {
        opacity: .3;
    }
  }
