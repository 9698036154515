@import "./form/create-form";
@import "./mailing-list-actions";

.mailing-lists {
  .filter-wrapper {
    margin-right: 1.5rem;
  }

  &__container {
    margin: 1rem auto;
  }

  &__item {
    width: 309px;
    height: 166px;
    background: $athens-gray-lighter;
    border: 1px solid $athens-gray;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    margin: 0 20px 20px 0;
    position: relative;

    &-header {
      position: absolute;
      right: 0;
      border-radius: 2px;
      width: 110px;
      height: 25px;
      display: flex;
      justify-content: center;
      color: white;
      align-items: center;

      &:hover {
        border-radius: 5px 3px 5px 5px;
      }

      .icon {
        margin: 4px 4px 0 0;

        svg {
          height: .9rem;
          width: 1rem;

          path {
            fill: white;
          }
        }
      }
    }

    &-container {
      padding: 2rem 2rem 0 2rem;

      .read-more {
        text-transform: uppercase;
        font-size: 10px;
        color: #6d7f92;
        float: right;

        svg {
          height: 10px;
          width: 10px;
          margin: 0 4px;

          path {
            fill: #6d7f92;
          }
        }

        .down {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $pickled-bluewood;
    }

    &-mandatory {
      font-size: 12px;
      margin-left: .5rem;
      color: #3C4E64;

      &:after {
        background: rgba(109, 127, 146, 0.2);
        border-radius: 4px;
        transform: matrix(1, 0, 0, -1, 0, 0);
        width: 2px;
        height: 16px;
        right: 17rem;
        content: '';
        position: absolute;
        margin-left: -4rem;
      }
    }

    &-description {
      font-size: 12px;
      line-height: 14px;
      padding-top: .2rem;
      color: #6D7F92;
    }

    p {
      margin-bottom: .2rem;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    &.optional {
      &:hover {
        border: 1px solid #12D2DE;
      }
      .mailing-lists__item-header, .mailing-lists__item-mandatory:after {
        background: #12D2DE;
      }
    }

    &.mandatory {
      &:hover {
        border: 1px solid #F9626D;
      }
      .mailing-lists__item-header, .mailing-lists__item-mandatory:after {
        background: #F9626D;
      }
    }
    &.expanded {
      height: auto;

      .mailing-list-actions {
        top: auto;
      }
    }

    &:hover .mailing-list-actions {
      opacity: 1;
      transform: translateX(0);
    }

    .read-more {
      cursor: pointer;
      margin: .7rem 0;
    }
  }

  .fwl {
    font-weight: 300;
  }
}
