.campaign-preview {
  &-enter {
    transform: translateY(100%);
  }
  &-active {
    transform: translateY(0);
    transition: transform 200ms;
  }
  background: #fff;
  height: calc(100vh - 65px - 46px); // actionBar heigh,  page margin
  border: 1px solid #F1F2F4;
  box-sizing: border-box;
  box-shadow: 0px 2px 20px rgba(41, 57, 77, 0.05);
  border-radius: 5px;
  &__device {
    text-align: center;
    margin: 16px auto;
    span {
      color: #6c757d;
      border: 1px solid #6c757d;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      text-transform: capitalize;
      text-align: center;
      cursor: pointer;
      &:first-child{
        border-radius: 4px 0 0 4px;
      }
      &{
        border-radius: 0 4px 4px 0;
      }
      &.active {
        color: #fff;
        background-color: #6c757d;
      }
    }
  }
  &__content {
    position: relative;
    height: calc(100% - 56px);
    text-align: center;
    background-color: rgb(255, 255, 255);
    background-image: linear-gradient(45deg, rgb(247, 247, 247) 25%, transparent 25%), linear-gradient(-45deg, rgb(247, 247, 247) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(247, 247, 247) 75%), linear-gradient(-45deg, transparent 75%, rgb(247, 247, 247) 75%);
    background-size: 20px 20px;
    background-position: 0px 0px, 0px 10px, 10px -10px, -10px 0px;
    iframe {
        position: relative;
        top: 0;
        width: 100%;
        height: 100%;
        background: #F3FAFF;
        border: 1px solid #f3faff;
        border-radius: 4px;
        transition: width 300ms ease-in;
        &.mobile {
          width: 320px;
        }
        &.text-desktop {
          position: absolute;
          width: 200%;
          height: 200%;
          transform: scale(0.5);
          transform-origin: 0% 0%;
          top: 0;
          left: 0;
          overflow-y: hidden;
          overflow-x: auto;
        }
    }
  }
}

.campaign-preview-enter {
  transform: translateY(100%);
}
.campaign-preview-enter-active {
  transform: translateY(0);
  transition: transform 200ms;
}
.campaign-preview-exit {
  transform: translateY(0);
}
.campaign-preview-exit-active {
  transform: translateY(100%);
  transition: transform 200ms;
}
