@import "./fields-selector";

.text-editor {
    position: relative;
    .toolbar {
        padding-right: 0;
        padding-left: 0;
        position: unset;
    }
    .rdw-editor-main {
        padding: .5rem;
        min-height: 7rem;
        .public-DraftStyleDefault-block {
            margin: 0;
        }
        .public-DraftEditorPlaceholder-root {
            z-index: 0;
        }
    }
}
