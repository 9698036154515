.theme-category__filter {
  width: 276px;
  min-width: 276px;
  overflow: scroll;;
  padding: 18px 0;

  background: #F1F2F4;
  ul {
    margin: 0;
    transition: height .3s;
    overflow: hidden;
    li {
      height: 44px;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      color: #29394D;
      line-height: 44px;
      position: relative;
      z-index: 0;
      cursor: pointer;
      .filter-header {
        display: flex;
        align-items: center;
        margin-left: 26px;
      }
      &.highlight {
        background: #F3FAFF;
      }
      &.active {
        &::after {
          content: " ";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          height: 44px;
          background: #C7E8FE;
          border-left: 3px solid #2495E1;
          z-index: -1;
        }
      }

      &.is-drop-down {
        height: unset;
        li {
          font-weight: normal;
          text-transform: capitalize;
          text-indent: 43px;
        }
        ul li {
          padding-left: 20px;
        }
        img {
          margin-right: 14px;
        }
      }
      svg {
        margin-right: 1rem;
      }
    }
  }
  .sidebar-arrow{
    position: absolute;
    right: 10px;
    top: 0;
  }
}
