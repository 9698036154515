.container {
  padding: 1% 1% 1% 0.5%;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  margin-top: 1%;
  margin-left: 0.2%;
  margin-right: 0.6%;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  color: #000;

  &:last-of-type{
    margin-bottom: 3rem;
  }
  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 21px;
    text-align: left;
    color: #29394d;
    margin: 1rem 1rem 1rem 1.5rem;
    padding-top: 1%;
    font-style: normal;
    text-transform: capitalize;
  }
  
  .contentContainer {
    @media (min-width: 1900px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .chartBlock {
        width: 49.5%;
      }
    }
    @media (max-width: 1900px) {
      min-width: 90%;
      .chartBlock {
        width: 100%;
        margin-right: 10%;
      }
    }
  }

  .chartContainer {
    padding: 2rem 1rem 1rem;
    position: relative;
    border: 1px solid #d8dde2;
    box-sizing: border-box;
    border-radius: 5px;

    .chartImage {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
    }
    .chartHeader {
      position: absolute;
      font-family: "Roboto";
      left: 3%;
      top: 6%;
      line-height: 25px;
      font-size: 18px;
      color: #29394d;
      margin-bottom: 2rem;
    }
    .buttonComponent {
      position: absolute;
      top: 14%;
      right: .7rem;
      display: flex;
      justify-content: space-between;
      width: 80%;

      .button_line {
        width: 23%;
        border-bottom: 2px #B2BCC6 solid;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        height: 30px;
        color: #6D7F92;
        cursor: pointer;
        z-index: 100;
      }
      .active {
        border-bottom: 2px #29394d solid;
        color: #29394d;
        cursor: default;
      }
    }
    .chartComponent {
      position: relative;
      width: inherit;
      height: 350px;
      margin-top: 1rem;

      .TooltipStyle {
        width: 80px;
        position: relative;
        border-radius: 10px;
        padding: 5px 20px;

        .polygonIcon {
          position: absolute;
          top: 17%;
          left: 20%;
        }
        .tooltipValue {
          color: #fff;
          width: 100%;
          text-align: right;
        }
      }
      .yAxisTitle {
        position: absolute;
        top: 13%;
        left: 2%;
        font-size: 14px;
        line-height: 14px;
        color: #29394d;
        z-index: 1;
      }
      .lineChart {
        .recharts-legend-wrapper {
          top: 4rem !important;
          margin-bottom: 0;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
.dashboard {
  .dashboard_fil{
    &__cont{
      display: flex;
      justify-content: flex-end;
      .filter-select{
        .ttp-select__control{
          border-radius: 26px;
          background: #F1F2F4;
          text-transform: uppercase;
          text-align: center;
          padding-left: 10px;
          border:0;
        }
      }
    }
  }

}
