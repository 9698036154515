.mail-list {
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  &-item {
    min-height: 232px;
    background: #FFFFFF;
    border: 1px solid #F1F2F4;
    box-sizing: border-box;
    box-shadow: 0px 4px 5px rgba(41, 57, 77, 0.1);
    border-radius: 10px;
    padding: 16px;
    margin: 12px;
    cursor: pointer;
    &:hover{
      background: #F3FAFF;
    }
    &.delete{
      background: #FFF7F8;
      &:hover {
        border: 1px solid #FE3745;
      }
    }
    &__main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-weight: 500;
        font-size: 12px;
        color: #18A0FB;
        margin: 0 .4rem;
      }
      .label_delete {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #FE3745;
        text-transform: uppercase;
        cursor: pointer;
        svg {
          margin-right: 13px;
        }
        svg path , svg rect{
          fill: #FE3745;
        }
      }
    }
    &__items {
      height: 44px;
      vertical-align: middle;
      background: #F3FAFF;
      border: 1px solid #CDE7FC;
      box-sizing: border-box;
      border-radius: 4px;
      &.name{
        margin-bottom: 27px;
        .is-visible{
          margin-top: -0.7rem;
        }
      }
    }
    .warning {
      background: #FCFAF2;
      color: #29394d;
      margin-bottom: 1rem;
      padding: .8rem;
      font-weight: normal;
      font-size: 12px;
    }
    .param-label {
      text-indent: 0;
      font-size: 14px;
      line-height: 16px;
    }
    input, input:focus{
      height: 100%;
      box-shadow: none;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 39px;
      color: #3C4E64;
    }

    &__action span {
      text-transform: uppercase;
      padding: .5rem .8rem 0 0;
      font-size: .7rem;
      font-weight: normal;

      &:hover {
        color: #505050;
        letter-spacing: .03rem;
      }
    }
  }
}
