.template-filter-bar{
    display: flex;
    margin: 2rem 0;
    select {
        margin: 0;
    }
    .search {
        padding: 0;
    }
    input {
        width: 100%;
    }
    .category-filter {
        display: flex;
    }
}