.not-found {
  background: transparent;
  margin: 1rem auto;
  color: #2C394B;
  font-size: 1.5rem;
  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-size: 28px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 18px;
      color: #3F4E62
    }

    .btn {
      min-width: 300px;
      a {
        text-transform: initial;
        letter-spacing: 1px;
        color: #18A0FB;
      }
    }
  }
  &__text {
    display: flex;
    align-items: center;
  }
}
.not-found-iframe{
  width: 750px;
}
