.theme-actions {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    min-width: 140px;

    &__action {
        width: 40px;
        height: 40px;
        background: #18A0FB;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        z-index: 0;

        img, svg {
            width: 16px;
            height: 16px;
            z-index: 1;
        }

        &::after {
            content: " ";
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
            background-color: #2495E1;
            border-radius: 50%;
            transition: width .3s, height .3s, opacity .2s;
        }

        &:hover {
            box-shadow: none;
            &:after {
                width: 100%;
                height: 100%;
                opacity: 1;
            }
        }

        &.delete-action {
            background-color: #FF5656;
            &::after {
                background-color: #FE3745;
            }
        }
    }
}
