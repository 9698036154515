#e-box-page, #other-ebox-page {
  padding-bottom: 2rem;
  min-height: calc(100vh - 154px);

  #ttp-horizontal-filter {
    z-index: 1;
  }

  .list {
    &-item {
      padding-left: 0;

      & > div::after {
        right: 75%;
      }

      & > div:nth-child(2)::after {
        right: 35%;
      }

      & > div:nth-child(3)::after {
        right: 14.5%;
      }

      & > div:nth-child(4)::after {
        display: none;
      }

      &.border-alert {
        background: #FFF7F8;

        .list-item__border {
          background-color: rgba(230, 20, 10, 1);
        }
      }

      &.border-primary {
        border-color: #59A6EF !important;
        background: #F3FAFF;

        .bg-primary {
          background-color: #59A6EF;
        }

        .list-item__border {
          background-color: #59A6EF;

          svg {
            circle {
              fill: #59A6EF;
            }
          }
        }
      }

      &.border-info {
        border-color: #18A0FB !important;

        .bg-info {
          background-color: #18A0FB;
        }

        .list-item__border {
          background-color: #18A0FB;
        }
      }

      &.border-sent {
        border-color: #62DEB0 !important;

        .bg-sent {
          background-color: #62DEB0;
        }

        .list-item__border {
          background-color: #62DEB0;

          svg {
            circle {
              fill: #62DEB0;
            }
          }
        }
      }

      &.border-success-opened {
        border-color: #B2BCC6 !important;
        background: #F8F9FA;

        .bg-success-opened {
          background-color: #B2BCC6;
        }

        .list-item__border {
          background-color: #B2BCC6;
        }
      }

      &.border-deleted {
        border-color: #F7953E !important;
        background: #FFFAF3;

        .bg-deleted {
          background-color: #F7953E;
        }

        .list-item__border {
          background-color: #F7953E;
        }
      }

      &:hover {
        transform: scale(1.01);

        .display-inline {
          display: inline;
        }
      }

      &__actions {
        svg {
          padding-right: 2px;
        }
      }

      &__border {
        width: 22px;
        height: 44px;
        border-top-right-radius: 110px;
        border-bottom-right-radius: 110px;
        position: absolute;

        svg {
          width: 14px;

          path {
            fill: white;
          }
        }
      }

      &__sender {
        padding-left: 3rem;
        line-break: anywhere;
      }

      &__border {
        svg, img {
          position: relative;
        }

        svg {
          top: 30%;
        }

        img {
          top: 25%;
        }
      }
    }

    .drop-down-option {
      display: none
    }
  }

  .pagination {
    height: 5rem !important;
  }

  .bubble-div {
    &:hover {
      svg {
        fill: #FFFFFF;
      }
    }

    span {
      padding-left: 1rem;
      color: #FFFFFF;
    }

    &.mail-express {
      background: #29394D;

      span {
        color: #C7E8FE;
      }

      path {
        fill: #C7E8FE;
      }
    }
  }

  .settings-filter {
    max-width: 74rem;
    margin: auto;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(12, 1fr);;

    &__status {
      display: inline-flex;
      width: 500px;

      span {
        background: #F1F2F4;
        border: 0 solid #f1f2f4;
        box-sizing: border-box;
        border-radius: 30px;
        margin-right: .8rem;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #6D7F92;
        padding: 9px 17px;
        cursor: pointer;
        transition: background-color .3s;
        white-space: nowrap;

        &.active {
          background: #6D7F92;
          color: #FFFFFF;
          font-weight: 500;
          text-shadow: 0px 4px 4px rgba(41, 57, 77, 0.4);
        }

        &:hover:not(.active) {
          background-color: rgba(109, 127, 146, 0.2);
        }

        &:hover, &.active {
          border-width: .5px;
        }
      }
    }

    .ttp-page-size {
      grid-column: 12 /span 1;
    }

    .search-box {
      grid-column: 11 /span 1;
    }

  }

  .sms-ebox-list {
    .list-item:hover .list-item__status {
      display: block;
      margin-right: .5rem;
    }

    .list-item {
      &.border-primary {
        background: #F8F9FA;
      }

      .m-b {
        padding-bottom: 1.5rem;
      }
    }
  }
}
