.list {
  span {
    font-family: Roboto;
  }

  &-item {
    padding: 20px;
    margin-bottom: 6px;
    align-items: stretch;
    cursor: pointer;
    background: #FFF;
    border-radius: 0px 5px 5px 0px;
    border-left: 2px solid;
    border-top: 1px solid $athens-gray !important;
    border-right: 1px solid $athens-gray !important;
    border-bottom: 1px solid $athens-gray !important;
    background: #FFF;

    &__count {
      text-align: center;

      span {
        text-transform: capitalize;
      }

      img {
        margin-right: .3rem;
      }
    }

    &__type {
      font-size: 14px;
      color: $fiord;

      span {
        text-transform: capitalize;
      }
    }

    &__header {
      font-size: 14px;
      font-family: $main-body-font;
      color: $pickled-bluewood;
      font-weight: 500;
      margin-bottom: .5rem;
      overflow: hidden;
      line-height: 19px;
    }

    &__sub-header {
      font-size: 12px;
      color: $fiord;

      .item-number {
        font-weight: 500;
      }

      .icon-left {
        font-size: .7rem;
      }

      .lang {
        text-transform: capitalize;
      }
    }

    &__contacts {
      font-size: 14px;
      line-height: 19px;
      color: $fiord;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      align-items: center;
      align-content: center;

      .recipient-type {
        background-color: #e1e4e8;
        color: $slate-gray;
        padding: 2px 6px;
        font-size: 12px;
        font-weight: 400;
        line-height: 19.2px;
        border-radius: 5px;
      }
    }

    &__destinataires {
      display: flex;

      .container-dest {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: $pickled-bluewood;

        >div {
          font-weight: 500;
          font-size: 14px;
          display: flex;
          align-items: center;

          .icon-dest {
            width: 14px;
            height: 14px;
            display: inline-block;
            margin-right: 5px;
          }
        }

        >span {
          color: var(--Dark-1, var(--dark-1, #29394D));
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    &__status {
      padding: .2rem .5rem;
      text-transform: uppercase;
      border-radius: 3rem;
      float: right;
      font-size: .7rem;
      background: #FFFFFF;
      text-align: center;

      i {
        display: inline-block;
        font-size: .5rem;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-right: .3rem;
        animation-name: clignote;
        animation-iteration-count: infinite;
        animation-duration: 1s;
      }

      &-count {
        padding: .1rem .3rem .1rem .1rem;
        border-radius: 3rem;
        background: #E8EAED;
        margin-left: 0.5rem;
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      opacity: 0;
      transform: translateY(-100%);

      >div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        vertical-align: middle;
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        overflow: hidden;
        border-radius: 50%;
        margin-left: -8px;
        cursor: pointer;
        background: #FFFFFF;
        color: #18A0FB;
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);

        &:hover {
          background: linear-gradient(180deg, #18A0FB 0%, #2495E1 100%);
          //bottom: .5rem;
          z-index: 1;

          i {
            color: #FFFFFF;
          }

          svg,
          rect,
          path {
            fill: #FFFFFF;
          }
        }

        .type-dark {
          background: $fiord;
        }
      }

      svg {
        fill: #18A0FB;
      }

      rect {
        fill: #FFB06A;
      }
    }

    position: relative;

    &>div {
      border-left: 1px solid #E1E4E8;
    }

    &>div:first-child,
    &>div:nth-child(5) {
      border-left: none;
    }

    .icon {
      margin: 2px 4px 0 0;
    }

    .list-item__status {
      border: 1px solid #E1E4E8;
      min-width: 120px;
      min-height: 26px;
      padding-top: .3rem;
      font-weight: 500;
      text-align: center;
      color: $slate-gray;
    }

    &:hover {
      background: #F1F2F4;
      z-index: 1;

      .list-item__status {
        display: none;
      }

      .list-item__actions {
        opacity: 1;
        transform: translateY(0%);

        &>div {
          overflow: visible;
          box-shadow: 0 0 10px rgba(41, 57, 77, 0.04);
          border: .5px solid #C7E8FE;

          &.delete-action {
            border-color: rgba(254, 55, 69, 0.2);

            &:hover {
              background: #FE3745;
            }
          }
        }
      }

      .list__row-groups-cell-wrapper {
        .list__row-group-cell-tag {
          background: #F8F9FA;
        }
      }

      &>div {
        border-left-color: #FAFBFB;
      }
    }
  }

  &__row-action-cell-button {
    padding-right: 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  &__row-status-cell-icon {
    font-size: 1.4rem;
    padding: .4rem;
  }

  &__row-group-cell-tag {
    padding: 2px 8px;
    display: inline-block;
    margin: .1rem;
    background: #F1F2F4;
    border-radius: 2px;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: #3C4E64;
  }

  &__row-groups-cell-wrapper {
    display: flex;
    position: relative;
    width: 100%;

    .hide {
      display: none !important;
    }

    .groups-block {
      padding-right: 12px;
    }

    .groups-block-position {
      padding-left: 1rem;
      top: -5px;
    }

    .more-groups-container {
      position: absolute;
      line-height: 2rem;
      color: #aaa;
      cursor: pointer;
      right: 0;
      text-transform: lowercase;
      font-size: .7rem;
      bottom: -.2rem;

      &:hover {
        color: #777;
      }
    }
  }

  &__sort-button {
    cursor: pointer;

    &:hover {
      color: $primary-color;
    }
  }

  &__datatable {
    .ttp-datatable__header {
      padding: 15px 5px;
    }

    .ttp-datatable__row {
      padding: 15px 5px;
      cursor: pointer;
    }
  }
}

.duplicate-options .checkbox label {
  letter-spacing: 1px;
  font-size: .8rem;
  text-transform: uppercase;
}