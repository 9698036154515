.feed-fetching {
  transition: nth($transitions, 3);
  background-color: #fff;
  margin-bottom: 1.5rem;

  &__img {
    height: 13rem;
  }

  &__content {
    padding-left: 13%;
    padding-right: 13%;
    padding-top: 32px;
    box-shadow: 0px 15px 45px -9px rgba(0, 0, 0, 0.2);
  }

  &__username {
    margin-bottom: .4rem;
    height: .9rem;
  }

  &__published-date {
    height: .6rem;
  }

  &__user {
    margin-bottom: 1rem;
  }

  &__title {
    margin-bottom: 1rem
  }

  &__title div {
    margin-bottom: 0.5rem;
    height: 1.3rem;
  }

  &__content-p {
    margin-bottom: 2rem;

    & > div {
      margin-bottom: 0.5rem;
      height: .8rem;
    }
  }

  &__tags {
    padding: 2rem 0 2rem 18%;
    margin-left: -18%;
    border-top: 1px solid #e9e9e9;
    position: relative;

    & .small-4 {
      margin-right: .5rem;
      height: 1rem;
    }
  }

  &__category {
    position: absolute;
    top: -1rem;
    right: -1rem;
    width: 7rem;
    background-color: #fff;
    padding: 0.3rem 0.5rem;

    div {
      padding: .7rem;
      border-radius: 100px;
    }
  }

  .icon {
    font-size: 16px;
    position: absolute;
    left: 7%;
    top: 2rem;
  }
}

.layout-fetching {
  height: 10rem;
  width: 100%
}

.article-fetching {
  position: relative;
  display: flex;
  background: #FFFFFF;
  border: 1px solid #F8F9FA;
  box-shadow: 0px 4px 5px rgba(41, 57, 77, 0.1);
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 14px;
  padding: 13px;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
  &__drag {
    height: 34px;
    margin: auto 13px auto 0;
    cursor: move;
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  &__content-p {
    margin-bottom: 2rem;

    & > div {
      margin-bottom: 0.5rem;
      height: .8rem;
      width:23rem;
    }
  }

  &__img {
    width: 135px;
    height: 94px;
    border-radius: 5px;
    border: 1px solid #eee;
    background-size: cover;
    background-position: center;
  }

  &__category {
    position: absolute;
    top: -4px;
    right: -15px;
    padding: 4px 9px;
    &-label {
      width: 8.0rem;
      height: 1.2rem;
      border-radius: 10%;
    }
  }

  &_author {
    display: flex;
    margin-bottom: 10px;

    &-avatar {
      width: 26px;
      height: 26px;
      background-size: contain;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
}
