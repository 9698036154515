@import "components/list/list-form";
@import "components/list/embed-form";
@import "components/list/list-manager";

#list-page, #subscribers-page{
  .icon-options-vertical{
    font-size: 1.1rem;
  }
  .checkbox .ttp-checkbox{
    width: 1rem;
    height: 1rem;
  }
  .template-list__item:hover{
    .add-subscription{
      margin-left: .6rem;
      display: inline-block;
    }
  }
  .add-subscription{
    display: none;
  }
  .icon-close, .icon-plus{
    cursor: pointer;
    font-size: 1.1rem;
  }
  .icon-quotes-left{
    font-size: .7rem;
    color: $gray-dark;
  }
}
.mailing-list-container{
  .mailing-list-tag{
    display: inline-block;
    background-color: $tag-bg-color;
    padding: .3rem .7rem;
    margin: .1rem;
    cursor: pointer;
    color: $main-color;
    &__remove{
      font-size: 1rem;
      margin-left: .3rem;
      &::before{
        content: "\D7";
      }
    }
  }
}
.mailing-list-suggestion{
  display: flex;
  align-items: center;
  input, input:focus{
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #f3f3f3;
  }
  input:focus {
    border-bottom: 1px solid $main-medium-gray;
  }
  .react-autosuggest__container {
    width: 90%;
    border: none;
    border-bottom: 1px solid #f3f3f3;
  }
  .react-autosuggest__suggestions-container--open {
    position: absolute;
    left: 0;
    right: 0;
  }
}
.mailing-list-assign{
  border: 1px $main-medium-gray solid;
  padding: .3rem .3rem 0;
  input:focus{
    border: none;
  }
}
