.widget_top-bar {
  position: relative;
  display: flex;
  background: #29394D;
  border-radius: 5px 5px 0px 0px;
  height: 36px;
  cursor: pointer;
  text-transform: uppercase;
  &.minimized-bar {
    width: 316px;
  }
  .resize{
    position: absolute;
    right: 1rem;
    margin: auto;
    bottom: 0;
    top: 0;
  }
  svg {
    color: $french-pass;
    margin-right: 0.25rem;
    fill: $french-pass;
    rect {
      fill: $french-pass;
    }
  }
  span{
    text-align: center;
    padding-left: .5rem;
    display: flex;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: $french-pass;
    &.icomoon {
      border-left: 1px solid #6D7F92;
    }
  }
  img{
    vertical-align: inherit;
  }
}
