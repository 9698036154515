.simple-tabs {
  position: relative;
  margin-top: 2rem;

  &__group {
    margin-bottom: -1px;
    display: flex;
    position: relative;

    .tab {
      background: #F1F2F4;
      border-radius: 4px 4px 0px 0px;
      width: 164px;
      height: 40px;
      margin-right: 6px;
      padding: 12px 0px;
      line-height: 1;
      font-size: 14px;
      color: #29394D;
      text-transform: capitalize;
      position: relative;
      text-align: center;

      .flag-icon {
        width: 20px;
        height: 14px;
        margin-right: 8px;
      }

      &.active {
        background: #FFFFFF;
        border: 0.5px solid #F1F2F4;
        border-bottom: none;
        box-sizing: border-box;
        padding: 11.5px 17px;
        box-shadow: 0px -10px 10px rgba(41, 57, 77, 0.04);
      }
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
      }
    }
  }

  &__content {
      display: flex;
      background: #FFFFFF;
      border: 0.5px solid #F1F2F4;
      box-sizing: border-box;
      border-radius: 0px 4px 4px 4px;
      padding: 2rem 1rem;
  }
}
