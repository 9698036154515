@import "./campaign";
@import "./article";
@import "./ebox";
@import "./NewsLetter";

.gradiant {
  background: linear-gradient(to right, #F9F9F9, #EEE, #E5E5E5, #DEDEDE);
  background-size: 300% 100%;
}

.dark-gradiant {
  background: linear-gradient(to right, #303339, #4a4949, #C6C6C6, #C5C5C5);
  background-size: 300% 100%;
}

.theme-fetching{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .user-avatar {
    width: 8.5rem;
    height: 8.5rem;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
  h3 {
    height: 1.3rem;
    width: 60%;
    margin-bottom: .7rem;
  }
  h4 {
    height: 1rem;
    width: 70%;
    margin-bottom: .7rem;
  }
  span {
    width: 20%;
    height: 1px;
    background-color: $main-orange;
    margin-bottom: .7rem;
  }
  h5 {
    height: .8rem;
    width: 50%;
  }
  h6 {
    height: .8rem;
    width: 20%;
  }
  h2 {
    height: 1.5rem;
    width: 70%;
  }
}

.mail-data-fetching {
  display: flex;
  flex-direction: column;
  align-items: center;
  h6 {
    width: 90%;
    height: .8rem;
    margin-top: 1.3rem;
  }
}

.user-fetching {
  h3 {
    height: 1.3rem;
    width: 60%;
    margin: 1rem;
  }

  h4 {
    height: 1rem;
    width: 50%;

    &.separator {
      margin: .5rem;

      &:after {
        margin: 1.7rem 0 .2rem 0;
      }
    }
  }

  h5 {
    height: 1rem;
    width: 40%;
    margin: .9rem;
  }
}
.theme-fetching{
  flex-direction: row;
}

.datatable-fetching{
  h3, h4, h5, h6{
    height: .8rem;
  }
  h3 {
    width: 80%;
  }
  h4 {
    width: 70%;
  }
  h5 {
    width: 50%;
  }
  h6 {
    width: 30%;
  }

  .ttp-datatable__header{
    background-color: transparent;
  }
  .notifications-list__lang {
    span {
      width: 12rem;
      padding: .8rem;
      border-radius: 50px;
    }
  }
}
.approval-response{
  .fetching{
    h4{
      height: 1.5rem;
      width: 100%;
    }
  }
}

.approval-feedback-fetching{
  h4, h5, h6{
    height: .8rem;
  }
  h5{
    width: 40%;
  }
  h6{
    width: 30%;
  }
}

.team-list-item-fetching{
  h4, h5, h6{
    height: 1rem;
  }
  h4{
    width: 30%;
  }
  h5{
    width: 70%;
  }
  .summary__count{
    display: inline-block;
    height: 1rem;
    width: 10%;
  }
  .team-list-item__action{
    span{
      display: inline-block;
      width: 10%;
      height: .8rem;
      margin: 0 .2rem;
    }
  }
}

.speakers-item.fetching {
  h3, h5 {
    height: 1rem;
  }
  h3 {
    width: 4rem;
  }
  h5 {
    width: 6rem;
  }
  .empty-avatar {
    background: linear-gradient(to right, #F9F9F9, #EEE, #E5E5E5, #DEDEDE);
  }
}

.generic-email-fetching {
  h6 {
    width: 30rem;
  }
  h6, h3, h4, h5{
    height: 1rem;
  }
  h3{
    width: 20rem
  }
  h5{
    width: 10rem
  }
}

.mailing-list-fetching {
  h3 {
    height: 1.3rem;
    width: 80%;
  }

  h6 {
    height: .7rem;
    width: 50%;
  }
  h4 {
    height: .8rem;
    width: 100%;
  }

  .mailing-lists__item-header {
    background: linear-gradient(to right, #F9F9F9, #EEE, #E5E5E5, #DEDEDE);
  }
}
