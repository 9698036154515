#sms-campaign-page {
  overflow-x: hidden;

  textarea {
    margin: 0 0 8px 0;
    box-shadow: none;
  }

  .ttp-button {
    float: right;
  }

  .button {
    margin: .8rem 0 0;
  }

  #campaign-recipent__tab {
    .send-header {
      margin: 0;
    }
  }

  .action-header {
    display: flex;

    > div {
      display: flex;
      text-transform: capitalize;
      background: #FAFBFB;
      border: 0.5px solid #F1F1F1;
      box-sizing: border-box;
      border-radius: 50px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin: 0 0 0 .5rem;

      .sms-models {
        font-size: 1rem;
        color: $pickled-bluewood;
        margin: auto;
      }
    }
  }

  .fields-box {
    text-transform: uppercase;
    display: flex;
    border: 1px solid #F1F2F4;
    border-radius: 0px 0px 4px 4px;
    margin: 0 0 .3rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F1F2F4;
      color: $pickled-bluewood;
      height: 36px;
      flex-grow: 1;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: .1rem;
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  .message-counts {
    font-weight: 300;
    font-size: 12px;
    color: #6D7F92;
    line-height: 14px;
  }

  .drop-down-option {
    margin-top: 3rem;
    width: 100%;

    .button {
      font-weight: 700;
      margin: 0;
      width: 100%;
      background-color: #373c44;
    }

    .icon-plus {
      padding-right: .3rem;
      font-weight: 700;
    }

    .icon-arrow-down {
      padding-left: .2rem;
      font-weight: 700;
    }
  }

  .icon-info, .icon-check {
    margin-left: 0;
  }

  .flag-icon {
    margin-left: 1.2em;
  }

  .sms-counter {
    border-left: 1px solid rgba(0, 0, 0, .1) !important;
    padding-left: 1.4rem;
  }

  .tooltip-info {
    padding-left: 0 !important;
  }

  .topbar-recipient-type {
    padding-right: 1.4rem;
  }

  .campaign-sms-warning {
    margin: 2em 0;
    padding: 0;
  }

  .row-top {
    padding-top: 2rem;
  }

  .alert {
    border: 1px solid $alice-pass;
    border-radius: 4px;

    hr {
      border-color: $alice-pass;
    }
  }
}

.sms-models-modal {
  margin-bottom: -2rem !important;

  h3 {
    text-transform: uppercase;
  }

  .preview-format {
    margin: -2rem 1rem 0 1rem !important;

    .phone-text {
      top: 30% !important;
      width: 95% !important;
      left: 45% !important;
    }
  }

  .phone-container {
    margin: -4.5rem -4rem 0 -7rem;
    position: relative;
    text-align: center;

    img {
      height: 40rem;
      max-width: none !important;
      padding: 4rem 0 2rem 0;
    }

    .phone-text {
      position: absolute;
      top: 30%;
      left: 47%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      height: 5rem;
      width: 60%;
      padding: 0 2.5rem 0 4.5rem;
    }
  }

  .button {
    box-shadow: 0 0.06rem 0.28rem 0 rgba(0, 0, 0, 0.1);
    color: #fff;
    background-image: linear-gradient(104deg, #57d0f2 0%, #5081be 100%);
    font-weight: 600;
    background-size: 100%;
    border: none;
    display: block;
    text-transform: uppercase;
    margin-bottom: 0;

    .arrow {
      float: right;
      border-color: white transparent transparent;
    }
  }

  .models-message {
    padding: 0 0 1rem .2rem;

    span {
      font-size: .8rem;
      color: #8d8d8d;

      i {
        margin-right: 0.3rem;
      }
    }
  }

  .dynamic-button {
    font-weight: 700;
    margin: 0;
    width: 100%;
    background-color: #373c44;
    background-image: none !important;

    &:hover {
      background-color: #373c44;
    }

    i:first-child {
      float: left;
    }

    i:last-child {
      float: right;
    }
  }

  .ttp-select-container {
    padding-bottom: 1rem;
  }

  .margin {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .fields-box {
    display: flex;
    border: 1px solid #F1F2F4;
    border-radius: 0px 0px 4px 4px;
    margin: 0 0 .3rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F1F2F4;
      color: $pickled-bluewood;
      height: 36px;
      flex-grow: 1;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: .1rem;
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  .ttp-button {
    margin-left: 0;

    &.info {
      padding: 0.8rem 0;
    }
  }

  textarea {
    box-shadow: none;
  }
}

.campaign-sms {
  .list-item > div:nth-child(4)::after {
    border-left: none;
  }

  .empty-box {
    input {
      border-radius: 0;

      &:focus {
        border: 1px solid #8a8a8a;
      }
    }
  }
}

#sms-preview {
  .sidebar {
    width: 30%;
    background-color: #3C4E64;
    transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

    #theme-preview_close {
      float: left;
      position: relative;
      top: -2rem;
      right: 3rem;
    }

    .container-smartphone {
      .phone-container {
        width: 247px;
        height: 438px;
        background-color: white;
        margin-top: 4.15rem;
        margin-left: 4.8rem;

        .phone-text {
          position: absolute;
          top: 25%;
          left: 46%;
          transform: translate(-50%, -50%);
          font-weight: bold;
          height: 5rem;
          width: 75%;
          padding: 0 2.5rem 0 4.5rem;
        }
      }
    }
  }

  .side-sidebar {
    width: 0 !important;
    padding: 0;
  }
}
