@keyframes marquee-start {
  0% {
    text-indent: 0%
  }

  100% {
    text-indent: calc(-120%)
  }
}

@keyframes marquee {
  0% {
    text-indent: 100%
  }

  100% {
    text-indent: calc(-120%)
  }
}

.stat-link-container {
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;

  .stat-link {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 35px;
    padding: 0;
    border-radius: 50px;
    display: flex;
    color: #3C4E64;
    border: 2px solid white;
    margin-bottom: 1rem;
    box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.5);
    .marquee {
      margin: auto 0;
      position: absolute;
      display: block;
      white-space: nowrap;
      top: 50%;
      left: 60px;
      transform: translateY(-50%);
      transition: transform 1s;

      &.slide-left {
        animation: marquee-start 10s linear, marquee 20s 10s linear infinite;
      }
    }
  }

  .badge {
    position: absolute;
    color: #9a9a9a;
    background: #F1F2F4;
    height: 35px;
    font-size: 1rem;
    min-width: 35px;
    border-radius: 50%;
    padding-left: .4rem;
    padding-right: .4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .5);
    .counter {
      display: inline-block;
    }
    .editor {
      display: none;
    }
  }

  &:hover {
    color: #5081be;
    background: #f3f3f3;
    .badge {
      background-color: #f3faff;
      color: #29394D;
      border: none;
      .counter {
        display: none;
      }
      .editor {
        display: inline-block;
      }
    }
  }
}
