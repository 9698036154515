.broadcasted-campaign-header {
  .broadcasted-campaign-container {
    background-color: #F3FAFF;
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    margin-top: 0px;
    border-radius: 5px 5px 0px 0px;
    border-bottom: 1px solid #C7E8FE;
  }

  .icon-container {
    display: inline-block;
    margin-right: 12px;
  }

  .broadcasted-campaign-text {
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #18A0FB;
    size: 20px;
    margin-top: 3px;
    &.second-header {
      color: #29394D;
      margin-left: 3px;
    }
  }
}

.modal-container{
  display: flex ;
}

.modal-popup{
  overflow-y: scroll;
  height: 100%;
}

.modal-content {
  .email-container {
    background-color: #F8F9FA;
    margin-left: 50px;
    margin-top: 23px;
    height: 256px;
    width: 289px;
  }

  .title-container {
    display: inline-block;
    font-weight: bolder;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.75px;
    color: #29394D;
    size: 16px;
    padding-top: 20px;
    padding-left: 20px;
  }

  .text-container {
    display: inline-block;
    font-size: 10px;
    size: 10px;
    padding-left: 20px;
    font-weight: 400;
    line-height: 14.06px;

  }

  .text-email {
    width: 250px;
    height: 36px;
    margin-left: 20px;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #B2BCC6;

    &::placeholder {
      color: #6D7F92;
      size: 12px;
      line-height: 14.06px;
      font-weight: 400;
      text-align: center;
    }
  }

  .button {
    width: 114px;
    height: 38px;
    margin-top: 55px;
    margin-left: 150px;
    border-radius: 4px;
    text-align: center;
    background-color: #18A0FB;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
  }
}

.modal-headline {
  .headline-container {
    width: 289px;
    margin-left: 20px;
    margin-top: 5px;
  }

  .title-container {
    display: inline-block;
    font-weight: bolder;
    font-weight: 400;
    font-size: 16px;
    width: 450px;
    line-height: 18.75px;
    color: #29394D;
    padding-top: 20px;
    padding-left: 20px;
  }

  .text-container {
    display: inline-block;
    font-size: 10px;
    size: 10px;
    padding-left: 20px;
    font-weight: 400;
    line-height: 14.06px;
    width: 450px;
  }

  .broadcaster_item{
    max-height: 53vh;
    width: 750px;
    overflow-y: auto;
  }

  .inline-item {
    display: flex;
    align-items: center;
    width: 702px;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #E1E4E8;
    justify-content: space-evenly;
    margin-left: 20px;
    margin-bottom: 5px;
  }

  .separator {
    width: 1px;
    height: 36px;
    background-color: #E1E4E8;
    margin: 0 10px;
  }

  .item-section1 {
    width: 230px;
    background-color: #F1F2F4;
    padding-left: 10px;
    padding-top: 6px;
    border-radius: 4px;
    margin-left: -31px;
    margin-top: 15px;
    font-size: 12px;
  }

  .item-section2 {
    margin-left: -39px;
    margin-top: 10px;
    font-size: 12px;
    &.second-section {
      font-weight: bolder;
      color: #29394D;
      line-height: 18.75px;
      margin-left: -36px;
      font-size: 12px;
    }
  }

  .item-section3 {
    font-size: 12px;
    margin-left: -40px;

    &.second-section3 {
      font-weight: bolder;
      color: #06D9B1;
      line-height: 18.75px;
      margin-left: -36px;
      font-size: 12px;
      transform: translateX(333%);
    }
  }

  .progress-bar {
    margin-top: 27px;
    margin-left: -125px;
    width: 180px;
    height: 7px;
    background-color: #D8DDE2;
    border-radius: 50px;
    position: relative;
  }

  .progress-bar-container {
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    background-color: #D8DDE2;
  }

  .progress-bar-content {
    height: 100%;
    background-color: #06D9B1;
    transition: width 0.3s ease-in-out;
  }
}

.campaign-go-to {
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  top: 193px;
  left: 137px;
  background-color: #F8F9FA;

  .text {
    width: 200px;
    height: 14px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #29394D;
    margin-left: 1.5rem;
    margin-right: -0.2rem;
    padding-top: 8px;
    align-items: baseline;
  }

  .url {
    width: 686px;
    height: 14px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #6D7F92;
    border: none;
    background-color: transparent;
    margin-left: -0.5rem;
    text-decoration: underline #6D7F92;
  }

  .copy {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    align-items: baseline;
    grid-gap: 10px;
    gap: 8px;
    padding-top: 10px;
    font-size: 12px;
    margin-left: -1.2rem;
    color: #6D7F92;
  }

  .copy-text {
    margin: auto;
    font-size: 12px;
    margin-left: -0.2rem;
    color: #06D9B1;
  }
  .go-to-separator{
    Width:1px;
    Height:14px;
    background-color: #D8DDE2;
    position: sticky;
    margin-right: 24px;
    margin-left: -13px;
    transform: translateY(-74%);
  }
}
