.loading {
  width: 20px;
  margin-left: 0.5rem;
  margin-right: -0.1rem;
}
.svg_check_icons {
  margin-left: 0.3rem;
  margin-right: -0.4rem;
  path {
    fill: #FFF;
  }
}
