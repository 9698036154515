.mailing-list-actions {
  position: absolute;
  top: 5rem;
  right: 10px;
  opacity: 0;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  bottom: 2rem;

  &__action {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
    margin-bottom: -0.3rem;
    position: relative;
    cursor: pointer;
    background: #fff;
    border: 0.5px solid #e9ecef;
    font-weight: bold;

    &:first-child {
      z-index: 1;
    }

    &.edit-action {
      border: 0.5px solid rgba(24, 160, 251, 0.2);
      svg {
        fill: $dodger-blue;
      }
      &:hover {
        background-color: $dodger-blue;
        svg {
          fill: #fff;
        }
      }
    }
    &.delete-action {
      border: 0.5px solid rgba(254, 55, 69, 0.2);
      path {
        fill: #FB1E5B;
      }
      &:hover {
        z-index: 2;
        background-color: #FB1E5B;
        path {
          fill: #fff;
        }
      }
    }
  }
}
