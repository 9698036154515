.campaign-fetching {
  h3 {
    height: 1.3rem;
    width: 90%;
  }
  h4 {
    height: 1rem;
    width: 50%;
  }
  span{
    display: inline-block;
    background-color: #f3f3f3;
    height: 1.4rem;
    width: 22%;
    margin: .2rem;
  }
}

.summary-fetching li{
  justify-content: center;
  div {
    height: 1rem;
  }
  div {
    width: 8rem;
  }
}