.campaign-bubbles {
  position: fixed;
  top: 16rem;
  right: -134px;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  z-index: 1;
  transition: 0.6s all ease-in-out;

  & > div {
    background: #F8F9FA;
    box-shadow: 0px 4px 5px rgba(60, 78, 100, 0.2);
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    transition: nth($transitions, 5);
    cursor: pointer;
    position: relative;
    color: $pickled-bluewood;
    width: 205px;
    height: 62px;
    border-radius: 31px 0px 0px 31px;
    padding: 0 1rem;
    transform: translate(0);

    i {
      font-size: 1.5rem;
      z-index: 2;
      padding: 0 1rem 0 .3rem;
    }

    span, div {
      text-transform: uppercase;
      text-align: center;
      opacity: 0;
    }

    svg {
      fill: $pickled-bluewood;
      width: 26px;
      height: 24px;
    }

    &.send {
      svg {
        fill: #FFFFFF;
      }
    }

    &.circleSvg {
      svg {
        fill: none;

        path {
          fill: $pickled-bluewood;
        }

        circle {
          stroke: $pickled-bluewood;
        }
      }

      &:hover, &.active {
        svg {
          fill: none;

          path {
            fill: #FFFFFF;
          }

          circle {
            stroke: #FFFFFF;
          }
        }
      }
    }

    &:hover, &.active {
      transform: translate(-110px);
      background: #2495E1;
      box-shadow: 0px 4px 5px rgba(109, 127, 146, 0.2);
      color: #fff;

      span, div {
        opacity: 1;
      }

      svg {
        fill: #FFFFFF;
      }

      .padding-left {
        padding-left: 1rem;
      }
    }

    &.success {
      background: #06D9B1;
      color: #fff;
    }

    &.info {
      background: #F1F2F4;
      color: #3C4E64;

      span {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}

.bubbles-sticky {
  right: calc(80% - 125px);
  z-index: 4;
}
