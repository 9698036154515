.news-letter__item {
  position: relative;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 5px;
  width: 307px;
  height: 320px;
  margin: .5rem 1rem 0 2rem;

  &__preview {
    width: 307px;
    height: 132px;
    position: absolute;
    border: 3px solid #FFFFFF;
    border-radius: 5px 5px 0px 0px;
  }

  &__status {
    .item-tag {
      border-radius: 2px;
      padding: 9px 2px;
      margin-right: 147px;
      top: -110px;
      position: relative;
    }

    .btn1 {
      border-radius: 2px;
      padding: 9px 2px;
      top: -128px;
      position: relative;
      margin-left: 220px;
    }

    .btn2 {
      border-radius: 2px;
      padding: 9px 2px;
      top: -145px;
      position: relative;
      margin-left: 220px;
      right: 60px;
    }

    .object {
      border-radius: 2px;
      padding: 30px 2px;
      top: -120px;
      position: relative
    }

    .avatar {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      top: -110px;
      position: relative;

      &__tag {
        border-radius: 2px;
        padding: 10px 2px;
        margin-right: 147px;
        top: -142px;
        position: relative;
        left: 60px;
      }
    }
  }
}
.news-letter{
  line-height: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: start;
  max-width: 82rem;
}
