#notification-page {
  #bubbles {
    top:8em;
  }
  #bubbles > div {
    overflow: visible;
  }
  .notification-params {
    .ttp-tab-container {
      background: unset;
    }
    .tab-pane {
      justify-content: center;
      max-width: 100%;
      textarea {
        box-shadow: none;
      }
    }
    nav {
      button {
        width: 185px;
        height: 45px;
      }
    }
  }
  .ttp-button {
    float: right;
  }
}
