.attachment {
  padding: 5px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  &__container {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    transition: all .2s ease-in;
    box-shadow: 1px 5px 5px 2px #11111120;
    flex: 1 40%;
    margin: 10px 5px 10px 0;

    a {
      display: block;
      height: 220px;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 36px;
      opacity: 0;
      transition: opacity .2s ease-out;
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1;
      i {
        color: #FFF;
        border-radius: 50%;
        transition: 0.3s all ease-in-out;
        cursor: pointer;
        padding: .5rem;
      }
      strong {
        font-size: 1.2rem;
        text-transform: uppercase;
        line-height: 0;
        color: #FFF;
      }
    }

    &:hover {
      opacity: .8;
      transform: scale(1.03);
      span {
        opacity: 1;
        i:hover {
          box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.5);
        }
      }
    }
    &.gradiant {
      min-height: 220px;
    }
    &.notfound {
      padding: 15px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      flex: auto;
    }
  }
}
