@import "components/utils/dropdown-option";
@import "components/utils/dropdown-button";
@import "components/utils/switch";
@import "components/utils/datatable";
@import "components/utils/checkbox";
@import "components/utils/loader";
@import "components/utils/bubble";
@import "components/utils/animation";
@import "components/utils/auto-suggest";
@import "components/utils/add-button";
@import "components/utils/banner";
@import "components/utils/animations";

.Select {
  margin: 0 0 1rem;
}
