body {
  font-family: $main-body-font;
  font-weight: normal;
  color: $main-color;
  font-size: 14px;
  backface-visibility: hidden;
  h1, h2, h3, h4, h5, h6 {
    font-family: $main-body-font;
  }
  a {
    outline: none;
  }
  ul {
    list-style-type: none;
  }

  * {
    outline: none;
  }
  textarea, input {
    box-shadow: none;
  }
  .close {
    position: absolute;
    top: 1.2rem;
    right: 3rem;
    cursor: pointer;
    &:before, &:after {
      content: ' ';
      border-top: 0.1rem solid #B9B9B9;
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;

    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
      left: 0.925rem;
    }
    &:hover {
      &:before, &:after {
        border-color: $gray-dark;
      }
    }
  }
  .loading-bar {
    background: linear-gradient(90deg,#1E86CC,#2495E1,#18A0FB);
    box-shadow: 0 0 10px rgba(119,182,255,0.7);
    height: 0.2rem;
    position: fixed;
    z-index: 99999;
  }
  .empty-avatar {
    background: $bg-empty-avatar;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1), 0 0 0 1px rgba(44, 43, 63, 0.13);
    span {
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
    }
  }
  .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $mask-bg-color;
    z-index: 2;
  }

  .margin-auto {
    margin: auto;
  }

  .icon-left {
    margin-right: .3rem;
  }
  .icon-right {
    margin-left: .3rem;
  }

  .border-radius-4 {
    border-radius: 4px;
  }

  .asterisk {
    color: red;
    font-size: 1rem;
  }
}

// General controls
#controls {
  display: flex;
  justify-content: space-around;
  padding: 1rem 5rem;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  background-color: #fff;
  box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, 0.3);
  transform: translateY(100%);
  transition: nth($transitions, 4);
  &.display {
    transform: translateY(0);
  }

  .controls__label {
    padding: 0 1rem;
  }
}

#loader {
  .svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
#auto-login {
  min-height: 50rem;
  background-color: #fff;
}

select {
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
}

.nothing-found {
  background-color: $athens-gray-lighter;
  padding: .5rem;
  margin: 1.5rem auto;
  color: $pickled-bluewood;
  &__title{
    font-size: .9rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__table-cell{
    display: table-cell;
    vertical-align: top;
    padding : 10px;
  }
}

.icon-control-pause, .warning{
  color: $main-orange;
}
.icon-control-play, .success{
  color: $success-color;
}
.alert {
  color: $alert-color !important;
}
.bg-default {
  background-color: #aaa;
}
.bg-secondary {
  background-color: $secondary-color;
}
.bg-success {
  background-color: #18A0FB;
}
.bg-primary {
  background-color: #06D9B1;
}
.bg-success-opened {
  background-color: #2495E1;
}
.bg-warning {
  background-color: #FFB06A;
}
.bg-alert {
  background-color: #FF636E;
}

.bg-info {
  background-color: #6CA4EA;
}
.bg-draft {
  background-color: #E8EAED;
  color: #241c15 !important;
}

.border-draft {
  border-color: #E8EAED !important;
}

.border-alert {
  border-color: rgba(230,20,10, 1) !important;
}

.border-warning {
  border-color: #FFB06A !important;
}

.border-success, .border-primary {
  border-color: #62DEB0 !important;
}

.border-success-opened {
  border-color: #2495E1 !important;
}

.border-info {
  border-color: #6CA4EA !important;
}

.icon-compass, .icon-refresh{
  color: $gray-dark;
  font-size: 26px !important;
}
.hide{
  display: none !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.ttp-button {
  margin-top: 2rem;
}

.param-label {
  color: var(--dark-1, #29394D);
  margin: .5rem 0px .3rem;
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: $fiord;
  text-indent: 1.2rem;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}
.obligatory{
  color: $alert-color;
  font-size: 1.5rem;
}

.border-danger {
  border: 1px solid #D10C0C;
  &::placeholder {
    color: #D10C0C;
  }
}
.bg-d8{
  background-color: #d8d8d8;
}
.bg-38{
  background-color: #383838;
}

.display-flex{
  display: flex !important;
}

.align-text-center {
  text-align: center;
}

.emailing-logo {
  font-size: 18px;
  cursor: pointer;
  img {
    height: 3rem;
    margin-right: 8px;
  }
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

.text-capitalize {
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
}

.margin-left-auto {
  margin-left : auto !important;
}

.margin-right-auto {
  margin-right : auto !important;
}

.ripple_loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;

  &.fixed-loader {
    position: fixed;
    top: 18rem;
  }
}

.page-title {
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #18A0FB;
  margin: 10px 0 45px;
  text-transform: uppercase;
}

.no-indent {
  text-indent: unset !important;
}

.__react_component_tooltip {
  &.type-dark {
    background: $fiord;
    &.place-bottom::after {
      border-bottom-color: $fiord;
      background: $fiord;
    }
  }
}
