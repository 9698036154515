.sample-selector {
  display: flex;
  height: calc(100vh - 64px);

  &__container {
    flex-grow: 1;
    overflow: scroll;
    height: calc(100vh - 65px);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 28px 116px;
    & > span {
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      text-transform: uppercase;
      color: #000000;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 45px;

    .theme-card {
      transition: transform .3s;
      &.empty-sample {
        border: 1.5px dashed #B2BCC6;
        position: relative;
        background: #FFFFFF;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: transform .3s;
        }
        &:hover {
          img {
            transform: translate(-50%, -50%) scale(1.3);
          }
        }
      }
      &:hover, &.active {
        transform: scale(1.05);
        border: 2px dashed #18A0FB;
        border-radius: 2px;
        box-shadow: 4px 4px 6px 2px rgba(41, 57, 77, 0.1);
      }
    }

  }
}

@media print, screen and (min-width: 1400px) {
  .sample-selector__content > div:not(.not-found) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
