.delivery-info {
  background: #F1F2F4;
  border-radius: 1px 1px 0px 0px;
  padding-right: 2rem;

  &__item {
    display: flex;
    align-items: center;
    min-height: 40px;
    border-bottom: 1px solid rgba(109, 127, 146, 0.2);

    .title {
      min-width: 30px;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
      margin-right: 15px;
      color: #29394D;
      font-weight: normal;
      padding-left: 3rem;
    }

    .delivery-type {
      cursor: pointer;
      border-left: 1px solid #B2BCC6;
      margin-bottom: 8px;
    }

    .filled-box {
      color: #29394D;
      border: 0.5px solid #18A0FB;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .ttp-select {
      min-width: 300px;
      margin: 3px 0;

      .ttp-select__indicator-separator {
        display: none;
      }

      .ttp-select__control {
        font-size: 12px;
        line-height: 14px;
        color: #29394D;
        height: auto;
        min-height: 30px;
      }

      .ttp-select__menu {
        z-index: 2;
      }

      .ttp-select__value-container {
        height: auto;
      }

      .ttp-select__indicators {
        min-height: 30px;
        height: 30px;
      }

      .ttp-select__multi-value {
        padding: 0;
      }

      &__option {
        &:first-child {
          font-weight: 600;
        }
      }
    }

    .content {
      display: flex;
      align-items: center;
    }

    .recipients-emails__input {
      min-height: 26px;
      background: #F1F2F4;
      width: 700px;
      flex-grow: 1;

      .react-tagsinput {
        background-color: transparent;

        .react-tagsinput-tag {
          background: #FFF;
          border: 0.5px solid #29394D;
          box-sizing: border-box;
          border-radius: 4px;
          font-weight: normal;
          font-size: 12px;
          text-transform: lowercase;
          line-height: 8px;
          color: #29394D;
          height: 26px;
          padding: 6px 9px;
          margin: 3px 5px 3px 0px;

          a.react-tagsinput-remove {
            color: #6D7F92;
            font-weight: normal;
          }
        }
      }

      input, input:focus {
        display: inline-block;
        padding-left: 0;
        width: 50%;
        font-size: 12px;
        height: 26px;
        background: #F1F2F4;
      }

      input::placeholder {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: #3C4E64;
      }
    }

    .info_body {
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: #6D7F92;
    }
  }

  .target-type__menu {
    position: relative;
    width: 142px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      background: $fiord;

      .title {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: #FFFFFF;
        padding: .5rem;
      }

      .icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
        background: $pickled-bluewood;
      }
    }

    & > div {
      border-radius: 0 4px 0 0;

      &:before {
        content: " ";
        position: absolute;
        height: 8px;
        width: 8px;
        left: -4px;
        transform: rotate(45deg);
        background: #3C4E64;
        border-radius: 2px 0 0 0;
      }
    }

    ul {
      margin: 0;
      position: absolute;
      left: 0;
      right: 0;
      cursor: pointer;
      z-index: 3;
    }
  }
}
