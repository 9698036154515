
.ttp-year {
    background-color: #F1F2F4;
    height: 38px;
    border-radius: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #3C4E64;
    width: 90px;
    padding-top: 0;
    padding-bottom: 0;
    background-image: url("/img/icons/polygon.svg");
    background-origin: content-box;
    background-position: right -1.2rem center;
    background-repeat: no-repeat;
    background-size: 14px 6px;
    padding-right: 1.5rem;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    border: none;
    cursor: pointer;
    margin: 0;
    &:focus{
        background-color: #F1F2F4;
        border-radius: 20px;
        background-image: url("/img/icons/polygon.svg");
        background-origin: content-box;
        background-position: right -1.2rem center;
        background-repeat: no-repeat;
        background-size: 14px 6px;
        padding-right: 1.5rem;
        transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
        border: none;
        cursor: pointer;
        margin: 0;
    }
}

.ttp-dateRange{
  transition: all .3s;
  background: #F1F2F4;
  border-radius: 30px;
  color: #29394D;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 38px;
  width:230px;
  &__item{
    z-index: 1;
    height: 38px;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #f1f2f4;
    text-align: center;
    padding: 8px 0;
    width: 130px;
    cursor: pointer;
    margin: -5px;
    &.is-active {
      background-color: #29394D;
      color: white;
      z-index: 2;
    }
    &.is-inactive {
      background-color: #F1F2F4;
      color: #3c4e64;
    }
  }

}



.single-date-picker {
  height: 38px;
  input{
   z-index: 1;
   height: 38px;
   border-radius: 26px;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   text-align: center;
   cursor: pointer;
   border: 0;
   color: #3c4e64;
   background: #F1F2F4;
   background-image: url("/img/icons/polygon.svg");
   background-origin: content-box;
   background-position: right -1rem center;
   background-repeat: no-repeat;
   background-size: 9px 6px;
   padding-right: 1.5rem;
   transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
   box-shadow:none;
 }
}

