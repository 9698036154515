.cardCollab {
    display: flex;
    width: 260px;
    padding: 20px 18px;
    align-items: flex-start;
    gap: 20px;
    border-radius: 5px;
    border: 1px solid  #F1F2F4;
    background: #FFF;
    break-inside: avoid;
    margin-bottom: 20px;
  }
  .shortHeight {
    height: 4.5em; 
  }
  
  .fullHeight {
    height: auto;
  }
  .column{
    display: flex;
    flex-wrap: wrap;
  }
  
  .frameGlobal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
  }
  
  .collabFrame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
  
  .frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }
  
  .group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    height: 19px;
  }
  
  .approverImage {
    width: 65px;
    height: 65px;
    border-radius: 65px;
    border: 2px solid var(--Gris-4, #F1F2F4);
    background: lightgray 50% / cover no-repeat;
    text-align: center;
    align-content: center;
    font-weight: bold;
    font-size: 27px;
    color: white;
  }
  
  .approverName {
    color: var(--dark-1, #29394D);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .approverEmail {
    color: var(--dark-1, #29394D);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
  
  .approval {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    margin-top: 20px;
  }
  
.acceptApproval {
  display: flex;
  width: 180px;
  height: 28px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background: #E7FCF8;
}


.pendingApproval {
  display: flex;
  width: 180px;
  height: 28px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background: var(--orange-alerte-2, #FDF3EA);
}
.rejectApproval{
  display: flex;
  width: 180px;
  height: 28px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background: var(--orange-alerte-2, #FDF3EA);
}

.acceptStatus {
  color: var(--vert-2, #02AF8E);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pendingStatus {
  color: var(--orange-alerte-2, #D87318); 
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.rejectStatus{
  color: var(--orange-alerte-2, #EB5757); 
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.approvalIcon{
  margin-top: 7px;
}
  
  .approvalDate {
    color: var(--dark4, #6D7F92);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
  }
  
  .approveButton {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    background: var(--bleu-1, #18A0FB);
    color: var(--blanc, #FFF);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 16.8px */
    cursor: pointer;
  }
  
  .textFrame {
    height: 52px;
    align-self: stretch;
    overflow: hidden;
    color: var(--dark4, #6D7F92);
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    text-wrap: wrap;
  }

  .readMore{
      text-decoration-line: underline;
      cursor: pointer;
    }
  
  