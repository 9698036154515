#campaign-reviews__tab {
  .approver-avatar{
    height: 6rem;
    width: 6rem;
  }
  
  .approvers-block{
    margin: .6rem;
  }

  .approver-team {
    display: flex;
    &__avatar {
      span {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
        line-height: 2.5rem;
        margin-right: 0.5rem;
        border: 2px solid #fff;
        box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1), 0 0 0 1px rgba(44, 43, 63, 0.13);
      }
    }
    &__detail {
      display: flex;
      flex-direction: column;
      span {
        font-size: .7rem;
        &:first-child {
          font-size: .9rem
        }
      }
    }
  }


  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 250px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 18px -4px rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  
  .flip-card-back {
    background-color: #2980b9;
    color: #000;
    transform: rotateY(180deg);
    padding: .7rem;
    .ttp-button {
      background: #fff;
      text-align: center;
      display: inline-block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    h3 {
      text-align: center;
    }
  }

  .approver-icon {
    position: absolute;
    top: 0;
    right: 4rem;
    font-size: 2rem;
    background-color: #fff;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    color: #CCC;
    cursor: pointer;
    z-index: 1;
    .active {
      transition: nth($transitions, 2);
      color: $main-blue;
    }
  }
  .icon-check {
    color: $persian-green;
  }
  .icon-clock {
    color: $tango;
  }
  .icon-ban {
    color: $coral-red;
  }
  .pending {
    background: #FFFAF3;
  }
  .reject {
    background: #FFD5D5;
  }
  .approve {
    background: #F1FEF9;
  }
}
