$pie_chart_width: 140px;

.piechart{
  background-size     : cover;
  background-position : 50% 50%;
  margin              : 0 0 10px;
  transition          : all .3s ease-in-out !important;
  &-wrapper{
    position: relative;
  }
  &-container{
    position: relative;
    display: table;
    margin: 0 auto;

    .piechart__percentage, .piechart__search{
      text-align: center;
      position: absolute;
      width: 100%;
      z-index: 2;
      color: $gray-dark !important;
      border-radius: 50%;
      cursor: pointer;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &-number{
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
      }
      &.hover-disabled {
        cursor: default;
      }
    }

    .piechart__search{
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-magnifier{
        font-size: 3.5rem;
        font-weight: bold;
        color: #FFF;
      }
    }

    &:hover{
      .piechart__percentage{
        display: none;

        &.hover-disabled {
          display: flex;
        }
      }
      .piechart__search{
        opacity:0.7;
        transform: scale(.5);
        visibility: visible;
      }
    }
}

  svg{
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
  &-heading{
    background-position: 50% 50%;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #3C4E64;
  }
  &-circle__empty{
    stroke-width: 15;
    box-sizing: border-box;
  }
  &-circle__fill{
    stroke-width: 15;
    stroke-linecap: round;
    transition: all 3s ease-in-out;
  }

&-info{
    cursor: pointer;
    font-size: 1rem;
    i {
      font-weight: 700;
    }
  }
}
