.embedded-form-page{
  h4{
    text-transform: uppercase;
    font-size: 1rem;
    color: $gray-dark;
    font-weight: 700;
    margin: 1.5rem 0 0.5rem;
  }
  .embedded-form{
    &__content{
      width: 70%;
      margin-left: $list-side-margin;
      margin-bottom: .5rem;
      font-size: 1rem;
      font-weight: 400;
      color: $black;
    }
    &__item{
      text-align: center;
      position: relative;
      display: block;
      padding: $status-list-item-padding;
      background-color: $status-list-item-bg-color;
      color: $status-list-item-color;
      height: $status-list-item-height;
      margin: $status-list-item-margin-top 0;
      cursor: pointer;
      line-height: $status-list-item-line-height;
      font-weight: bold;
      transition : 0.5s width ease-in-out;
    }

    &__item:hover, &__item.activated {
      left: -$status-list-item-margin-top;
      right: 0px;
      width: -webkit-calc(100% + #{(2*$status-list-item-margin-top)});
      width:    -moz-calc(100% + #{(2*$status-list-item-margin-top)});
      width:         calc(100% + #{(2*$status-list-item-margin-top)});
      color: $status-list-item-hover-color;
      background-color: $status-list-item-hover-bg-color;
    }

    #classic:hover, #classic.activated {
      background-color: $main-dark-gray;
    }

    #slim:hover, #slim.activated {
      background-color: $main-orange;
    }
    .menu__language li{
      padding: .3rem 1.3rem;
    }
  }
  .options-list{
    &__item{
      input[type="text"]{
        margin: .5rem 0 0;
        box-shadow: none;
      }
      label{
        font-size: 1rem;
        display: flex;
        margin-top: 1rem;
      }
    }
  }

  iframe{
    border: 1px solid $medium-gray;
    width: 100%;
  }
  .snippet-container{
    border: 1px solid $medium-gray;
    textarea{
      border: none;
      box-shadow: none;
    }
    button{
      cursor: pointer;
      padding: .3rem;
      font-weight: 700;
      text-transform: capitalize;
      background-color: $status-list-item-bg-color;
    }
  }
}
