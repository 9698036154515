.styled-tabs {
  position: relative;
  margin-top: 2rem;

  &__header {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    margin-bottom: 13px;
    cursor: pointer;
    .control {
      width: 36px;
      height: 36px;
      background: #FFFFFF;
      border: 1px solid rgba(109, 127, 146, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      svg path {
        fill: #29394D;
      }
      &.next{
        transform: rotate(90deg);
      }
      &.previous{
        transform: rotate(-90deg);
      }
    }
    &-items {
      display: flex;
      overflow: hidden;
      height: 36px;
    }
    .control-1 {
      max-width: calc(100% - 36px - 10px);
    }
    .control-2 {
      max-width: calc(100% - 2*36px - 10px);
    }
    .tab {
      border: 1px solid rgba(109, 127, 146, 0.2);
      box-sizing: border-box;
      border-radius: 50px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #6D7F92;
      background: #FFFFFF;
      padding: 10px 18px;
      margin-right: 8px;
      transition: all .3s ease-in;
      display: flex;
      justify-items: center;
      align-items: center;
      white-space: nowrap;
      &:hover, &.active {
        background: #6D7F92;
        box-sizing: border-box;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(60, 78, 100, 0.3);
      }
    }
  }

  &__content {
      display: flex;
      background: #FFFFFF;
      border: 0.5px solid #F1F2F4;
      box-sizing: border-box;
      border-radius: 0px 4px 4px 4px;
      padding: 25px;
  }
}
.styled-tabs.light {
  margin: 0;
  .styled-tabs__header {
    .tab {
      background: #F1F2F4;
      border-radius: 2px;
      border: none;
      color: #6D7F92;
      padding: 6px 18px;
      text-transform: capitalize;
      &:hover, &.active {
        background: #6D7F92;
        color: #FFFFFF;
      }
    }
    .control-1 {
      max-width: calc(100% - 26px - 10px);
    }
    .control-2 {
      max-width: calc(100% - 2*26px - 10px);
    }
    .control {
      width: 26px;
      height: 26px;
      background: #F1F2F4;
      border-radius: 2px;
      border: none;
    }
    &-items {
      height: auto;
    }
  }
}

.tab-slider {
  display: flex;
  align-items: center;
  background: #F1F2F4;
  border-radius: 16px;
  height: 32px;
  position: relative;
  max-width: 240px;
  &__item {
    padding: 9px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: capitalize;
    color: #3C4E64;
    border-radius: 16px;
    display: flex;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    width: 120px;
    svg {
      margin-right: 10px;
      path {
        fill: #3C4E64;
      }
    }
  }
  .active {
    position: relative;
    background: #3C4E64;
    color: #FFFFFF;
    transition: all .5s ease-in;
    svg {
      path {
        fill: #fff;
      }
    }
  }
}
