@import "add_block_side_bar";
@import "progress-bar";

#template-preview {
  display: flex;
  justify-content: space-around;

  .card-actions {
    position: absolute;
    z-index: 1;
    div{
      background: #ffffff;
      border: 0.5px solid #c7e8fe;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.04);
      border-radius: 4px;
      width: 36px;
      height: 36px;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2px 2px .2rem;
      cursor: pointer;
      transition: all 0.1s ease;

      .double-down, .down {
        transform: rotate(180deg);
      }

      &:hover {
        background: #18a0fb;
        svg * {
          fill: #fff;
        }
      }
    }
    display: none;

    &.float-right {
      right: 0;
      svg * {
        fill: #18a0fb;
      }
    }
  }

  .layout-item {
    margin: 0;
    transition: transform .3s ease-in;
    .drop_placeholder {
      position: absolute;
      top: 2px;
      bottom: 2px;
      right: 2px;
      left: 2px;
      justify-content: center;
      border: 2px dashed #18A0FB;
      border-radius: 5px;
      z-index: 1;
      background-color: #F3FAFF;
      background-size: 40px 40px;
      background-position: 0 0, 20px 20px;
      opacity: .9;
    }
    .card {
      display: flex;
      border: 1px dashed gray;
      margin: .4rem;
      background-color: #293039;
      cursor: move;
      align-items: baseline;
    }
    &:hover {
      .card-actions {
        display: block;
      }
    }

    .layout-item__img {
      border: none;
      margin: 0;
      overflow: visible;
      & > {
        transition: height .2s ease .3s;
      }
    }

    &.remove {
      transform: translateX(100%);
      .card-actions {
        display: none;
      }
      .layout-item__img > div {
        height: 0 !important;
      }
    }
    &.highlight {
      transform: scale(120%);
      transition: all ease-in .3;
      z-index: 9;
    }
  }

  iframe {
    width: 182%;
    height: 182%;
    transform: scale(0.55);
    transform-origin: 0% 0%;
    cursor: pointer;

    body {
      margin: 0;
    }
  }

  .unreferenced {
    position: absolute;
    overflow: hidden;
    border: none;
    pointer-events: none;
  }

  .iframe-container {
    position: relative;
    width: 100%;
    transition: all .2s ease-in;
    iframe {
      border: none;
    }
    & > img {
      position: absolute;
      bottom: 1rem;
      left: 1.3%;
      opacity: 0;
      transition: all 0.2s ease;
      width: 16px;
    }
    &::after {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 1px;
    }
    &.border-alert {
      &::after {
        border-color: #FF5656;
      }
    }
    &:hover {
      & > img {
        left: 0;
        opacity: 1;
      }
    }

    &:hover, &.active {
      border: none;
      &::after {
        background: transparent;
      }
    }
    &.active {
      &::after {
        border: 1px solid rgba(24, 160, 251, 0.8);
      }
    }
  }
}
