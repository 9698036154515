#image-selector, #theme-manager {
  .uploader-container {
    input[type="text"] {
      box-shadow: none;
      border-radius: 4px;
    }
    button {
      margin: 1rem 0 0 1rem;
    }
  }


  .image-container {
    position: relative;
    min-height: 20rem;
    width: 100%;
    background-color: #F8F9FA;;
    color : #6D7F92;
    text-align: center;
    cursor: pointer;
    margin-bottom: .8rem;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 2px solid #F1F2F4;
    max-height: 3rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;

    p{
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
    }

    .icon-cloud-upload {
      font-size: 3rem;
      padding-bottom: 1rem;
      display: inline-flex;
    }

  }


  .gallery {
    display: flex;
    text-align: center;

    &__item {
      cursor: pointer;
      border: 1px solid #d3d3d3;
      position: relative;
      margin: 1rem 0.3rem;
      min-height: 5rem;

      img {
        vertical-align: middle;
        width: 100%;
      }

      p {
        position: absolute;
        bottom: 0;
        background-color: rgba(0, 0, 0, .4);
        margin: 0;
        width: 100%;
        font-size: .9rem;
        color: #fff;
        font-weight: 700;
        text-align: left;
        opacity: 0;
        height: 0;
        transition: all .4s linear;
        padding: 0 .5rem;
      }

      &:hover {
        border: 2px solid #5081be;
        p {
          padding: .7rem .5rem;
          opacity: 1;
          max-height: 100%;
          height: auto;
        }
      }
    }

    &__fetching-item {
      height: 18rem;
      display: flex;
      align-items: flex-end;
      .loader {
        margin: auto;
      }
    }
  }
  .app-switch {
    margin-left: .5rem;
  }

  hr {
    margin: 1rem auto;
  }

  & > div {
    padding: 1.5rem 0 0;
  }
}
