@import "top-bar";
@import "delivery-info";
@import "bottom-bar";

$widget-height: 566px;
$widget-width: 820px;

$minimized-widget-height: 36px;
$minimized-widget-width: 316px;

#email-composer {
  box-shadow: 0px 2px 30px rgba(41, 57, 77, 0.1);
  border-radius: 5px 5px 0px 0px;
  background: #fff;
  height: 100vh;

  .subject-box {
    background: #fff;
    border: 1px solid rgba(109, 127, 146, 0.2);

    &:focus {
      border: 1px solid #18A0FB;
    }
  }

  &.email-composer__widget {
    position: fixed;
    bottom: 0;
    z-index: 4;
    right: 0;
    height: 70vh;
    margin-right: 100px;
    top: 40%;
    width: 1264px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.email-composer__minimized {
    width: $minimized-widget-width;
    height: $minimized-widget-height;
    min-height: 0;
    left: unset;
    transform: unset;
    top: unset;

    .email-composer-model__body, .rich-editor-component, .widget_bottom-bar {
      display: none;
    }
  }

  .email-composer-model {
    box-shadow: 1px 1px 50px 1px rgba(0, 0, 0, 0.05);
    background-color: #F8F9FA;
    min-width: 680px;
    border-radius: 5px 5px 0px 0px;
    height: 124%;

    &__body {
      padding-bottom: 2rem;
      height: calc(100vh - 122px);
      overflow: scroll;

      .rich-editor__text-area {
        min-height: 230px;
      }
    }
  }

  &.email-composer__widget .email-composer-model__body {
    height: calc(100% - 92px);
  }
}

