@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
}

@keyframes growing {
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes bg-scroll-up-down {
  from {
    background-position: top center;
  }
  to {
    background-position: bottom center;
  }
}

@keyframes clignote {
  0%{opacity: 1}
  50%{opacity: .7}
  100%{opacity: 1}
}