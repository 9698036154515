$slick-prev-character: url('/img/icons/left.png') !default;
$slick-next-character: url('/img/icons/right.png') !default;

#campaign-params__tab {

    .tooltip-info {
      cursor: pointer;
      margin-left: .5rem;
    }
    .label-from {
      display: flex;
      flex-direction: column;
      &__name {
        color: #29394D;
      }
      &__email {
        color: #2495E1;
      }
    }
    .ttp-select__option--is-selected .label-from__email {
      color: #fff;
    }
    .email-field svg{
      right: auto;
      left: 0;
    }
}

.form-error {
    margin: 0.3rem 0 0;
    font-weight: normal;
}

.empty-box, .empty-box input {
  background: #F8F9FA;
  border: 1px solid #B6BFC8;
  box-sizing: border-box;
  border-radius: 5px;
  color: #6D7F92;

  &::placeholder {
    color: #3C4E64 !important;
  }

  .ttp-select {
    &__control {
      background: #FAFBFB;
      border: 1px solid #F1F2F4;
    }
  }
}

.empty-date-picker{
  .DateInput {
    background: #FAFBFB;
    border: 1px solid #F1F2F4;
    box-sizing: border-box;
    border-radius: 4px;
    color: #6D7F92;
  }
}
.filled-date-picker {
  .DateInput {
    background: #f3faff;
    border: 1px solid #F3FAFF;
  }
}

.filled-box {
  background: #FFFFFF;
  border: 1px solid #18A0FB;
  box-sizing: border-box;
  border-radius: 5px;
  color: $pickled-bluewood;
  margin: 0;

  .ttp-select__value-container {
    color: $pickled-bluewood;
    font-weight: normal;
    padding-left: 1rem;
  }

  .ttp-select {
    &__control {
      border: 1px solid #F3FAFF;
    }

    &__dropdown-indicator {
      color: #6D7F92;
    }

    &__indicator-separator {
      background-color: #D8DDE2;
    }
  }

  .react-tagsinput-input, .react-tagsinput-input:focus {
    border: none;
    box-shadow: none;
  }
}

.error-box {
  border-radius: 4px;
  .ttp-select__control {
    border: 0.5px solid rgba(254, 55, 69, 0.5);
    background: rgba(254, 55, 69, 0.2);
  }
  .react-tagsinput-input,  .react-tagsinput-input:focus{
    border: none;
  }
}

.email-field {
  position: relative;
  svg {
      position: absolute;
      width: 14px;
      height: 14px;
      right: 14px;
      top: 0;
      bottom: 0;
      margin: auto;
  }
}

h1 {
  padding-bottom: .5rem;
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  box-shadow: unset;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

.required {
  color: #18A0FB;
  margin-left: 2px;
}
