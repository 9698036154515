.free-section-form {
  position: relative;
  box-sizing: border-box;
  padding: 2rem 1rem 30rem;
  overflow-x: hidden;
  overflow-y: scroll;

  &__item {
    background: #FFFFFF;
    border: 1px solid #D8DDE2;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;


    span {
      font-size: 12px;
    }

    &-question {
      display: flex;
      color: #29394D;
      align-items: center;
    }

    &-input {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      position: relative;

      .react-datepicker-wrapper {
        width: auto;

        input {
          background-image: url("/img/icons/calendarInput.svg");
          background-repeat: no-repeat;
          background-position: 16.5rem;
        }
      }

      .fs-input, .ttp-select__control{
        width: 288px;
        height: 34px;
        background: $athens-gray-lighter;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid $cadet-blue;
        box-shadow: none;
        margin: 7px 1rem 7px 0;
        color: #29394D;
        font-size: 12px;

        &:focus {
          border: 1px solid $btn-info-color;
        }

        &::placeholder {
          color: #6D7F92;
        }
      }

      .currency {
        width: 50px;
        height: 24px;
        position: absolute;
        background: #D8DDE2;
        border-radius: 3px;
        font-size: 12px;
        top: .75rem;
        left: 14.3rem;
        padding: 0;
        font-weight: 500;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
        background-origin: content-box;
        background-position: right .5rem center;
        background-repeat: no-repeat;
        background-size: 9px 6px;
      }
    }

    &-example {
      display: flex;
      font-size: smaller;
      text-transform: capitalize;
      color: #6D7F92;

      span {
        padding-left: 5px;
        color: #29394D;
      }
    }
  }
}

