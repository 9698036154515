.user-suggestion {
  .react-autosuggest__suggestion {
    padding: 0 !important;
  }

  .react-autosuggest__suggestions-container--open {
    padding: 0;
  }

  &__item {
    padding: 0.3rem 1rem;
    box-shadow: 0 0.06rem 0 0 rgba(44, 43, 63, .1);
    display: flex;

    .img-wrap {
      margin-right: 1rem;
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 3rem;
      overflow: hidden;
      background-size: cover;
      background-position: center;

      img {
        width: 100%;
        height: auto;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      max-width: 86%;

      span {
        font-size: 0.8rem;
        color: #bbb;
      }

      h4 {
        font-size: .8rem;
        font-weight: 600;
        margin: 0;
      }

      p {
        font-size: 0.8rem;
        color: #999;
        margin: 0;
        line-break: anywhere;
      }
    }
  }
}
