.picked-theme {
    display: flex;
    position: relative;
    background: #F1F2F4;
    box-sizing: border-box;
    border-radius: 4px;
    transition: border .2s ease-out;
    border: none;
    cursor: pointer;
    height: 355px;
    width : auto;
    margin-left: 1rem;
    &:hover {
      border: 2px solid #18a0fb;
      .theme-delete__action {
        display: block;
        &:after {
          opacity: 0;
        }
        img{
          width: 34px;
          height: 19px;
          margin-top: 7px;
        }
      }
    }

    & > div {
        width: 100%;
        overflow: hidden;
        height: 100%;
    }
    &__lng {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        font-size: 16px;
        color: #29394D;
    }
    &__label {
        position: absolute;
        top: 8px;
        left: 8px;
        color: #fff;
        background: rgba(109, 127, 146, 0.9);
        padding: 0 12px;
        font-size: 12px;
        border-radius: 50px;
        text-transform: uppercase;
    }
    &__preview {
        text-align: center;
        border: 3px solid#fff;
    }
    &__detail {
        position: absolute;
        bottom: 0;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 0px 0px 4px 4px;
        font-size: 16px;
        padding: 18px 16px;
        color: #29394D;
        display: flex;
        justify-content: space-between;
        height: 55px;
        width: 100%;
        align-items: center;
    }
    &.active {
        box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
        border: 2px solid #2495E1;
        &:after {
            content: " ";
            position: absolute;
            top: 100%;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-top: 14px solid#2495E1;
        }
    }
}
#theme-picker {
    min-height: 500px;
    .theme-picker {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(41, 57, 77, 0.1);
        border-radius: 4px;
        min-height: 400px;
        margin-top: 38px;
        transition: all .3s ease-in;
        &__open {
            transform: scale(1.05);
            box-shadow: 0 0 25px rgba(24, 160, 251, 0.2);
        }
        &__list {
            padding: 38px 52px 75px;
        }

        &__item {
          padding: 0 7px;
        }

        .simple-theme {
            position: relative;
            cursor: pointer;
            width: auto;
            height: 330px;
            border: 3px solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
            border-radius: 4px;
            overflow: hidden;
            margin: 0 auto 2rem;

            &:hover {
                .apply-button {
                    bottom: 0;
                }
                }
        }
    }
}
.theme-delete {
  &__action {
    margin: 3px 0 0 241px;
    width: 35px;
    height: 35px;
    background: #FF5656;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    z-index: 0;
    display: none;
  }
}

