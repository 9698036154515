#sms-bill-detail {
  h4 {
    font-size: 24px;
    line-height: 28px;
    color: #29394D;
    text-transform: uppercase;
  }
  .bill-detail {
    margin: 1.3rem 0;
    & > div {
      background: #F3FAFF;
      border-radius: 2px;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      padding: 11px 25px;
      color: #3C4E64;
      span {
        font-weight: 500;
      }
      &:not(:first-child) {
        margin-left: .4rem;
      }
    }
  }
}
