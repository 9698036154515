.bubble{
    position: absolute;
    transition: .5s all;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-30px);
    display: flex;
    justify-content: center;
    align-items: center;
    .icon{
        font-size: 1.5rem;
        color: white;
        font-weight: 600;
    }
    &:hover{
        background-color: #fff;
        color: #fff;
        box-shadow: 0px 2px 5px 1px rgba(0,0,0,.2);
        .icon{
            color: #505050;
        }
    }
}