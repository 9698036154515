.campaign_filters {
  .filter {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &__label {
      font-size: 15px;
      color: #3C4E64;
      width: 20%;
    }
    &__value {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      span {
        background: rgba(109, 127, 146, 0.1);
        border: 0.5px solid #F1F2F4;
        border-radius: 2px;
        font-size: 14px;
        padding: 5px 10px;
        color: rgba(109, 127, 146, 0.9);
        margin: 2px 4px;
        text-transform: uppercase;
      }
    }
  }
}
