.add-button-container {
  position: relative;
  background: #fff;
  padding: .5rem;
  display: flex;
  overflow: hidden;
  border: 1px solid #c7c7c7;
  border-left: none;
  margin: 2rem 0;

  &:hover {
    background: #fefefe;
    
    .add-button {
      transform: translateY(0);
      opacity: 1;
      &.reverse {
        transform: translateY(0);
      }
    }

    .add-icon {
      transform: translateY(-120%);
    }
  }

  .add-button {
    width: 100%;
    text-align: center;
    border: 1px solid #18a0fb;
    border-radius: 2px;
    padding: 2rem 0;

    transition: all .5s;
    z-index: 1;
    transform: translateY(-120%);
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    opacity: 0;
    color: #18a0fb;
    background-color: #fff;
    border: 0.5px solid#c7e8fe;
    box-shadow: 0px 0px 10pxrgba(41, 57, 77, 0.04);
    border-radius: 4px;
    &:hover {
      transform: scale(1.1);
      z-index: 2;
      background: #18a0fb;
      color: #fff;
      border: none;
    }

    &.reverse {
      transform: translateY(120%);
      margin: 0 .5rem;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .add-icon {
    position: absolute;
    right: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    z-index: 1;
    transition: transform .4s;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 5.3rem;
    .icon {
      width: 24px;
      margin-right: 8px;
    }
  }
}
