.attachments-container {
  color: #6D7F92;

  .attachments-dropzone {
    padding: 2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    background: #FAFBFB;
    border: 1px solid #F1F2F4;
    box-sizing: border-box;
    border-radius: 4px;
    align-items: center;
    margin-top: 1rem;

    .icon-cloud-upload {
      font-size: 3rem;
      padding-bottom: 1rem;
      display: inline-flex;
    }
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

  .attachments-list {
    padding: 0;
    margin: 0 3rem;

    .attachment {
      background: white;
      border: 1px solid rgba(109, 127, 146, 0.2);
      box-sizing: border-box;
      border-radius: 5px;
      width: 510px;
      box-shadow: 0 0 5px rgba(41, 57, 77, 0.1);
      align-items: center;
      margin: .3rem .1rem;
      padding: .5rem 1rem;
      text-transform: none;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: inline-block;

      &-delete {
        font-size: 1.2rem;
        cursor: pointer;
        float: right;

        &:before {
          content: " \D7";
        }
      }

      &-open:hover {
        font-weight: bold;
        cursor: pointer;
      }

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: #29394D;

        &.size {
          font-weight: normal;
          padding-left: .3rem;
        }
      }

      a {
        color: #29394D;
      }
    }
  }
}
