.ttp-select {
    &.uppercase {
        .ttp-select__menu, .ttp-select__value-container {
            text-transform: uppercase;
            font-weight: 400;
        }
    }
    .ttp-select__value-container.ttp-select__value-container--is-multi {
      padding-top: 0;
      padding-bottom: 0;
    }
    &__multi-value {
        background-color: #C7E8FE !important;
        padding: 4px 6px;
        box-shadow: 1px 1px 2px 1px rgba(41, 57, 77, 0.1);
        font-weight: 500;
        border-radius: 5px;
    }
    &__control {
        &--is-focused {
            box-shadow: 0 0 0 1px #18A0FB !important;
            &:hover {
                border-color: #18A0FB !important;
            }
        };
    }
    &__option {
        &--is-selected, &--is-selected:hover {
            background: #18A0FB !important;
        }
        &:hover {
            background: #F3FAFF;
        }
    }
  .ttp-select__placeholder{
    margin-left: 2px;
    margin-right: 2px;
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
  }
}
.ttp-select__input input[type="text"]{
    height: auto;
    margin: 0;
    box-shadow: none;
}


.error .ttp-select {
    &__control {
        background: #f9ecea;
        border: 1px solid #cc4b37;
    }
}

.select-option{
    &__label, &__content{
      display: block;
    }
    &__label{
      font-size: 1rem;
      color: $main-dark-gray
    }
    &__badge {
      background-color: #EBECF0;
      border-radius: 2em;
      color: #172B4D;
      font-size: 12px;
      font-weight: 'normal';
      padding: 0.16em 0.5em;
      text-align: center;
      margin-left: 1rem;
    }
    &__content{
      font-size: .9rem;
    }
  }
