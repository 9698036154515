.ttp-alert-dialog {
  text-align: center;

  .alert-header {
    display: flex;
    border-radius: 5px 5px 0 0;
    &__title {
      margin: 0.6rem auto;
      font-size: 22px;
      position: relative;
    }
    .icon-container {
      height: 5rem;
      width: 5rem;
      position: absolute;
      border-radius: 50%;
      top: 1rem;
      svg {
        width: 22px;
        height: 22px;
        margin-top: 2.5rem;
      }
      .question-icon {
        fill: none;
      }
    }
    &.header-alert {
      border: 0.5px solid #ffdbdd;
      background: #fff5f6;
      .icon-container {
        background: #fff5f6;
        border-bottom: 0.5px solid #ffdbdd;
        svg {
          fill: #ff636e;
          stroke: #ff636e;
        }
      }
      p {
        color: #ff636e;
      }
    }
    &.header-success {
      border: 0.5px solid #c7e8fe;
      background: #f3faff;
      .icon-container {
        background: #f3faff;
        border-bottom: 0.5px solid #c7e8fe;
        svg {
          fill: #2495e1;
          stroke: #2495e1;
        }
      }
      p {
        color: #2495e1;
      }
    }
    &.header-primary {
      border: 0.5px solid rgba(98, 222, 176, 0.3);
      background: #f1fbf9;
      .icon-container {
        background: #f1fbf9;
        border-bottom: 0.5px solid rgba(98, 222, 176, 0.3);
        svg {
          fill: #06d9b1;
          stroke: #06d9b1;
        }
      }
      p {
        color: #06d9b1;
      }
    }
  }

  .alert-message {
    font-size: 18px;
    margin: 5rem auto 3rem;
    color: #3c4e64;
    &.resend-message {
      display: block;
      line-height: 4rem;
      margin-top: 3rem;
    }

    &.ttp-alert-scheduled {
      margin: 3rem auto 1rem;

      .m-input-moment .tab {
        height: 331px;
      }

      .m-calendar .toolbar button {
        font-size: 14px;
        padding: 1px;
      }
    }
  }

  .alert-footer {
    display: block;
    justify-content: space-between;
    .button {
      margin: 1.2rem 1%;
      width: 48%;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      padding: 0.8rem;
      border-radius: 5px;
    }

    .cancel-button {
      background-color: rgba(109, 127, 146, 0.6);
      width: auto;
    }

    .ok-button:hover,
    .cancel-button:hover {
      border: 1px solid rgba(0, 0, 0, 0.8);
      background-color: #fff;
      color: black;
    }
    &:before {
      content: "";
      display: block;
      border-bottom: 1px solid #f1f2f4;
      bottom: 5rem;
      left: 0.5rem;
      right: 0.5rem;
      position: absolute;
    }
  }

  .modal-checkbox-group {
    text-transform: uppercase;
    text-align: left;

    .checkbox {
      padding: 5px;
    }
  }
  .modal-description {
    display: flex;
    justify-content: center;
    height: 114px;
    padding: 51px 20px 20px;
    border-bottom: 1px solid #f1f2f4;
    font-size: 18px;
    line-height: 21px;
    color: #29394d;
    text-align: center;
  }
}

//prevent overflow-y on the body while the modal is open
.ReactModal__Body--open {
  overflow-y: hidden;
  //position: fixed;
  width: 100%;
  height: 100%;
}
