.ge-viewer {
  height: 100%;
  overflow: hidden;

  &__container {
    height: 100%;
    width: 100%;
    padding: 36px 8px 2rem;

    &-content {
      height: 100%;
      overflow: scroll;
    }
  }

  &__aside {
    height: 90vh;
    width: 100%;
    background: #F8F9FA;
    position: relative;
    border-radius: 0 0 5px 0;
    box-shadow: -2px 0px 2px 0px rgba(238, 238, 238, 0.46);

    .save-btn-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      min-height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      padding: 0 1rem;
    }

    &-content {
      height: 100%;
      width: 100%;
      padding-top: 62px;
      border-radius: 0 0 5px 0;
      overflow: hidden;

      iframe {
        border: none;
        width: 125%;
        height: 125%;
        transform: scale(.8);
        transform-origin: 0 0;
      }

      &.scale-preview {
        iframe {
          width: 250%;
          height: 250%;
          transform: scale(.4);
        }
      }
    }
  }
}
