.notifications-list {
    &__lang {
        display: flex;
        flex-direction: column;
        margin: 0 1rem 0 0;
        li {
            background: #FFFFFF;
            border: 0.5px solid #C7E8FE;
            box-sizing: border-box;
            box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.04);
            border-radius: 50px;
            font-family: Roboto;
            color: #18A0FB;
            padding: .3rem .8rem;
            margin: .2rem;
            text-transform: uppercase;
            font-weight: normal;
            transition: all .3s ease-in;
            i {
                margin-right: .8rem;
            }
            &:hover {
                box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.02);
            }
            &.active {
                background: rgba(24,160,251, .7);
                color: #fff;
            }
        }
    }
    &__target {
        ul {
            margin-left: 0;
        }
        li {
            display: block;
            align-items: center;
            font-size: 1rem;
            margin: .3rem 0;
            text-transform: lowercase;
        }
        .icon {
            font-weight: 700;
        }
        span {
            margin-right: .3rem;
        }
        .counts{
            display: none;
        }
    }
    .ttp-datatable__row {
        & > div::after {
            right: 83%;
        }
        & > div:nth-child(2)::after {
            right: 67%;
        }
        & > div:nth-child(3)::after {
            right: 50.7%;
        }
        & > div:nth-child(4)::after {
            right: 26.5%;
        }
        & > div:nth-child(5)::after {
            right: 17%;
        }
        .list-item__actions {
            margin-left: 4rem;
            width: 100%;
        }
        .list-item__status {
            margin: 2rem .5rem 0 0;
        }
        &:hover {
            background: #F1F2F4;
            .counts {
                display: block;
            }
            .percents {
                display: none;
            }
        }
    }
}
