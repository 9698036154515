.apply-button {
    position: absolute;
    bottom: -96px;
    width: 100%;
    height: 75px;
    background: #F3FAFF;
    border-radius: 0px 0px 1px 1px;
    transition: .3s ease;

    a {
      font-style: normal;
      font-weight: normal;
      text-align: center;
      font-size: 14px;
      line-height: 16px;
      color: #18A0FB;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }

    .apply-icon {
      position: absolute;
      width: 40px;
      height: 40px;
      left: 50%;
      top: calc(100% - 95px);
      transform: translateX(-50%);
      border-radius: 50%;
      background: #2495E1;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    }

    &.applied {
      bottom: 0;
    }
  }