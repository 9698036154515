.side-page-header {
  height: 60px;
  text-transform: capitalize;
  background: #F3FAFF;
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
  border: .5px solid rgba(199, 232, 254, 0.5);

  &__info {
    display: flex;
    align-items: center;
    padding-left: 81px;

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: $slate-gray;
      margin: .5rem;

      &.sub-header {
        color: $curious-blue;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  &__button {
    background: $athens-gray-lighter;
    border: 1px solid $cadet-blue;
    color: $slate-gray;
    height: 36px;
    min-width: 116px;
    border-radius: 5px;
    margin: 0 10px 0 0;

    span {
      font-size: 14px;
    }
  }

  &__action {
    justify-content: flex-end;
    display: flex;
    width: 64.8%;
    align-items: center;

    button {
      border: 1px solid #C7E8FE;
    }
  }
}
