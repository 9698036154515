#campaign-send__tab {
  .right-side {
    margin: auto;
    .input-moment {
        .m-calendar .toolbar {
          margin-bottom: 0;
          button {
            font-size: 14px;
          }
        }
    }
    input[type="text"]{
      display: inline-block;
      font-size: 1rem;
      width: 50%;
      margin: 0 1rem .2rem 0;
      border: none;
    }
    label {
      display: flex;
      margin: 0 0 1rem;
      font-size: 16px;
      line-height: 19px;
      color: $pickled-bluewood;
      text-transform: uppercase;
      & > [type="radio"] {
      margin: 0;
      }
    }
  }

  .alert{
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: left !important;
  }
  .alert-success{
    color: #149343 !important;
    background-color: $success-background-color;
    border-color: $success-background-color;
    .icon-check {
      color: #149343 !important;
    }
  }
  .alert-error{
    color: $error-color !important;
    background-color: $error-background-color;
    border-color: $error-background-color;
    .icon-close{
      color: $error-color !important;
    }
  }
}

.links-inventory {
  textarea {
    border-radius: 4px;
  }
  .edit {
    display: none;
    svg {
      fill: $curious-blue;
    }
  }
  .link-actions {
    text-align: right;
    svg {
      background: #f5d15d;
      box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
      path {
        fill: $curious-blue;
      }
      &:hover {
        box-shadow: none;
      }
    }
    .save {
      svg {
        path {
          fill: #06D9B1;
        }
      }
    }
  }
  .ttp-datatable__row:hover {
    cursor: pointer;
    .edit {
      display: inline-block;
    }
  }
}

.sms-send{
  div > .confirm-sent_button{
    margin-top: 2rem;
  }
  .summary__content-item{
    padding: .6rem .5rem;
  }
}

.summary {
  align-items: center;
  padding: 0rem 2rem;
  background: #F3FAFF;
  border-radius: 4px;
  .email-field svg {
    right: auto;
    margin-left: 4px;
  }
  li {
    display: flex;
    padding: .7rem 0;
    border-bottom: 0.5px solid #F1F2F4;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    div:first-child {
      color: #6D7F92;
      text-align: right;
      width: 45%;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    div:nth-child(2) {
      color: #3C4E64;
      margin-left: .7rem;
      i {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
