$primary-color: #55585c;
$ball-size: 15px ;
$margin: 2px ;
$line-height: 35px ;
$line-width: 4px ;

@mixin global-bg() {
  background-color: $primary-color;
}

@mixin global-animation() {
  animation-fill-mode: both;
}

@mixin balls() {
  @include global-bg();

  width: $ball-size;
  height: $ball-size;
  border-radius: 100%;
  margin: $margin;
}

@mixin lines() {
  @include global-bg();

  width: $line-width;
  height: $line-height;
  border-radius: 2px;
  margin: $margin;
}

@keyframes ball-grid-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@mixin ball-grid-pulse($n:9) {
  @for $i from 1 through $n {
    > div:nth-child(#{$i}) {
      animation-delay: ((random(100) / 100) - 0.2) + s;
      animation-duration: ((random(100) / 100) + 0.6) + s;
    }
  }

}

.ball-grid-pulse {
  @include ball-grid-pulse();
  width: ($ball-size * 3) + $margin * 6;

  > div {
    @include balls();
    @include global-animation();

    display: inline-block;
    float: left;
    animation-name: ball-grid-pulse;
    animation-iteration-count: infinite;
    animation-delay: 0;
  }
}
@keyframes ball-beat {
  50% {
    opacity: 0.2;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.ball-beat {

  > div {
    @include balls();
    @include global-animation();

    display: inline-block;
    animation: ball-beat 0.7s 0s infinite linear;

    &:nth-child(2n-1) {
      animation-delay: -0.35s !important;
    }
  }
}

@keyframes line-spin-fade-loader {
  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.line-spin-fade-loader {
  position: relative;
  top: -10px;
  left: -4px;

  >div {
    background-color: #222;
    width: 4px;
    height: 35px;
    border-radius: 2px;
    margin: 2px;
    animation-fill-mode: both;
    position: absolute;
    width: 5px;
    height: 15px;

    &:nth-child(1) {
      top: 20px;
      left: 0;
      animation: line-spin-fade-loader 1.2s -0.84s infinite ease-in-out;
    }

    &:nth-child(2) {
      top: 13.63636px;
      left: 13.63636px;
      transform: rotate(-45deg);
      animation: line-spin-fade-loader 1.2s -0.72s infinite ease-in-out;
    }

    &:nth-child(3) {
      top: 0;
      left: 20px;
      transform: rotate(90deg);
      animation: line-spin-fade-loader 1.2s -0.6s infinite ease-in-out;
    }

    &:nth-child(4) {
      top: -13.63636px;
      left: 13.63636px;
      transform: rotate(45deg);
      animation: line-spin-fade-loader 1.2s -0.48s infinite ease-in-out;
    }

    &:nth-child(5) {
      top: -20px;
      left: 0;
      animation: line-spin-fade-loader 1.2s -0.36s infinite ease-in-out;
    }

    &:nth-child(6) {
      top: -13.63636px;
      left: -13.63636px;;
      transform: rotate(-45deg);
      animation: line-spin-fade-loader 1.2s -0.24s infinite ease-in-out;
    }

    &:nth-child(7) {
      top: 0;
      left: -20px;
      transform: rotate(90deg);
      animation: line-spin-fade-loader 1.2s -0.12s infinite ease-in-out;
    }

    &:nth-child(8) {
      top: 13.63636px;
      left: -13.63636px;
      transform: rotate(45deg);
      animation: line-spin-fade-loader 1.2s 0s infinite ease-in-out;
    }
  }
}