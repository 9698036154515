.viewer {
  height: 100%;
  max-width: unset;

  &__close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #F1F2F4;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;

    img {
      position: absolute;
      width: 36px;
      height: 14px;
      top: 10px;
      left: 0;
    }
  }

  &__container {
    padding: 20px 15px;

    &__goto {
      position: relative;
      margin-bottom: 1rem;

      input {
        margin: 0;
        border: none;
        background-color: #F8F9FA;
        box-shadow: none;
        border-radius: 2rem;
        padding: 0 3rem 0 2rem;
        font-size: .8rem;
        font-weight: 500;
        color: #6D7F92;
        height: 2.25rem;
        width: 100%;
        outline: none;
      }

      a {
        position: absolute;
        right: 0;
        top: 0;
        width: 2.25rem;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 50%;
        font-size: .9rem;
        border: 1px solid #F1F2F4;
        color: #3C4E64;
        font-weight: bold;
      }
    }

    &__content {
      height: calc(100% - 46px);

      iframe {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: none;
      }
    }
  }

  &__aside {
    background: #F8F9FA;
    border-radius: 0px 5px 5px 0px;
    height: 100%;
    overflow: scroll;

    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 1;
      color: #3C4E64;
      margin-bottom: 4px;
      text-transform: uppercase;
    }

    &__subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      color: #6D7F92;
      margin-bottom: 4px;
    }

    &__text {
      font-weight: normal;
      font-size: 14px;
      line-height: 1;
      color: #3C4E64;
      margin-bottom: 4px;
    }

    &__container {
      background: #F1F2F4;
      margin: 0;
      padding: 10px 0;
    }

    &__block {
      background: #FFFFFF;
      border-radius: 5px;
      margin: 5px 15px;
      padding: 12px;
    }
  }

  #theme-preview {
    padding: 21rem 0;
    background: unset;
    position: unset;

    .container-smartphone {
      position: unset;

      .phone-text {
        position: absolute;
        top: 25%;
        left: 46%;
        transform: translate(-50%, -50%);
        font-weight: bold;
        height: 5rem;
        width: 75%;
        padding: 0 2.5rem 0 4.5rem;
      }
    }
  }
}
