#home-page,
.home-page {
  background-color: #FAFBFB;
  flex: 1 1 auto;

  .sending-header {
    position: relative;
    min-height: 550px;
    padding-bottom: 4rem;
    
    &__title {
      position: absolute;
      top: 40px;
      left: 80px;
      z-index: 1;
      color: #29394D;
      h3 {
        font-weight: 500;
        font-size: 30px;
        line-height: 1;
        margin: 0;
      }
      h4 {
        font-size: 20px;
        span {
          font-weight: 300;
          color: #18A0FB;
        }
      }
    }

    &__animation {
      z-index: -1;
    }

    video {
      position: absolute;
      width: 40%;
      height: auto;
      top: 50%;
      left: 50%;
      z-index: 1;
      border-radius: 5px;
      background-color: #F1F2F4;
      box-shadow: 1px 1px 2px 2px rgba(109, 127, 146, 0.1);
    }
  }
}
