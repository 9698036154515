@import "./form/create-form";
@import "./text_model";
@import "./item-header";
@import "./apply-button";
@import "./generic-emailing/setting-step";
@import "./generic-emailing/ge-viewer";
@import "./settings/ge-settings";

#generic-emailing-page {
  margin-bottom: 7rem;

  .ttp-tab-container {
    min-height: 90vh;
  }

  #cd-filter-horizontal-container .filter-wrapper {
    background-color: #FAFBFB;
  }

  #cd-filter-horizontal-container {
    margin-top: 2rem;
  }

  .toolbar {
    padding-right: 0;
  }
}

.generic-email-preview {
  height: 100%;

  h3 {
    color: #000;
    font-size: 1rem;
  }

  span {
    text-transform: capitalize;
    font-weight: 100;
  }

  iframe {
    border: 1px solid #18a0fb;
    width: 100%;
    height: 87%;
    border-radius: 4px;
  }
}

.generic-email-container {
  margin-bottom: 2rem;

  .generic-email-list {
    background: #FAFBFB;
    box-sizing: border-box;
    border-radius: 5px;
    transition: height 2s ease-in;
    height: 0;
    opacity: 0;
    display: none;
    margin: 1rem auto 0;

    &__open {
      height: auto;
      opacity: 1;
      display: flex;
      justify-content: center;
    }
    &__item {
      padding: 1rem;
      .generic-email {
        &__content {
          display: flex;
          overflow: hidden;
          border-radius: 4px;
          box-shadow: 0px 1px 3px rgba(41,57,77,.1);
        }
        &__preview-container {
          height: 304px;
        }

        &__preview {
          width: 233px;
          border: 3px solid #FFFFFF;
          box-sizing: border-box;
          box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
          border-radius: 4px;
          z-index: 1;

          &:hover {
            animation-name: bg-scroll-up-down;
            animation-duration: 8s;
            animation-direction: alternate;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
          }

          &.fetching {
            background: linear-gradient(to right, #F9F9F9, #EEE, #E5E5E5, #DEDEDE);
            background-size: 300% 100%;
            z-index: 2;
          }
        }

        &__title {
          margin: 0;
          font-family: $main-body-font;
          font-weight: 300;
          font-size: 14px;
          color: #6D7F92;

          & > span:not(:first-child) {
            margin-left: 8px;
            font-weight: 400;
            font-size: 14px;
            color: $pickled-bluewood;
          }
        }

        &__model {
          position: relative;
          height: 300px;
          flex: 1;
          &:hover {
            .text-models__status {
              top: 58px;
            }
          }

          .text-models {
            position: relative;
            overflow: hidden;
            padding: 0;
            z-index: 0;
            box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
            border-radius: 4px;
            height: 330px;
            width: 260px;
            margin: .5rem auto 2rem;
            &__status {
              background: rgba(255, 255, 255, 0.95);
              border-radius: 0px 0px 4px 4px;
              position: absolute;
              top: calc(100% - 7px);
              height: 100%;
              width: 100%;
              transition: all 300ms ease-in;
              display: flex;
              justify-content: center;
              .text-actions__action {
                width: 40px;
                height: 40px;
                background: #18A0FB;
                box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 80px;
                border-radius: 50%;
                cursor: pointer;
                position: relative;
                z-index: 0;
                margin-right: 30px;
                left: 18px;
                &.delete-action {
                  background-color: #FF5656;
                  &::after {
                    background-color: #FE3745;
                  }
                }
              }
            }
          }

          .header {
            padding: 1rem;
            padding-right: 0;
            display: flex;

            & > :first-child {
              flex: 1;
            }

            &__actions {
              .drop-down-option {
                background: #fff;
                border-radius: 4px;
                flex-direction: column;
                vertical-align: middle;
                margin: 0;
                width: 28px;
                height: 28px;
                -webkit-justify-content: center;
                cursor: pointer;
                display: flex;

                .drop-down-option__left {
                  width: auto;
                  right: 1px;
                  top: 28px;
                  li {
                    padding-left: 3rem;
                    padding-right: 3rem;
                  }
                }

                .icon-options {
                  font-size: 16px;
                  color: #4c4c4c;
                  display: flex;
                  -moz-box-pack: center;
                  justify-content: center;
                }
              }
            }
            &__main {
              display: flex;
              padding-right: 1rem;
              span {
                font-weight: 500;
                font-size: 12px;
                margin: 0 .4rem;
                &.main-color {
                  color: $dodger-blue;
                }
              }
            }
          }

          &__title {
            display: flex;
            justify-content: space-between;
            padding: 1rem 1rem 0;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: $pickled-bluewood;
            bottom: 0;
            background: #fff;
            width: 100%;
            position: absolute;
            opacity: .9;
            .language img {
              width: 20px;
            }

            .title > span:first-child {
              margin-right: 8px;
            }

            button {
              padding: 0 1rem;
              padding-bottom: .5rem;
              margin-right: 1rem;
              font-size: 12px;
              position: relative;
              cursor: pointer;
              outline: none;

              i {
                margin-right: .5rem;
              }

              &:after {
                content: "";
                display: block;
                margin: auto;
                height: 1px;
                position: absolute;
                bottom: 0;
                right: auto;
                left: 0;
                width: 100%;
                background: currentColor;
                transition: width .3s linear 0s, right 0s linear .3s, left 0s linear .3s;
              }

              &:hover {
                font-weight: 600;

                &:after {
                  width: 0;
                  right: 0;
                  left: auto;
                }
              }
            }
          }

          &__content {
            background: #FFF;
            height: 100%;

            &.fetching {
              flex: 1;
              height: 100%;
              box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
              border-radius: 2px 2px 2px 0px;
            }

            img {
              margin-right: 8px;
            }
          }

          &__actions {
            position: absolute;
            bottom: 0;
            background: #FFF;
            width: 100%;
            padding: 1rem;
            background: linear-gradient(to top, #ffffff, #ffffff, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.5));
            display: flex;

            button {
              padding: 0 1rem;
              padding-bottom: .5rem;
              margin-right: 1rem;
              font-size: 12px;
              position: relative;
              cursor: pointer;
              outline: none;
              height: 36px;
              display: flex;
              align-items: center;

              img {
                margin-right: .5rem;
              }

              &::after {
                content: '';
                display: block;
                margin: auto;
                height: 1px;
                position: absolute;
                bottom: 0;
                right: auto;
                left: 0;
                width: 100%;
                background: currentColor;
                transition: width 0.3s linear 0s, right 0s linear 0.3s, left 0s linear 0.3s;
              }

              &:not(:disabled):hover {
                font-weight: 600;

                &::after {
                  width: 0;
                  right: 0;
                  left: auto;
                }
              }
            }
          }
        }

        &__text {
          text-overflow: ellipsis;
          background: #FFF;
          padding: 1rem;
          font-size: 12px;

          p {
            margin: initial;

            br {
              margin-bottom: 10px;
            }
          }
        }

        &__detail {
          width: 100%;
          padding: .5rem 0 .5rem .5rem;
          display: flex;
          justify-content: space-between;
          flex-direction: row-reverse;

          h6 {
            font-size: .9rem;
            margin: 0;
          }

          .icomoon, .icon {
            font-size: .8rem;
            margin-right: .5rem;
            font-weight: 700;
          }

          .language {
            font-size: 1.3rem;

            img {
              width: 35px;
              padding-left: .2rem;
            }
          }
        }

        &__actions {
          display: flex;

          button {
            padding: .5rem 1.5rem;
            margin: 0 0 0 .6rem;
            text-transform: uppercase;
            color: #4198D7;
            cursor: pointer;
            height: 36px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
            border-radius: 40px;
            outline: none;
            display: flex;
            align-items: center;

            &:hover {
              box-shadow: none;
            }

            img {
              margin-right: 0.5rem;
            }

            &:hover {
              color: #0094ff;
            }
          }
        }
      }
    }
  }

  &.text-model-container {
    background: transparent;
    border: none;
  }

  .generic-email-header {
    background: #F1F2F4;
    border-radius: 4px 4px 0 0;
    align-content: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    height: 50px;
    -moz-box-pack: justify;
    justify-content: space-between;
    padding: 0 2rem;
    z-index: 1;

    &__title {
      display: flex;
      margin-top: 6px;

      h4 {
        font-size: 20px;
        color: #29394D;
        margin: auto .6rem;
      }

      img {
        vertical-align: unset;
      }

      svg {
        margin-top: 6px;
      }
    }

    &__right-side {
      display: flex;

      .languages {
        display: flex;
        margin-right: 1rem;
        align-items: center;

        img {
          margin-left: 5px;
          border: 5px solid transparent;
          border-top-width: 0;
          border-bottom: 0;
          height: 22px;
          background: #F8F9FA;
          border-radius: 2px;
        }
      }

      .icon {
        display: flex;
        cursor: pointer;
        background: #fff;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        color: #29394D;
        font-weight: 700;
        font-size: 1rem;

        i {
          margin: auto;
        }
      }
    }
  }
  .text-form-enter {
    opacity: 0;
    transform: scale(0.5);
  }
  .text-form-enter-active {
    opacity: 1;
    transform: scale(1.05);
    transition: all 400ms;
  }
  .text-form-exit {
    opacity: 1;
  }
  .text-form-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: all 400ms;
  }

  .text-form {
    padding: 2rem;
    background: #fff;
    &__actions {
      display: flex;
      justify-content: right;
      margin-top: 1.5rem;
    }
  }
}

.generic-email-list {
  .empty-block {
    flex-direction: column;
    align-items: center;
    height: 12rem;
    display: flex;
    background: #FFF;
    border-radius: 2px;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.04);
    justify-content: center;
    color: #4198D7;
    cursor: pointer;
    .icon-wrapper {
      font-size: 1.3rem;
      background: #FFFFFF;
      border: 0.5px solid #F1F2F4;
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
      border-radius: 50%;
      padding: .8rem;
      display: flex;
      i {
        margin: auto;
      }
    }
    .message {
      span {
        margin-right: 1rem;
      }
      margin-top: 15px;
      font-size: 12px;
      color: #18A0FB;
    }
    img {
      width: 1.4rem;
    }
    &:hover {
      transform: scale(1.02);
      .icon-wrapper {
      box-shadow: none;
      }
    }
  }
  .blank-text {
    height: 344px;
    background: #f8f9fa;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: none;
  }
}
.email-list-step {
  .notes-container {
    display: flex;
    flex-wrap: wrap;
    color: $pickled-bluewood;

    .notes {
      margin: .5rem;
      border-radius: 4px;
      width: 275px;
      justify-content: flex-start;
      padding: .5rem 1rem;

      &__warning-item {
        font-size: 12px;
        display: flex;

        &__body {
          padding-left: 10px;
          flex-direction: column;
          display: flex;

          span {
            float: left;
            font-weight: 500;
            font-size: 14px;
          }
        }

        &__languages {
          float: left;
        }

        &__language-item {
          justify-content: center;
          cursor: pointer;
          background: #fff;
          border: .5px solid rgba(109, 127, 146, .5);
          box-sizing: border-box;
          box-shadow: 0 0 5px rgba(41, 57, 77, .1);
          border-radius: 4px;
          padding: .3rem 0.5rem;
          margin: 9px 5px 0 0;
          display: flex;
          font-size: 12px;
          align-items: center;
          float: left;

          .language-icon {
            display: flex;
          }

          .language-title {
            text-transform: capitalize;
          }
        }
      }
    }
  }

  svg {
    fill: $tango;
  }

  .alert {
    background: rgba(255, 99, 110, 0.06);
    svg {
      fill: $coral-red;
    }
  }
}
