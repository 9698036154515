.bestLinksClicked {
  background: #ffffff;
  border: 1px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5% 4%;
  line-height: 14px;
  font-size: 14px;
  color: #29394d;
  align-items: center;
  h2 {
    font-size: 18px;
    font-weight: 500;
  }
  .links {
    padding: 8% 0%;
    .link {
      padding: 8% 0%;
      border-bottom: 1px solid #f1f2f4;
      .diffTime {
        display: flex;
        margin: 0% 0% 3% 0%;
        label {
          margin: 0% 2%;
        }
      }
      h3 {
        margin: 1%;
        font-size: 14px;
        font-weight: 500;
      }
      span {
        color: #6d7f92;
      }
    }
  }
}