.image-editor {
  &__preview {
    width: 100%;
    height: 78vh;
    text-align: center;
    padding: 0 0 0 1rem;
    overflow: scroll;

    img {
      max-height: 100%;
      width: auto;
      border: 2px dashed #B2BCC6;
    }
  }

  &__form {
    padding: 0 1rem;

    & > div {
      margin-bottom: 1rem;
    }

    .ttp-select {
      text-transform: uppercase;
    }

    .row {
      justify-content: space-between;
      padding: 1rem;

      .btn {
        min-width: 45%;
        border-radius: 2px;
        margin: 0;
      }
    }
  }
}
