@import "./approval-response";

.approval-page,
.approval-feedback {
  min-height: 100vh;
  height: 100vh;
  overflow: scroll;

  h3 {
    font-size: 1rem;
    margin: 0;
    color: $gray-dark;
  }

  h5 {
    color: $main-blue;
    color: $dodger-blue;
    font-size: .8rem;
    margin: .2rem 0 .3rem;
  }

  .header {
    position: relative;

    .approver-header {
      position: absolute;
      bottom: 0;
    }
  }

  input {
    margin: 0;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  .icon-plus {
    font-size: 1rem;
    font-weight: 600;
    padding-left: .4rem;
    cursor: pointer;
  }

  .setting_item {
    background-color: #F3FAFF;
    width: 100%;
    height: 90%;
    padding: 5px 0px 10px 15px;
    border-radius: 13px;

    .setting-param {
      display: flex;
      justify-content: space-between;
      color: $main-color;
      font-weight: 500;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      padding-right: 1.5rem;
      margin-bottom: 1.2rem;
      padding-left: 0.9rem;

      &__detail {
        margin-bottom: 0;
        font-weight: normal;
      }

      .switch-container {
        margin-bottom: 1.5%;
      }

      input {
        width: 10%;
      }
    }
  }

  .approver-user {
    display: flex;
    align-items: center;
    padding: 0;
    border: 1px solid #efefef;
    position: relative;

    &__plus {
      line-height: normal;
      padding: .5rem;

      .icon {
        padding-left: 0;
      }
    }

    .react-autosuggest__container {
      width: 100%;
    }

    input {
      border: none;
      padding-left: 0;
    }

    .loading-suggestion {
      position: absolute;
      right: 0;
      left: 0;
      box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1), 0 0 0 1px rgba(44, 43, 63, 0.13);
      background: #fff;
    }
  }

  .react-autosuggest__suggestions-container--open {
    position: absolute;
    left: 0;
    right: 0;
    padding: 0;
    min-height: 37px;

    .react-autosuggest__suggestions-list .react-autosuggest__suggestion {
      padding: 0;
    }
  }

  .react-autosuggest__container {
    position: static;
    border: none;
    border-bottom: 1px solid $main-light-gray;
  }

  .approver {
    justify-content: flex-start;
    height: auto;
    margin: 1rem 0;
    text-align: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    h5 {
      text-align: center;
    }

    .empty-avatar span {
      font-size: 2.5rem;
    }

    &-avatar {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      overflow: inherit;
      opacity: 0.9;
      border-radius: 50%;
      background-size: cover;
      background-position: 50%;
      width: 5rem;
      height: 5rem;
      margin-bottom: 1rem;
      border: 2px solid #fff;
      box-shadow: 0 3px 12px 1px rgba(44, 43, 63, .1), 0 0 0 1px rgba(44, 43, 63, .13);

      &.selected,
      &:hover {
        opacity: 1;
      }

      &:before {
        border: .2rem solid transparent;
      }

      &:after {
        border: 0 solid transparent;
      }

      .delete-approver {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        z-index: 0;

        .icon-trash {
          position: absolute;
          bottom: 0;
          color: #fff;
          background-color: black;
          left: 50%;
          opacity: .5;
          -webkit-transform: translateX(-50%) translateY(100%);
          -moz-transform: translateX(-50%) translateY(100%);
          -o-transform: translateX(-50%) translateY(100%);
          transform: translateX(-50%) translateY(100%);
          display: inline-block;
          width: 76px;
          padding: 0.6rem 0.5rem;
          font-size: 1.5rem;
          font-weight: 100;
          border-radius: 50%;
          cursor: pointer;
          transition: nth($transitions, 3);
          height: 5rem;
          top: 0;
        }
      }

      &:hover {
        .icon-trash {
          -webkit-transform: translateX(-50%) translateY(0);
          -moz-transform: translateX(-50%) translateY(0);
          -o-transform: translateX(-50%) translateY(0);
          transform: translateX(-50%) translateY(0);
          transition: nth($transitions, 3);
        }
      }
    }
  }

  .icon-bubbles {
    margin-right: .5rem;
    color: #2095F0;
  }

  .approve-block {
    margin-top: 3rem;
  }

  .settings-tab {
    h3 {
      margin-bottom: 1.5rem;
    }

    button {
      margin: 3rem 1rem;
    }
  }

  .team-list {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    margin-bottom: 3rem;
    flex-wrap: nowrap;

    &-item {
      cursor: pointer;
      margin: .3rem;

      &__name {
        font-size: 1.2rem;
        color: #29394D;
      }

      &__details {
        color: var(--dark-1, #29394D);
        text-align: right;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;

        span {
          font-weight: 600;
        }
      }

      &__default-team {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;

        &-label {
          color: #6D7F92;
          font-family: "Roboto";
          font-size: 12px;
          font-weight: 500;

          &.selected {
            color: #18A0FB;
          }
        }

        input[type="radio"] {
          cursor: pointer;
          width: 18px;
          padding: 9px;
        }
      }

      .param-label {
        text-indent: 0;
      }

      .team-header {
        padding: 1rem;
        cursor: pointer;
        border-radius: 8px;
        background: #FFF;
        border: 1px solid #F1F2F4;
        box-sizing: border-box;
        transition: .3s all ease-in-out;
        border: 1px solid var(--gris-3, #F1F2F4);

        .icon {
          padding-right: 0.2rem;
          font-size: .6rem;
        }

        &--members {
          font-size: .7rem;
          padding: 0 .5rem;
          display: flex;
          border-left: .1rem solid #ededed;
          justify-content: center;

          &-avatars {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;

            .small-avatar {
              background-size: cover;
              vertical-align: middle;
              width: 40px;
              height: 40px;
              flex-shrink: 0;
              overflow: hidden;
              border-radius: 50%;
              border: 2px solid white;
              margin-left: -8px;
              cursor: default;
              background-color: gray;

              &:hover {
                z-index: 1;
                position: relative;
              }
            }

            .empty-avatar span {
              font-size: 1rem;
            }
          }

          .icon {
            font-size: 1.3rem;
          }
        }

        &:hover {
          box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.10);
        }

        &.delete {
          background: #FFF7F8;

          &:hover {
            border: 1px solid #FE3745;
          }
        }

        &.selected {
          border-color: #18A0FB;
          background-color: #F3FAFF;
        }

        .icon-pencil {
          display: none;
          font-size: .8rem;
        }

        &:hover .icon-pencil {
          display: inline-block;
        }
      }

      .label_delete {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #FE3745;
        text-transform: uppercase;
        cursor: pointer;
        margin: 10px 0 10px 10px;

        svg {
          margin-right: 13px;
        }
      }

      .team-setting {
        height: 0;
        overflow: hidden;
        padding: 0rem 1rem;
        background-color: #fbfbfb;
        transition: .3s all ease-in-out;

        h6 {
          text-align: center;
        }

        .setting-param {
          display: inline-flex;

          .ttp-select {
            width: 20%;

            &.empty-box {
              border: unset;

              .ttp-select__control {
                background: white;
                border-color: rgb(179, 179, 179);
              }
            }
          }
        }
      }

      .team-avatar {
        display: inline-block;
        color: #fff;
        text-align: center;
        margin: 10px 1px 10px 1px;
        width: 44px;
        height: 44px;
        font-size: 1.8rem;
        line-height: 2.8rem;
        //overflow: hidden;
        text-transform: uppercase;
        border-radius: 50%;
        font-weight: bold;
      }

      p {
        color: #505050;
        margin: 0;
      }

      &__action span {
        text-transform: uppercase;
        padding: .5rem .8rem 0 0;
        font-size: .7rem;
        font-weight: normal;

        &:hover {
          color: #505050;
          letter-spacing: .03rem;
        }
      }
    }

    &-item--open {
      .team-setting {
        padding: 4rem 1rem;
        height: auto;
        overflow: auto;
        background-color: #fff;
      }
    }

    input {
      height: 2rem;
      font-size: .8rem;
    }
  }
}