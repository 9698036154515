.ttp-datatable{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  color: $pickled-bluewood;
  div {
    margin-bottom: .1rem;
    justify-content: center;
  }
  &__header{
    text-transform: capitalize;
    background: #F1F2F4;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    padding: 1rem;
  }
  &__row{
    line-height: 1.5rem;
    font-size: .9rem;
    padding: 1rem;
    border-radius: 2px;
    &:hover {
      background: #F1F2F4;
    }
    .gradiant {
      min-height: 24px;
    }

    .tooltip-bounce {
      position: absolute;
    }

    .valid-email {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0;

      button {
        border-radius: 5px;
        min-width: 64px;
        height: 34px;
        font-size: 14px;
        text-transform: capitalize;
        margin: -.3rem 0 0 1rem;
      }

      .ttp-page-size {
        width: auto;
      }
    }
  }
  &__row:nth-child(2n) {
    background: #FAFBFB;
  }
  &__row:nth-child(2n+1) {
    background: #F8F9FA;
  }
}
