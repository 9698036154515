$header-height: 65px;
.create-campaign {
  &__header {
    width: 100%;
    height: $header-height;
    background: #29394D;
    display: flex;
    position: relative;

    .type-info {
      display: flex;
      flex: 2 1;
      padding: 0 0 0 23px;
      align-items: center;
      .icon {
        height: 44px;
        width: 44px;
        background: #F8F9FA;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        position: relative;
        margin-right: 11px;
        img {
          height: 26px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .title {
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        text-transform: uppercase;
      }
    }

    .theme-name {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 4;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #FFFFFF;
    }

    .type-steps {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      flex: 4;
      z-index: 0;
      &::after {
        content: "";
        height: 4px;
        background: #6D7F92;
        position: absolute;
        right: 10px;
        left: 10px;
        top: 25px;
        z-index: 0;
      }
      .active-bar {
        width: 50%;
        position: absolute;
        height: 4px;
        background: #18a0fb;
        top: 25px;
        z-index: 1;
        transition: width .7s;
      }
      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;
        div {
          width: 20px;
          height: 20px;
          background: #F8F9FA;
          border: 3px solid #6D7F92;
          border-radius: 50%;
          margin-bottom: 12px;
        }
        span {
          font-size: 12px;
          text-align: center;
          color: #6D7F92;
          white-space: nowrap;
          position: absolute;
          top: 40px;
        }

        &.active {
          cursor: pointer;
          div {
            background: #18A0FB;
            border-color: white;
          }
          span {
            color: #F8F9FA;
          }
        }
      }
    }
    .type-actions {
      flex: 3 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 3.5rem;

      a {
        min-width: 120px;
        height: 38px;
        border: none;
        margin: 0 0 0 11px;
        font-size: 14px;
        white-space: nowrap;

        img {
          margin-right: 12px;
          margin-left: 0;
        }

        svg {
          margin-right: 8px;
          path {
            fill: #ffffff;
          }
        }
      }
    }
    .close {
      flex: 1;
      top: 26px;
    }
  }

  &__body {
    position: relative;
    height: calc(100vh - #{$header-height});
    overflow: scroll;
  }
}
