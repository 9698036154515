#subMenu,
.sub-menu {
  position: relative;
  background: $pickled-bluewood;
  z-index: 1;

  &:not(.vertical) {
    padding-left: 3rem;
    padding-right: 1rem;
    .menu__link {
      &-has-child {
        width: 13rem;
        ul {
          li {
            text-align: center;
          }
        }
      }
    }
  }

  .flip-menu {
    cursor: pointer;
    position: absolute;
    top: calc(100% - 30px/2);
    left: 1rem;
    width: 30px;
    height: 30px;
    background: $pickled-bluewood;
    color: #fff;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    transition: transform .2s, background-color .2s;
    i {
      margin: auto;
    }

    &:hover {
      transform: rotateZ(-90deg);
    }
  }

  .menu {
    li {
      align-items: center;
      color: $french-pass;
      font-family: $main-body-font;
      font-weight: normal;
      height: 44px;

      .menu__link {
        color: $french-pass;
        display: flex;
        align-items: center;
        height: 100%;
        .title {
          padding-left: .5rem;
          text-transform: uppercase;
        }

        &:hover, &.active {
          background: $fiord;
          ul {
            display: block;
          }
        }
        &-has-child {
          position: relative;
          flex-direction: column;
          .icon-arrow-down {
            display: inline-block;
          }
          .icon-arrow-up {
            display: none;
          }
          &:hover {
            .icon-arrow-up {
              display: inline-block;
            }
            .icon-arrow-down {
              display: none;
            }
          }
          & > div {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            padding-left: .5rem;
          }
          ul {
            position: absolute;
            background: $pickled-bluewood;
            display: none;
            top: 44px;
            left: 0;
            right: 0;
            margin: 0;
            z-index: 1;
            li:hover {
              background: $fiord;
            }
            a {
              color: $french-pass;
            }
          }
        }
      }

      .icomoon {
        font-size: 1rem;
      }

      a {
        padding: 0.75rem 1rem;
      }

      &.spliter {
        flex: 1;
        position: relative;
        width: 0px;
        height: 44px;
        &::after {
          content: " ";
          position: absolute;
          top: 0;
          right: 50%;
          width: 2px;
          height: 100%;
          background: rgba(109, 127, 146, 0.2);
        }
      }
    }
  }

  &.vertical {
    padding-top: $spacing-m;
    width: 4rem;
    justify-content: center;
    display: flex;
    flex-shrink: 0;
    transition: width .5s cubic-bezier(0, 0, 0.2, 1);
    position: absolute;
    left: 0;
    top: 64px;
    bottom: 0;
    z-index: 1;

    .flip-menu {
      visibility: hidden;
    }

    .menu {
      flex-direction: column;
      width: 100%;
      overflow: hidden;
      min-height: 100%;

      li {
        margin: $spacing-xxs 0;
        min-height: 2rem;
        width: 100%;
        .menu__link {
          border-left: 3px solid transparent;

          .title {
            opacity: 0;
            visibility: hidden;
            display: none;
            white-space: nowrap;
            width: 0;
          }

          &:hover, &.active {
            border-left: 3px solid $dodger-blue;
            background: $fiord;
          }
        }

        .icomoon {
          font-size: 1.22rem;
        }

        &.spliter {
          height: 0;
          min-height: 0;
          flex: unset;
          &::after {
            left: 0;
            top: 50%;
            height: 2px;
            width: 100%;
          }
        }
      }
    }

    &:hover {
      width: 17rem;
      justify-content: flex-start;

      .flip-menu {
        visibility: visible;
        left: calc(100% - 30px/2);
        top: .6rem;
        transform: rotateZ(-90deg);
        transition: transform .6s ease-out;
        &:hover {
          transform: rotateZ(-180deg);
          transition: transform .2s;
        }
      }

      .menu li .menu__link .title {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 4;
  }
}
