.template {
    padding: 2rem 0;
    &-item {
        margin-bottom: 1.5rem;
        &__title {
            text-align: center;
            font-weight: 600;
            color: black;
        }

        &__box {
            position: relative;
            background-color: gainsboro;
            margin: 0;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background-size: cover;
            height: 25rem;
            background-repeat: no-repeat;
            background-size: 100% auto;
            cursor: pointer;

            &:hover {
                animation-name: bg-scroll-up-down;
                animation-duration: 10s;
                animation-direction: alternate;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
            }
            .warning-box {
                padding: .2rem .3rem;
                background-color: orange;
                color: white;
                border-radius: 1rem;
                font-size: .6rem;
                text-transform: uppercase;
                font-weight: 700;
            }
        }
    }
}
