#e-box-preview {
  .e-box-preview {
    overflow-y: auto;
  }
  .loader-hidden {
    display: none;
  }
  .loader-active {
    display: block;
  }

  .e-box-preview-iframe-container {
    text-align: center;
    .e-box-preview-iframe {
      margin: 1rem auto;
      border: none;
    }
  }

  .mail-data {
    font-size: .9rem;
    cursor: unset !important;
    text-transform: capitalize !important;
    width: 25rem !important;
    border-bottom: unset;
    min-height: 3.7rem;
    height: unset !important;
    display: block !important;
    span {
      padding-left: 20px;
    }
    &:first-child {
      .email-span {
        padding-left: 5px;
      }
    }
    &:last-child {
      display: flex !important;
      span {
        padding-bottom: 1.4rem;
      }
    }
    .email-span {
      text-transform: none;
    }
  }
  .preview-button {
    padding-left: 2rem;
    padding-top: 2rem;
    button{
      color: #cbcbcb;
      border: 1px solid #cbcbcb;
      background-color: transparent;
      text-transform: uppercase;
      border-radius: 4px;
      &:hover{
        color: white !important;
        border: 1px solid white;
      }
    }
  }
  .resend-button {
    color: #fcb316 !important;
    border: 1px solid #fcb316 !important;
    margin-left: 1rem;
    &:hover {
      border: 1px solid #fcb316 !important;
      background-color: #fcb316;
    }
    .icon {
      padding-right: .3rem;
    }
  }
  .attachment-list {
    border: none !important;
    text-transform: capitalize !important;
    height: unset !important;
    padding-left: 0 !important;
    margin-left: -2rem;
    cursor: unset !important;
    span {
      padding: 0 !important;
    }
  }
  .div-loader {
    padding-top: 20rem;
  }
  #style-tools-menu-logo {
    padding-right: 3rem;
    justify-content: center;
    background: $pickled-bluewood;
    color: #FFF;
  }
  .gallery-header {
    border-bottom: 1px solid #555;
    margin-bottom: 1rem;
    margin-left: -.5rem;
  }
  h4 {
    margin-top: -1.5rem;
    color: white;
    margin-left: 3rem;
    text-align: start;
    text-transform: none;
  }
  h6 {
    margin-top: 1rem;
  }
  .attachment-img-container {
    margin: 0.3rem .3rem 1rem 0;
    overflow: hidden;
    position: relative;
    transition: 0.3s all ease-in-out;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    border-radius: 1rem;
    box-shadow: 0 0px 3px #777;
    span {
      position: absolute;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      z-index: -1;
      top: 0;
      bottom: 0;
      opacity: 0;
      right: 0;
      left: 0;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 2rem;
      background-color: rgba(80, 129, 190, 0.6);
      color: #fff;
      transition: .3s all ease-in-out;
      i {
        color: #fff;
        border-radius: 50%;
        transition: 0.3s all ease-in-out;
        cursor: pointer;
        padding: .5rem;
      }
    }
    img {
      border-radius: 1rem;
    }
    &:hover{
      span {
        z-index: 0;
        opacity: 1;
        i:hover {
          background-color: #5081be;
          box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
  .attachment-pdf-container {
    background-color: #454545;
    height: auto;
    width: 98%;
    border-radius: 1rem;
    box-shadow: 0 0px 3px #777;
    margin: 0.3rem .3rem 1rem 0;
    i {
      margin-left: .5rem;
    }
    h4 {
      margin-top: -1.3rem !important;
      margin-left: 2rem!important;
      font-size: 14px;
      color: #838383;
    }
    .gallery-header {
      margin-left: unset !important;
      margin-bottom: .5rem !important;
      padding-top: 0.5rem;
    }
    img {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      padding: 0 0 .3rem .3rem;
      height: 20rem;
      width: 98%;
    }
    .pdf-preview {
      overflow: hidden;
      position: relative;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      span {
        position: absolute;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        z-index: -1;
        top: 0;
        bottom: 0;
        opacity: 0;
        right: 0;
        left: 0;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 2rem;
        background-color: rgba(80, 129, 190, 0.6);
        color: #fff;
        transition: .3s all ease-in-out;
        i {
          color: #fff;
          border-radius: 50%;
          transition: 0.3s all ease-in-out;
          cursor: pointer;
          padding: .5rem;
        }
      }
      &:hover{
        span {
          z-index: 0;
          opacity: 1;
          i:hover {
            background-color: #5081be;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
  .attachment-doc-container {
    background-color: #454545;
    height: 5rem;
    border-radius: 1rem;
    box-shadow: 0 0 3px #777;
    margin: 0.3rem .3rem 1rem 0;
    i {
      margin-left: .5rem;
    }
    h4 {
      margin-top: -1.3rem !important;
      margin-left: 2rem!important;
      font-size: 14px;
      color: #838383;
    }
    .gallery-header {
      margin-left: unset !important;
      margin-bottom: .5rem !important;
      padding-top: 0.5rem;
    }
    a span {
      color: white;
      margin-left: 1rem;
      text-align: start;
      text-transform: none;
      font-size: 1rem;
    }
  }
  .attachments-ul {
    margin: 0 3rem 0 5rem !important;
    .attachment-container {
      max-width: 90%;
    }
  }
}
