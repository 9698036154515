.community_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
  & img {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin-top: -30px;
  }
  .avatar_empty {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin-top: -30px;
  }
  .community_name {
    margin-top: 20px;
    font-family: Roboto;
    padding-top: 1%;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #29394D;
  }

}

