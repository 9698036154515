.page-header {
    position: relative;
    height: 96px;
    text-transform: uppercase;
    background: #F3FAFF;
    border-radius: 0px 0px 10px 10px;
    margin: 0 20px;
    display: flex;
    margin-bottom: 1rem;
    z-index: 0;
    
    &__icon {
      display: flex;
      width: 9%;
      justify-content: flex-end;
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        display: block;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 60px;
        width: 1px;
        background-color: #FFFFFF;
        box-shadow: -4px 0px 10px rgba(41, 57, 77, 0.1);
      }
      img {
        width: 46px;
        padding-right: 12px;
        margin: 18px 0;
      }
    }

    &__info {
      width: 51%;
      display: flex;
      align-items: center;
      padding-left: 34px;

      * {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        margin: 0;
      }
    
      h3 {
        font-size: 24px;
        line-height: 28px;
        color: $pickled-bluewood;
      }

      p {
        font-size: 12px;
        line-height: 14px;
        color: #3C4E64;
      }
    }

    &__children {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 5%;
      text-transform: initial;
    }  
}