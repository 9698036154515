.widget_bottom-bar {
  display: flex;
  justify-content: space-between;
  padding: 6px 20px;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 4px 30px rgba(41, 57, 77, 0.3);
  background: #FFFFFF;
  border: 1px solid #D8DDE2;

  button {
    height: 44px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;

    &.primary, &.success {
      min-width: 144px;
    }

    &.save-btn:hover {
      background: #06D9B1;
      opacity: 0.6;
    }
  }

  .primary {
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    margin-left: 10px;
  }

  .btn-attach {
    display: flex;
    align-items: center;
    background: #F1F2F4;
    border: 1px dashed #B2BCC6;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #6D7F92;
    padding: 0 14px;
    border-radius: 5px;
    cursor: pointer;
    width: 44px;
    margin-right: 3rem;

    &:after {
      border-radius: 4px;
      content: "";
      position: absolute;
      background: #D8DDE2;
      width: 1px;
      height: 28px;
      left: 7.2rem;
    }
  }

  .btn-trash {
    width: 44px;
    background: #F1F2F4;
    margin: 0 3.3rem 0 1.5rem;

    svg {
      width: 18px;
      height: 18px;

      path {
        fill: #FE3745;;
      }
    }
  }

  .fs-btn {
    float: left;
    margin-left: 49rem;
  }

  .next-container {
    align-items: center;
    margin-left: 6rem;
    text-align: end;

    button {
      float: left;

      img {
        margin: 0;
        padding-left: 5%;
      }
    }

    span {
      color: #6D7F92;
      font-weight: normal;
      font-size: 12px;
    }

  }

  &_text-model {
    justify-content: end;
    display: grid;
  }

  &.text-model {
    display: grid;
    justify-content: end;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 31.1rem;
    box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    background: #FFFFFF;
    border: 1px solid $caribbean-green;
    box-sizing: border-box;
    width: 265px;
    right: 11.7rem;

    button {
      background: #FFFFFF;
      color: #29394D;
      box-shadow: none;
      border: none;

      &:hover {
        background: none;
      }

      &:first-child:after {
        content: "";
        position: absolute;
        bottom: 2.7rem;
        height: 1px;
        width: 240px;
        left: .7rem;
        background: #D8DDE2;
      }
    }
  }

  .show-dropdown {
    &:hover {
      .dropdown-content {
        display: block;
      }
    }
  }

  .check-box-container {
    margin: 0;

    &:after {
      border-radius: 4px;
      content: "";
      position: absolute;
      background: #D8DDE2;
      width: 1px;
      height: 28px;
      left: 13.2rem;
    }

    .check-box {
      width: 16px;
      height: 16px;
      background: #F1F2F4;
      border: 2px solid #B2BCC6;
      margin-right: .7em;

      &.checked {
        border-color: #18A0FB !important;
      }

      &:after {
        top: 4px;
        left: 0;
      }

      &:before {
        top: 10.4px;
        left: 7.9px;
        box-shadow: 0 0 0 1px #F1F2F4;
      }
    }
  }
}
