.drag-drop-editor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 100%;

  .dynamicFieldsCopy {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0 5px 0px;
    padding: 7px 7px 7px 7px;
    -webkit-appearance: none;
    border: 1px solid transparent;
    border-radius: 11px;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-size: 0.9rem;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    background-color: #1779ba;
    color: #fefefe;
  }

  .send-btn{
    position: relative;
    top: -54px;
    left: 190px;
    width: 70px;
    overflow: hidden;
    transition: width .3s;
    &:hover {
      width: 168px;
    }
    .btn.send {
      height: 42px;
      border-radius: 0px;
      text-transform: none;
      border: none;
      font-weight: normal;
      background: #293039;
      letter-spacing: 0.04em;
      white-space: nowrap;
      font-size: 12px;
      color: #A7AAAE;
      justify-content: unset;
      img {
        filter: contrast(0.6);
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(100, 100, 100, 0.3);
    z-index: 1;

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}

#theme-form {
  margin-bottom: 3rem;
  textarea {
    margin-bottom: 0;
  }
  textarea,
  input {
    box-shadow: none;
  }
  .subject-container {
    margin-top: 1rem;
  }
}

#send-test-form {
  .user-suggestion {
    border: 1px solid $dodger-blue;
    padding: 5px;
    border-radius: 3px;
    .react-tagsinput-tag {
      margin: 1px;
    }

    .react-tagsinput {
      padding: 2px;
    }

    a.react-tagsinput-remove {
      margin-left: 280px;
      top: -20px;
      position: relative;
    }
  }
}

#theme-preview_close {
  position: fixed;
  top: .8rem;
  z-index: 10;
  right: 1rem;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
}

#theme-preview {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background: $fiord;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;

  %basic-content {
    height: 600px;
    background: #ccf;
    position: absolute;
  }

  #frame {
    width: 100%;
    height: 100%;
    border-width: 0;
  }

  div {
    display: inline-block;
  }

  .container-desktop {
    position: fixed;
    left: -60px;
    top: 50%;
    transform: translateY(-50%);
    width: 1080px;
    height: 655px;;
    background-image: url('/img/theme/mac.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;

    .content {
      @extend %basic-content;
      height: 524px;
      width: 823px;
      top: 30px;
      left: 130px;
    }
  }


  .container-smartphone {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 400px;
    height: 600px;
    background-image: url('/img/theme/iphone.png');
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;

    .content {
      @extend %basic-content;
      height: 433px;
      width: 246px;
      top: 68px;
      left: 76px;

      #frame {
        transform: scale(.7, .7);
        transform-origin: 0 0;
        overflow: hidden;
        width: 144%;
        height: 144%;
      }
    }
  }
}
