#sms-credit-validation-page {
  .ttp-datatable {
    &__header {
      background-color: #f3f3f3;
    }
  }
  .padding-left {
    padding-left: 20px;
  }
  .icon-check {
    color: #149343 !important;
  }
  .icon-close{
    color: $error-color !important;
  }
  .button{
    padding: 7px;
    margin: 0;
  }
}
