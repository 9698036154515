.settings {
  &__newuser {
    border-top: 1px solid #ccc;

    &-link {
      padding: 2rem 0;
      font-size: 1.05rem;
      color: $curious-blue;
    }
  }

  &__filter-bar:after {
    content: "";
    width: 1280px;
    border: 1px solid $athens-gray;
  }

  .user {
    justify-content: flex-start;
    height: auto;
    margin: 0 -8.5rem 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .empty-avatar {
      &__initials {
        font-size: 2.5rem;
      }
    }

    h3 {
      font-weight: bold;
      font-size: 1rem;
      margin: 0;
      color: $pickled-bluewood;
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
      color: $curious-blue;
    }

    h4 {
      color: $slate-gray;
      font-weight: 300;
      font-size: .8rem;
      margin: .2rem 0 .3rem;
      text-align: center;

      &:after {
        content: "";
        width: 15rem;
        margin: .7rem 0 .2rem 0;
        border: 1px solid rgba(109, 127, 146, 0.1);
      }
    }

    &-avatar {
      position: relative;
      cursor: pointer;
      overflow: inherit;
      opacity: 0.9;
      border-radius: 50%;
      background-size: cover;
      background-position: 50%;
      width: 10rem;
      height: 10rem;
      margin-bottom: 1rem;
      text-align: center;
      box-shadow: 0 4px 4px rgba(41, 57, 77, 0.1);
      border: 3px solid #FFFFFF;

      &.selected, &:hover {
        opacity: 1;
      }

      &:before, &:after {
        content: '';
        width: 104%;
        height: 103%;
        position: absolute;
        top: -1%;
        left: -2%;
        border-radius: 100%;
        z-index: 0;
      }

      &:before {
        border: .2rem solid transparent;
      }

      &.selected:before {
        border-top-color: $curious-blue;
        border-right-color: $curious-blue;
        border-bottom-color: $curious-blue;

        transition: border-top-color 0.15s linear,
        border-right-color 0.15s linear 0.10s,
        border-bottom-color 0.15s linear 0.20s;
      }

      &:after {
        border: 0 solid transparent;
      }

      &.selected:after {
        border-top: .2rem solid $curious-blue;
        border-left-width: .2rem;
        border-right-width: .2rem;
        transform: rotate(269deg);
        transition: transform 0.4s linear 0s,
        border-left-width 0s linear 0.35s;
      }
    }

    &-check {
      position: absolute;
      top: 0;
      left: 1rem;
      font-size: 1.7rem;
      background-color: #fff;
      height: 1.9rem;
      width: 1.9rem;
      border-radius: 50%;
      color: #CCC;
      cursor: pointer;
      z-index: 1;

      svg {
        width: 20px;
        height: 28px;

        path {
          fill: $slate-gray;
        }
      }

      &.active {
        transition: nth($transitions, 2);
        color: $curious-blue;
        background-color: $curious-blue;

        path {
          fill: #FFFFFF;
        }
      }
    }

    &-header, h4 {
      position: relative;
      flex-direction: column;
      display: flex;
      align-items: center;
    }

    &-container {
      position: absolute;
      width: 270px;
      height: 193px;
      margin-top: 4.7rem;
      background: rgba(241, 242, 244, 0.5);
      border-radius: 20px;
    }
  }

  .bubbles {
    & > div {
      &:hover, &.active {
        width: 220px;
      }
    }
  }
}

#setting-users {
  padding: 2rem 0;
  max-width: inherit;

  .setting-user {
    text-align: center;
  }
}

#settings__sidebar {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 75%;
  right: 0;
  z-index: 3;
  transform: translateX(100%);
  transition: nth($transitions, 5);

  &.display {
    transform: translateX(0);
    box-shadow: 0 0 1.2rem 0 rgba(44, 43, 63, .3);
  }

  overflow-y: scroll;

  & .preferences-switch:last-child {
    padding-bottom: 7rem;
  }

  ul {
    margin: 0;
  }

  .sidebar__user {
    margin: 3rem 0 2rem;

    h3 {
      font-size: 1rem;
    }

    h4 {
      font-size: .8rem
    }
  }

  .switches, .switch.mandated, .switch.box, .preferences-switch {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-left: 1.5rem;

    li:last-child {
      border-bottom: none;
    }
  }

  .switch {
    display: flex;
    justify-content: space-between;
    color: $curious-blue;
    font-weight: 500;
    margin: 0;
    height: 3rem;
    align-items: center;
    padding-right: 1.5rem;
    border-bottom: 1px solid #EEE;
    letter-spacing: .02rem;

    &.mandated.tags {
      margin-bottom: 100px;
    }
  }

  .separator {
    padding: .6rem;
    background-color: #F5F5F5;
    color: #aaa;
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
  }

  .side__bar-group {
    margin: 2rem 0;
  }

  .side__bar-group-avatar {
    display: flex;
    justify-content: center;

    .user-avatar {
      margin-left: -2.5rem;
      opacity: 1;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .side__bar-group-names {
    padding: 1.5rem;
    font-weight: 400;
    text-align: center;
  }
}
