* {
  box-sizing: inherit;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

$color: #fff;

.band {
  position: absolute;
  top: 35px;
  right: .5rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 0 0 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.toggleButton {
  cursor: pointer;
  display: block;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  transition: transform .14s ease;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  transform: rotate(45deg);

  &:active {
    transform: rotateX(30deg);
  }

  input {
    display: none;

    &+div {
      border: 3px solid rgba($color, .2);
      border-radius: 50%;
      position: relative;
      width: 44px;
      height: 44px;

      svg {
        transform: rotate(-45deg);
        fill: none;
        stroke-width: 3.6;
        stroke: $color;
        stroke-linecap: round;
        stroke-linejoin: round;
        width: 44px;
        height: 44px;
        display: block;
        position: absolute;
        left: -3px;
        top: -3px;
        right: -3px;
        bottom: -3px;
        z-index: 1;
        stroke-dashoffset: 162.6 - 38;
        stroke-dasharray: 0 162.6 133 (162.6 - 133);
        transition: all .4s ease 0s;
      }

      &:before,
      &:after {
        content: '';
        width: 3px;
        height: 16px;
        background: $color;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 5px;
      }

      &:before {
        opacity: 0;
        transform: scale(.3) translate(-50%, -50%) rotate(45deg);
        animation: bounceInBefore .3s linear forwards .3s;
      }

      &:after {
        opacity: 0;
        transform: scale(.3) translate(-50%, -50%) rotate(-45deg);
        animation: bounceInAfter .3s linear forwards .3s;
      }
    }

    &:checked+div {
      svg {
        stroke-dashoffset: 162.6;
        stroke-dasharray: 0 162.6 28 (162.6 - 28);
        transition: all .4s ease .2s;
      }

      &:before {
        opacity: 0;
        transform: scale(.3) translate(-50%, -50%) rotate(45deg);
        animation: bounceInBeforeDont .3s linear forwards 0s;
      }

      &:after {
        opacity: 0;
        transform: scale(.3) translate(-50%, -50%) rotate(-45deg);
        animation: bounceInAfterDont .3s linear forwards 0s;
      }
    }
  }
}

@keyframes bounceInBefore {
  0% {
    opacity: 0;
    transform: scale(.3) translate(-50%, -50%) rotate(45deg);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(45deg);
  }

  80% {
    opacity: 1;
    transform: scale(.89) translate(-50%, -50%) rotate(45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes bounceInAfter {
  0% {
    opacity: 0;
    transform: scale(.3) translate(-50%, -50%) rotate(-45deg);
  }

  50% {
    opacity: 0.9;
    transform: scale(1.1) translate(-50%, -50%) rotate(-45deg);
  }

  80% {
    opacity: 1;
    transform: scale(.89) translate(-50%, -50%) rotate(-45deg);
  }

  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }
}

@keyframes bounceInBeforeDont {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(45deg);
  }

  100% {
    opacity: 0;
    transform: scale(.3) translate(-50%, -50%) rotate(45deg);
  }
}

@keyframes bounceInAfterDont {
  0% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%) rotate(-45deg);
  }

  100% {
    opacity: 0;
    transform: scale(.3) translate(-50%, -50%) rotate(-45deg);
  }
}

#text-model-form {
  padding: 4rem 0;
  margin: 0 auto;
  z-index: 1;

  &.form-open-form-url {
    padding-top: 0;
    .add-text-model {
      box-shadow: none;
    }
  }
}

.add-text-model {
  padding: 1rem 4rem 0;
  box-shadow: 1px 1px 50px 1px rgba(0,0,0,0.05);
  background-color: #fefefe;
  min-width: 680px;

  &__body {
    margin-bottom: 2rem;
    .flex-container {
      margin: 2rem 0;
      .check-box-container {
        margin: 0;
      }
    }
    h3 {
      font-size: 1.4rem;
      color: #3C4E64;
      text-align: center;
      padding-bottom: 1rem;
    }
    .save-button-container {
      float: right;
      margin-right: 8.6rem;
      a {
        margin-top: 3rem;
        padding: 1rem;
        i {
          padding-right: .3rem;
        }
      }
    }
  }
  &__footer {
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1rem;
    border-top: 1px solid #ddd;
    .controls__cancel {
      margin-right: 2rem;
    }
  }
}

.generic-email__model:hover {
  .apply-button {
    bottom: 0;
  }
}


.text-model-form {
  transition: 1s ease-in;
  &.form-open {
    transform: translateX(0);
  }
  &.form-close {
    transform: translateX(100%);
    height: 0;
  }
}

.text-model-list {
  transition: 1s ease-in;
  &.form-open {
    transform: translateX(-100%);
    height: 0;
  }
  &.form-close {
    transform: translateX(0);
  }
}
