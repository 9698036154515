.application{
  &__logo {
    width: 21px;
    font-size: 12px;
    line-height: 14px;
    margin-top: -5px;

  }
  &__name {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: 0.02em;
    color: #29394D;
    text-transform: uppercase;
  }

}
