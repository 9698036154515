.sh-progress {
  &-content {
    position: relative;
    width: 30rem;
  }
  &-status {
    background-color: #e5e5e5;
    height: 5px;
    &-value {
      background-color: #18a0fb;
      height: 5px;
      width: 0;
      position: relative;
      overflow: visible !important;
      margin-top: 2px;
      transition: all 0.8s ease 0s;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border: 2px solid #55b9e3;
        background-color: #fff;
        border-radius: 50%;
        top: -6px;
        right: -8px;
      }
    }
  }
  &-value1, &-value2 {
    text-transform: uppercase;
    font-size: .8rem;
    color: #fff;
  }
  &-value1 {
    margin-bottom: .3rem;
  }
  &-value2 {
    text-align: right;
    position: absolute;
    top: 50%;
    left: 102%;
  }
}