.se-dialog.sun-editor-common {
  position: absolute;

}

.sun-editor {
  z-index: 0;
  position: relative;

  .se-toolbar {
    background-color: #f8f9fa;
    padding: 6px 5px 0;
    font-size: 15px;
    margin-bottom: 5px;
    user-select: none;
  }

  button {
    color: #6d7f92;
    background-color: #f1f2f4;
  }

  .se-btn-tray {
    padding: 0;

    .se-btn-module-border {
      border: 0;

      ul {
        margin-left: 4px;

        li {
          margin: -1px;
        }
      }
    }

    .se-btn {
      font-size: 12px;
      line-height: 19px;
      background-color: #f1f2f4;
      border-radius: 2px;
      border: none;
      height: 26px;
      box-shadow: none;
      margin: 0;

      span {
        font-size: 12px;
        text-transform: uppercase;
        color: #6d7f92;
        font-family: Roboto;
        font-weight: normal;
      }

      &:hover {
        background: #3c4e64;
        box-shadow: none;

        span, i {
          color: white;
        }

        svg {
          fill: white;
        }
      }

      .custom-container {
        display: flex;
        align-items: center;

        i {
          margin-right: 4px;
        }
      }

      &.se-btn-custom {
        width: auto;
      }

      &.se-btn-hidden {
        visibility: hidden;
      }
    }
  }

  .se-select {
    label {
      padding-right: 5px;
    }

    select {
      appearance: auto;

      option {
        width: 100px;
      }
    }

    .example-div {
      padding-top: 5px;

      &.hide {
        visibility: hidden;
      }
    }

    .options-div {
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      span {
        font-size: 12px;
        color: #29394D;
      }

      & > div:first-of-type {
        margin: .5rem 0 1rem;

        label {
          color: $dodger-blue;
          font-weight: 500;
        }

        input {
          margin-bottom: 10px;
        }
      }

      button {
        background-color: transparent;
      }

      ._se_fs_add {
        border: 1px solid $dodger-blue;
        color: $dodger-blue;
      }

      ._se_fs_delete {
        border: 1px solid $persimmon;
        color: $persimmon;
      }
    }
    .options-scroll{
      &.scrolling-div {
        overflow-y : scroll;
        height: 210px;
      }
    }
  }

  .se-controller {
    .se-btn-group.d-i-g {
      display: inline-grid;
    }

    .content-text {
      padding: .5rem;
    }

    &.fill-fs-container {
      width: 400px;
      height: 220px;
      background: #F8F9FA;
      border: 1px solid #B2BCC6;
      box-sizing: border-box;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 5px;
      padding: 1rem;

      .content-text {
        font-weight: 500;
        font-size: 16px;
        color: #29394D;
      }

      .content-input {
        padding-top: .5rem;

        textarea {
          padding: .5rem;
        }

        .react-datepicker-wrapper input {
          margin-bottom: 1rem !important;
        }
      }

      button {
        border-radius: 5px;
        float: right;
      }
    }
  }

  .loading-dots {
    font-size: 12px;
    font-weight: 500;
    color: #6d7f92;
    min-width: 76px;
    display: inline-block;
    text-align: center;
  }

  .se-dialog{
    &-content{
      min-width: 420px;
    }
    &-inner {
      display: grid !important;
      align-items: center;
    }
  }
}

.se {
  &-tag {
    &-container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-item {
      font-size: 12px;
      margin: 2px 0 2px 0 !important;
      padding: 4px 12px !important;
      border-radius: 4px;
      color: #18a0fb;
      background: white;
      cursor: pointer;
      transition: .2s;

      &.is-active {
        transform: scale(1.1) translateY(-1px);
        outline: 1px dashed #ccc;
      }
    }
  }

  &-submenu
  {
    &.se-list-layer{
      &.se-list-font-size  {
        top: auto !important
      }
      &.se-list-font-family{
        top: auto !important
      }
      &.se-list-format{
        top: auto !important
      }
    }
  }
}

.se-dialog-footer {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  padding-bottom: .6rem !important;

  button {
    color: white !important;
    border-radius: 4px !important;
  }
}
.se-wrapper{
  border: 1px solid #B2BCC6;
}

