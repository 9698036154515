#list-form{
  h4{
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
    padding-left: .5rem;
    margin: 1rem;
    border-left: .2rem solid #5081be;
  }

  .details{
    h4{
      margin-top: 0;
    }
  }

  .switch{
    display: flex;
    align-items: center;
    .switch-container{
      margin-right: 6px;
    }
  }
  input, textarea{
    box-shadow: none;
  }
}
