.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9 !important;
  background-color: rgba(41, 57, 77, 0.4);
  overflow-y: scroll;
}

.modalContent {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: none;
  background: transparent;
  border-radius: 4px;
  outline: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContentAfterOpen {
  .confirm {
    top: 0;
    opacity: 1;
  }
}
.modalContentBeforeClose {
  .confirm {
    top: 50%;
    opacity: 0;
  }
}

.confirm {
  position: relative;
  width: 35.375rem;
  max-width: 90%;
  height: auto;
  opacity: 0;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.25);
  border-radius: 5px;
  transition: all 200ms ease-in-out;
  padding: 1rem 1rem;
}

.close-modal {
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  color: #3c4e64;
  background: #ffffff;
  border: 1px solid #f1f2f4;
  border-radius: 50%;
  span {
    margin-left: 0px !important;
  }
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 120px;

  border-radius: 5px 5px 0px 0px;
  font-size: 22px;
  line-height: 26px;

  text-align: center;

  .icon {
    position: relative;
    width: 3.75rem;
    height: 3.75rem;

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    svg {
      position: absolute;
    }
  }
  span {
    margin-top: 10px;
  }
}

.modal_body {
  width: 100%;
  height: max-content;
  padding: 24px 20px 0px;
  //border-bottom: 1px solid ;
  font-size: 18px;
  line-height: 21px;
  color: #29394d;
  display: grid;
  grid-gap: 10px;
  span {
    color: #29394d;
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .selection-title {
    input {
      width: 100%;
      margin: 0.5rem 0rem;
      padding: 5px;
      height: 2.75rem;
      border-radius: 0.3125rem;
      border: 1px solid #b2bcc6;
      background: #f8f9fa;
      ::placeholder {
        opacity: 0.5;
        color: #6d7f92;
        font-family: Roboto;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .selection-info {
    margin-top: 1.5rem;
    display: grid;
    grid-gap: 10px;
    .tag-types {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
      .tag {
        width: max-content;
        margin: 0 0.25rem;
        display: inline-flex;
        padding: 0.375rem 0.5625rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.3125rem;
        background: #c7e8fe;
      }
    }
  }
  .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    width: 100%;
    .title {
      color: #29394d;
      font-family: Roboto;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .tabs-types {
      display: flex;
      justify-content: flex-end;
      width: 60%;
      .tab_badge {
        width: 50%;
        margin: 0px 3px;
        padding: 7px 0px;
        border-bottom: 3px solid;
        border-color: #b2bcc6;
        cursor: pointer;

        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        line-height: 16px;
        color: #6d7f92;

        &_active {
          border-color: #18a0fb;
          color: #18a0fb;
        }
      }
    }
  }
  .groups {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    .tab-groups {
      width: 48%;
      border-radius: 0.3125rem;
      border: 1px solid #e1e4e8;
      padding: 1rem;
      span {
        color: #29394d;
        font-family: Roboto;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .tag {
        span {
          display: inline-block;
          padding: 0.4rem;
          border-radius: 0.3125rem;
          margin: 0.5rem 0.5rem 0 0;
        }
        .allowed-groups {
          color: #02af8e;
          background: rgba(6, 217, 177, 0.1);
        }

        .denied-groups {
          color: #de4848;
          background: rgba(235, 87, 87, 0.1);
        }
      }
      .not-found-msg {
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
      }
    }
  }
}
.separator {
  height: 1px;
  background-color: #f1f2f4;
  margin: 1rem 1rem;
}
.footer-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 0 1rem;
}

.yes,
.no,
.deleting {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  border: 0;
  border-radius: 5px;
  // font-family: $body-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }
}

.no {
  width: 126px;
  background: #fff;
  margin-right: 10px;
  color: #6d7f92;
  border: 1px solid #d8dde2;
  box-shadow: 0px 2px 10px 0px rgba(41, 57, 77, 0.1);
}

.yes,
.deleting {
  width: max-content;
  padding: 0 2rem;
}

.duplicate {
  .header {
    color: #29394d;
    text-align: center;
    font-family: Roboto;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .icon {
      background-color: rgba(6, 217, 177, 0.1);
    }
  }
  .yes,
  .deleting {
    background-color: #06d9b1;
    box-shadow: 0px 2px 10px 0px rgba(41, 57, 77, 0.1);
  }
}

.deleting {
  cursor: not-allowed;
  &:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 39.9375em) {
  .modal_body,
  .footer-modal {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
