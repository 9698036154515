#subscriber-manager{
  h3{
    text-align: center;
    margin-bottom: .8rem;
    font-size: 1.7rem;
  }
  .param-label{
    color: $main-color;
  }
  .summary__sub-header{
    font-size: 2rem;
  }
  background-color: rgba(243,243,243,0.3);
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2);
  .controls{
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    button{
      text-transform: uppercase;
      margin: 1rem 1rem 0;
    }
  }
}
