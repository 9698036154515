$ep-toolbar-height : rem-calc(40px);
$ep-padding-top: $ep-toolbar-height + 1rem;
$ep-bottom-bar-height: rem-calc(70px);

#stats-page {
  .viewer-actions {
    padding-right: 0;
    .secondary-button {
      margin-right: 96px;
    }
  }
  .stats-page {
    position: relative;
    background: #fafbfb;
    padding: 0 96px 30px;
    margin-bottom: 4rem;
    &__block {
      background: #fff;
      border-radius: 5px;
      background:#fff;
      border-radius: 5px;
      padding: 1.5rem;
      margin: 20px 0 0;
      
      .recipients-link-clicked{
        display: flex;
        align-items: baseline;
        margin-bottom: 1.5rem;
        
        h4 {
          font-size: 24px;
          letter-spacing: 0.02em;
          color:#29394D;
          margin-right: 1.5rem;
        }
        .link-clicked{  
          flex-grow: 1;
          line-break: anywhere;

        }
        .show-more-link {
          color: #2495E1;
          cursor: pointer;
          text-decoration: underline;
          margin-left: 8px;
      
          &.show-less {
            color: rgb(254, 55, 69) 
          }
        } 
      }
      
    }

    .summary {
      background: #fff;
      display: inline;

      li:last-of-type .flex-container {
        cursor: pointer;
        text-transform: capitalize;

        i {
          font-size: inherit;
        }
      }
    }
    .w-40 {
      width: 40%;
    }
    .w-58 {
      width: 58%;
    }
  }
  .filter-select {
    text-transform: uppercase;
    width: 300px;
  }

  .link-stats{
    li{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      padding: .6rem 0;
      cursor: pointer;
      &:hover {
        a {
          color: $main-blue;
          & + .count {
            background-color: $main-blue;
            color: #FFF;
          }
        }
      }
    }
  }

  .scheduled-info {
    background: #F3FAFF;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;
    span {
      color: #29394d;
      font-size: 25px;
      text-align: center;
    }
    .scheduled-date {
      z-index: 1;
      position: relative;
      transition: all .3s ease-in-out !important;
      text-align: center;
      padding-top: 1rem;
      & > div {
        display: inline-block;
        padding: 10px 23px;
        position: relative;
        border-right: 3px solid #fff;
        & > div {
          font-size: 20px;
          text-transform: uppercase;
        }
      }
      & > div:last-child {
        border: unset;
      }
    }

    button {
      margin-bottom: 1rem;

      svg {
        margin-right: 10px;
      }
    }
  }
  .icon-refresh {
    font-size: 1.2rem;
    cursor: pointer;
 }

 #sidebar {
   width: 4.63rem;
   position: fixed;
   background-color: rgba(255,255,2555,1);
   right: 0;
   box-shadow: 0px 1px 12px 0px rgba(0,0,0,0.12);
   transition: nth($transitions, 2);
   color: #B9B9B9;
   top: 18rem;
   z-index: 3;
   li {
     position: relative;
     padding: 0.9rem 0;
     text-align: center;
     cursor: pointer;
     border-top: 1px solid #f2f2f2;
     &:first-child {
       border-top: 0;
     }
     .sidebar__icon {
       cursor: pointer;
       text-align: center;
       transition: nth($transitions, 3);
       font-size: 1.5rem;
     }
     &:hover {
       color: $main-blue;
       background-color: nth($main-blue-alpha, 1);
       .sidebar__item-hover {
         opacity: 1;
         z-index: 5;
         transform: translateX(-100%);
       }
     }
     &.activated {
       color: $main-blue;
       background-color: nth($main-blue-alpha, 1);
     }
     .sidebar__item-hover {
       position: absolute;
       background-color: $main-blue;
       color: #fff;
       padding: 1rem 0.2rem;
       transform: translateX(0%);
       left: 0;
       top: 0;
       bottom: 0;
       transition: nth($transitions, 2);
       z-index: 5;
       right: 0;
       width: 13rem;
       opacity: 0;
       font-weight: 600;
     }
   }
 }
  .icon-arrow-down, .icon-arrow-up{
    padding-left: .5rem;
    font-size: 1rem;
    cursor: pointer;
  }
  .ttp-datatable__row {
      .drop-down-option {
        display: inline-block;
        padding-right: 1rem;
        .drop-down-option__left {
          margin-right: -1rem;
          top: 60%;
        }
      }
      .send-status {
        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: inline-block;
          margin-right: .4rem;
        }
        padding: .2rem .5rem;
        text-transform: uppercase;
        border-radius: 3rem;
        font-size: .7rem;
        background:#FFFFFF;
        font-weight: normal;
        font-size: 14px;
        color:#6D7F92;
        text-transform: uppercase;
      }
  }
  .icon-check, .icon-info{
    margin-left: 0.5rem;
  }
  .icon-check{
    color: #3adb76;
  }
  .flag-icon{
    margin-left: 1.2em;
  }
  .sms-count{
    margin-left: 1.5em;
  }
  .ttp-datatable__header{
    background-color: #3182bd;
    color: #FFF;
    opacity: .8;
  }

  .filter-container {
    display: flex;
    position: relative;
    margin-bottom: .7rem;

    .search {
      margin: 0 0 -.3rem -1rem;
    }
    .refresh {
      margin: .4rem 2rem 0 0 !important;
    }
    .download-csv{
      cursor: pointer;
      margin: .4rem 16rem 0 0 !important;

    }
  }
  .list-item__count {
    color: #FFF;
    margin-bottom: -2rem;
  }
  .close {
    top: 1rem !important;
  }
  .link-filters span.active {
    background: #6D7F92;
    color: #FFFFFF;
    font-weight: 500;
    text-shadow: 0px 4px 4px rgba(41, 57, 77, 0.4);
  }
  .link-filters span {
    background: #F1F2F4;
    border: 0 solid #F1F2F4;
    box-sizing: border-box;
    border-radius: 30px;
    margin-right: .4rem;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #6D7F92;
    padding: 9px 17px;
    cursor: pointer;
    transition: background-color .3s;
  }
  .link-filters-container{
    padding-right: 9px;
    margin-top: 6px;
  }
}

#resend-email-form {
  .check-box-container span {
    font-size: 1rem;
  }
  .warning {
    background: #FCFAF2;
    font-size: 14px;
  }
}
