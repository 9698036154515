.generic-emails-page {
    min-height: 100vh;
    .viewer-actions div:nth-child(2){
        margin-right: 7.2rem;
    }
    .emails-actions {
      padding: 0;
      margin-bottom: 1rem;
      display: flex;
      justify-content: flex-end;
      button {
        background: #FAFBFB;
        border: 0.5px solid rgba(109, 127, 146, 0.2);
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        border-radius: 4px;
        padding: 10px 20px;
        font-weight: 500;
        font-size: 14px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        color: #3C4E64;
        &:first-child:hover {
          background: #18A0FB;
          color: white;
          border: none;
        }
        &:hover {
          box-shadow: none;
        }
        .icon {
          margin-right: .5rem;
          font-weight: 600;
          font-size: 16px;
        }
        cursor: pointer;
        .check-box-container {
          margin: 0;
        }
      }
    }
}

#generic-email-form {
  position: relative;
  padding: 0 8rem 3rem;
  min-height: 80vh;
  background: #FAFBFB;
  margin-top: 4.5rem;

  h3 {
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  #generic-email-form {
    padding: 0 2rem 3rem;
    #theme-picker .theme-picker__list {
      padding: 38px 20px 75px;
    }
  }
}
