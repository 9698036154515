.viewer {
  &__info {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    border-bottom: 1px solid #F1F2F4;
    position: relative;

    .user-avatar {
      width: 48px;
      min-width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
      img {
        height: 100%;
      }
    }

    .spacer {
      flex-grow: 1;
    }

    .resend-btn {
      width: 38px;
      min-width: 38px;
      height: 38px;
      background: #FFFFFF;
      border: 1px solid #F1F2F4;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .2s ease;
      i {
        font-size: 16px;
        color: #2495E1;
      }
      &:hover {
        background: #2495E1;
        transform: scale(1.1);
        i {
          color: #FFFFFF;
        }
      }
    }
  }
}
