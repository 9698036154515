.total_members {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0 16px 15px;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #F1F2F4;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
  .users_logo {
    justify-content: left;
    align-items: center;
  }
  .number_content {
    transform: translateX(1rem);
    .number {
      font-family: Roboto;
      padding-top: 1%;
      font-weight: 500;
      font-style: normal;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */
      color: #29394D;}
  }
}
