.e-box-fetching{
  h4 {
    width: 50% !important;
  }
  h5 {
    width: 80% !important;
  }
  .padding-left {
    padding-left: 7rem;
  }
}
