@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/fonts-icomoon/icomoon.eot?ji6atd');
  src:  url('/fonts/fonts-icomoon/icomoon.eot?ji6atd#iefix') format('embedded-opentype'),
    url('/fonts/fonts-icomoon/icomoon.ttf?ji6atd') format('truetype'),
    url('/fonts/fonts-icomoon/icomoon.woff?ji6atd') format('woff'),
    url('/fonts/fonts-icomoon/icomoon.svg?ji6atd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icomoon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon {
  &.icon-quotes-left:before {
    content: "\e977";
  }
  &.icon-list-numbered:before {
    content: "\e9b9";
  }
  &.icon-list:before {
    content: "\e9ba";
  }
  &.icon-link:before {
    content: "\e9cb";
  }
  &.icon-image:before {
    content: "\e901";
  }
  &.icon-unlink:before {
    content: "\e900";
  }
  &.icon-code:before {
    content: "code2";
  }
  &.icon-bold:before {
    content: "\ea62";
  }
  &.icon-underline:before {
    content: "\ea63";
  }
  &.icon-italic:before {
    content: "\ea64";
  }
  &.icon-strikethrough:before {
    content: "\ea65";
  }
  &.icon-text-color:before {
    content: "\ea6d";
  }

  &.icon-cancel:before {
    content: "cancel";
  }

  &.icon-tick:before {
    content: "tick";
  }
  &.icon-filter:before {
    content: "filter";
  }
  &.icon-blocked:before {
    content: "blocked";
  }
}

.icon-tt-menu:before {
  content: "\e941";
}
.icon-tt-event:before {
  content: "\e942";
}
.icon-tt-stats:before {
  content: "\e943";
}
.icon-tt-folder:before {
  content: "\e944";
}
.icon-tt-network:before {
  content: "\e946";
}
.icon-tt-envelope-open:before {
  content: "\e947";
}
.icon-tt-profil:before {
  content: "\e93d";
}
.icon-tt-closed-envelope:before {
  content: "\e93e";
}
.icon-tt-grid:before {
  content: "\e93f";
}
.icon-tt-bell:before {
  content: "\e940";
}
.icon-tt-contact:before {
  content: "\e939";
}
.icon-tt-instagram:before {
  content: "\e93a";
}
.icon-tt-facebook:before {
  content: "\e93b";
}
.icon-tt-linkedin:before {
  content: "\e93c";
}
.icon-equalizer:before {
  content: "\e948";
}
.icon-group:before {
  content: "\e915";
  color: #8d8d8d;
}
.icon-tt-close:before {
  content: "\e900";
}
.icon-linkedin:before {
  content: "\e901";
}
.icon-facebook1:before {
  content: "\e904";
}
.icon-twitter:before {
  content: "\e902";
}
.icon-tt-copy:before {
  content: "\e927";
}
.icon-tt-edit:before {
  content: "\e928";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-linegraph:before {
  content: "\e907";
}
.icon-stop:before {
  content: "\e903";
}