.side-no-tabs-page {
  background: #F8F9FA;

  .white-container {
    background: white;
    margin: auto 81px;
    border: 0.5px solid #F1F2F4;
    box-shadow: 0 4px 4px rgba(41, 57, 77, 0.05);
    border-radius: 5px;
    padding-bottom: 4rem;

    &--title {
      display: block;

      &-with-filter {
        position: absolute;
        left: 8.5rem;
      }

      h3 {
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        color: #29394D;
        margin: 1.5rem 0 auto;
      }

      p {
        font-size: 12px;
        line-height: 14px;
        color: #6D7F92;
        margin-bottom: 1.5rem;
      }
    }

    #ttp-horizontal-filter {
      margin: 1.8rem 0;
    }
  }

  .bubbles {
    & > div {
      background: #FFFFFF;

      &:hover, &.active {
        background: $curious-blue;
      }
    }
  }
}
