.ttp-modal {
  height: 100%;
  max-width: unset;
  overflow: hidden;

  .small-12 {
    height: 100%;
  }

  &__close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #F1F2F4;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1;

    img {
      position: absolute;
      width: 36px;
      height: 14px;
      top: 10px;
      left: 0;
    }
  }

  &__header {
    height: 73px;
    width: 100%;
    background: #F3FAFF;
    border-radius: 5px 5px 0 0;
    padding: 22px 32px;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
    border-bottom: 1px solid rgba(199, 232, 254, 0.5);
    & > span {
      font-family: Roboto, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;
      color: #2495E1;
    }
  }
}
