.speakers {
  &-item {
    position: relative;
    border: 1px solid #F8F9FA;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 14px;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: .5rem;
    width: 100%;
    box-shadow: 0px 3px 30px -9px rgba(0, 0, 0, 0.2);

    .band{
        top: 35%;
        right: -25%;
    }

    .feed-order {
      top: 37%
    }

    &:hover {
      background: #F3FAFF;
      border: 1px solid #C7E8FE;

      .toggleButton {
        display: block;
      }

      .toggleButton input {
        & + div::before, & + div::after {
          background: $dodger-blue;
        }

        & + div svg {
          stroke-width: 1;
          stroke: $dodger-blue;
        }
      }
    }

    .avatar {
      cursor: pointer;
      overflow: inherit;
      opacity: 0.9;
      background-size: cover;
      background-position: 50%;
      width: 10rem;
      height: 10rem;
      margin-bottom: 1rem;
      text-align: center;

      &:before {
        content: '';
        width: 110%;
        height: 110%;
        position: absolute;
        top: -5%;
        left: -5%;
        border-radius: 100%;
        z-index: 0;
        border: .2rem solid transparent;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 1rem;
      margin: 0 0 1px 0;
      color: #505050;
    }

    h5 {
      font-size: .8rem;
      font-weight: 600;
    }

    &__header {
      flex-direction: column;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
}
