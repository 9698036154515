#gallery {
  .sidebar {
    transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    box-shadow: inset 1px 0 1px $french-pass;
    background-color: #F8F9FA;
    width: 450px;
    top: 0;
    padding-left: 1.3rem;
    padding-right: 0;
    z-index: 3;
    padding-bottom: 0;

    &__actions{
      .row {
        margin-left: -1rem;
      }
    }
    &__close{
      right: 1rem !important;
      top: -1.3rem !important;
      font-size: initial;
    }
    &__form {
      margin: 0;
      .gallery-header {
        margin-bottom: 0.5rem;
        margin-left: 0rem;
        margin-right: 1.2rem;
        padding-bottom: 19px;
      }
      .gallery-content {
        height: 90vh;
        width: 430px;
        padding-right: 20px;
        overflow-y: auto;
      }
      .settings-filter__status {
        width: 410px;
        border-bottom: 1px solid rgba(109, 127, 146, .1);
        border-top: 1px solid rgba(109,127,146,.1);
        margin-bottom: 1rem;
        margin-left: 0;
        padding: 8px 0;
      }
      .settings-filter__status.context span {
        padding: 4px 6px;
        font-size: 11px;
      }
      .settings-filter__status span{
        padding: 4px 14px;
      }
      .row{
        border-bottom: 1px solid rgba(109,127,146,.1);
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
      .tabs-filters {
        border: none;
        padding: 0;
        .search-box {
          width: 268px;
        }
        button{
          color: #6d7f91;
          padding: 9px;
          font-size: 11px;
          position: unset;
          background: unset;
          width: unset;
          height: unset;
          margin: unset;
          box-shadow: unset;
          border-radius: unset;
          display: unset;
        }
        button.active{
          color: #2495e1;
          border-bottom:2px solid #257bc5;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          background: unset;
        }
        nav {
          position: unset;
          text-align: unset;
        }
      }
      h4 {
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.06em;
        color: #3C4E64;
        margin-top: -1.5rem;
        text-align: start;
        line-height: 23px;
      }
      h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #6D7F92;
      }
      h6 {
        margin-top: 1rem;
      }
      .h7 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #29394D;
      }
      .h8 {
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        text-transform: lowercase;
        color: #6D7F92;
        text-align: right;
      }
      .div-loader {
        padding-top: 17rem;
        padding-left: 7rem;
        color: #3C4E64;
        & > div > div > div {
          background-color: #3C4E64;
        }
      }
      .attachment__container.notfound {
        background: white;
        margin: 0 1rem 0 0;
      }
    }
    .attachment__container {
      margin-right: 0.5rem;
    }
    .attachment__container:nth-child(2n),.attachment__container.small-12 {
      margin-right: 1px;
      flex: 1;
    }
    .attachment__container.small-6 {
      flex: 0 48.9%;
    }
    .attachment__container a{
      display: block;
      height: 260px;
      background-size: auto 100%;
      background-position: center;
      background-repeat: no-repeat;
      text-align: center;
    }
    .attachment__container.small-6 a{
      height: 127px;
    }
    .attachment__container.small-6.icon img{
      display: block;
      margin: 5% auto;
      width: 55%;
    }
    .attachment__container.small-12.icon a img{
      display: block;
      margin: 20% auto;
      width: 40%;
    }
  }
  .preview-sidebar {
    left: -2px;
    width: calc(100% - 448px);
    background-color: rgba(30, 30, 33, 0.8) !important;
    overflow: hidden;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out !important;
    .sidebar__close{
      color: #F1F2F4;
    }
  }
  iframe {
    overflow-y: hidden;
    overflow-x: auto;
    position: absolute;
    top: 2rem;
    border: 1px solid rgba(0,0,0,0.06);
    width: 80%;
    margin: 0 0 0 7.5%;
    height: 93% ;
  }
  .side-sidebar{
    width: 0 !important;
    padding: 0;
  }
}
