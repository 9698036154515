.campaign-type__selector {
    .page-header {
        margin-bottom: 3rem;
    }

    .tabs-wrapper{
        margin-top: 3.5rem;
        li.active{
            color: #fff;
            margin-bottom: -1px;
            border-bottom: 4px solid #5080be;    
        }
    }
    .boxes {
        background-color: #fff;
        min-height: 64vh;
        background: #FAFBFB;
        padding: 20px 0 2rem;
        max-width: 100%;
    }
}