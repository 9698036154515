#theme-manager {
  background-color: #d8d8d8;
  .theme-btn-action {
    border-radius: 2px;
    background-color: $main-blue;
    width: inherit;
    padding: 1rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
  }

  #style-tools-menu {
    background-color: #2b2e33;
    padding: 0;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    bottom: 0;
    min-width: 18%;
    transition: transform ease-in-out .5s;
    h3 {
      color: #DDD;
      font-size: 1.2rem;
      font-family: $h-font-familly;
      font-weight: bold;
    }
    ul {
      margin: 3rem 0 2rem;
    }
    li {
      display: flex;
      height: 3rem;
      align-items: center;
      padding-left: 2rem;
      text-transform: uppercase;
      border-bottom: 1px solid #222528;
      cursor: pointer;
      transition: nth($transitions, 2);
      &:first-child {
        border-top: 1px solid #222528;
      }
      i {
        margin-right: 1.2rem;
        font-size: 1rem;
      }
    }
  }

  .e-box-preview {
    min-width: 30% !important;
  }

  #style-tools-menu-logo {
    display: flex;
    align-items: center;
    height: 6rem;
    background: rgba(255,255,255,0.05);
    padding-left: 1rem;
    position: relative;
    img {
      height: 3rem;
      margin-right: 1rem;
    }
  }
  .tmpl-form {
    margin-bottom: 2rem;
    width: 100%;
    padding: 0 1.5rem;
    border-left: 3px solid $main-orange;
  }
  #style-tools-menu-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1rem;
    width: 100%;
    .cancel {
      background-color: #222528;
      margin-top: .5rem;
    }
  }
  .style-tools-palette {
    width: 25%;
    background-color: #222528;
    transition: transform .3s ease-in-out;
    .apply-all {
      border-bottom: 1px solid #555;
      margin: 1rem auto;
      padding: 1rem;
      &-item{
        display: flex;
        justify-content: space-between;
        h3 {
          padding-right: 2rem;
          font-size: .9rem;
          font-weight: bold;
          &::first-letter{
            text-transform: capitalize;
          }
        }
      }
    }
    &.start {
      transform: translateX(-100%);
    }
    img {
      padding: .3rem;
      border: 1px dashed #ccc;
      display: block;
      margin: auto;
      max-height: 80%;
    }
    #save-btn {
      float: right;
      margin-top: 1.5rem;
    }
    &.add-bloc-overflow-y {
      overflow-y: auto;
      position: fixed;
      z-index: 2;
      top: 0;
      bottom: 3Opx;
      left: 18%;
      height: 100%;
    }
    .upload-img-form{
      &__header{
        font-size: 1.2rem;
        margin-bottom: 1.2rem;
        text-align: center;
      }
      &__label{
        text-transform: uppercase;
        font-size: .8rem;
      }
      margin: 3rem .4rem;
    }
    .add-theme-img {
      padding: .3rem;
      margin: 1rem 1rem 2rem;
      //overflow: hidden;
      position: relative;
      display: flex;
      box-shadow: 0px 0px 6px 1px rgba(55, 55, 55, 0.4);
      background-color: #FFF;
      transition: nth($transitions, 3);
      justify-content: center;
      text-align: center;
      border-radius: 2px;
      flex-direction: column;
      height: auto;
      min-height: 10rem;
      .add-img {
        position: absolute;
        display: flex;
        z-index: -1;
        top: 0;
        bottom: 0;
        opacity: 0;
        right: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        background-color: nth($main-blue-alpha, 6);
        color: #fff;
        transition: .3s all ease-in-out;
        .icon{
          color: #fff;
        }
        i {
          border-radius: 50%;
          transition: 0.3s all ease-in-out;
          cursor: pointer;
          padding: .5rem;
          &:hover {
            background-color: $main-blue;
            box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.3);
          }
        }
      }
      &.add-theme-img__preview:hover {
        transform: scale(1.1);
        .add-img {
          z-index: 0;
          opacity: 1;
        }
      }
      textarea{
        margin-top: .5rem;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid #eee;
      }
      .edit-theme-controls{
        align-items: center;
        justify-content: space-between;
        display: flex;
        .btn {
          padding: 0 12px;
          min-width: 40%;
          font-size: 12px;
          height: 32px;
          border-radius: 2px;
          margin: 0;
        }
      }
    }
  }
  &__preview{
    margin: 3rem auto;
    padding: .5rem;
    background-color: #fff;
    border: 1px solid;
    img:hover{
      cursor: pointer;
    }
    table, tbody, tfoot, thead, tr, th, td {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 100%;
      vertical-align: baseline;
      font-family: Arial, sans-serif;
  }
    tbody tr:nth-child(2n){
      background-color: transparent;
    }
    tbody{
      background-color: transparent;
    }
    .img-selected {
      opacity: 0.6;
      border: .2rem dashed black;
    }
  }
  .menu__language {
    display: flex;
    margin: 0;
    li {
      width: 40px;
      height: 40px;
      border: 1px solid #C7E8FE;
      border-radius: 50%;
      background: #fff;
      color: #18A0FB;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 0.5rem 0.3rem;
      &.selected {
        background-color: #18A0FB;
        color: #FFF;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .search-box {
    width: 100%;
  }
}
