#theme-category-form {
  padding-bottom: 20%;
  #mailing-lists-form .sidebar__header {
    text-align: left;
    color: #29394D;
    font-size: 18px;
  }
  .header-list {
    position: relative;
    h4 {
      color: #29394D;
    }
    h6 {
      font-weight: 300;
    }
    &:after {
        position: absolute;
        width: 96.3%;
        height: 1px;
        bottom: -15px;
        left: 15px;
        background: #EAEDF0;
        content: '';
        
    }
  }
  #mailing-lists-form {
    h6 {
      font-family: Roboto;
      font-weight: normal;
      font-size: 14px;
      color: #6D7F92;
    }
    h6 span {
      font-weight: 300;
    }
    .sidebar .row-top:after {
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -12px;
      background: #EAEDF0;
      content: '';
      left: 0;
    }
    .sidebar__form .empty-box {
      border: 1px solid #B2BCC6;
      background: #F8F9FA;
      box-shadow: none;
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
     }
     .sidebar__form .buttons-container {
      position: relative;
     }
     .sidebar__form .buttons-container:after {
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      top: 8px;
      background: #EAEDF0;
      content: '';
      margin: 0 auto;
    }
    .sidebar .row-top {
      background: #FFFFFF;
      border: 1px solid rgba(178, 188, 198, 0.5);
      box-sizing: border-box;
      border-radius: 5px;
      padding: 0 8px 30px 8px;
      margin-top: 30px;
      position: relative;
    }
    .sidebar__form .flag-icon {
      background-position: 0 32px;
      padding-left: 10px;
      margin-left: 15px;
    }
    .sidebar__form .param__title {
      text-transform: capitalize;
    }
  }
  .theme-category input{
    width: 90%;
    display: inline-block;
    border-radius: 3px;
    height: 36px;
    margin-left: 7px;
    margin-bottom: 5px;
    background: #F8F9FA;
    box-shadow: none;
  }
  .sidebar__form input {
    margin-bottom: 0;
    border-radius: 3px;
  }
  .theme-category .flag-icon{
    font-size: 16px;
  }
  .delete-category, .edit-category, .badge {
    opacity: 0;
    position: absolute;
    right: 40px;
    top: -3px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .edit-category {
    opacity: 1;
    right: 40px;
    top: 13px;
  }
  .theme-category.notheme{
    background: #f8f9fa;
    border: 1px solid rgba(109, 127, 146, 0.2);
  }
  .theme-category:hover .delete-category {
    opacity: 1;
    top: 13px;
    z-index: 1;
  }
  .theme-category:hover .badge {
    opacity: 0;
  }
  .theme-category:hover {
    border: 1px solid #18a0fb;
  }
  .theme-category {
    display: flex;
    background: #f3faff;
    border: 1px solid #c7e8fe;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(41, 57, 77, 0.05);
    border-radius: 5px;
    margin:0.5rem 14px 0.5rem 0;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #000000;
    padding: 0.8rem;
    position: relative;
    cursor: pointer;
    .badge {
      top: 11px;
      opacity: 1;
      margin-left: 15px;
      background: #18a0fb;
    }
    .cancel-btn {
      transform: rotateY(180deg);
      display: inline-block;
      margin-right: 8px;
    }
    .see-more {
      position: absolute;
      right: 4px;
      top: 11px;
      border-left: 1px solid #d1d1d2;
      padding: 3px 5px 2px 8px;
    }
    .categories-inputs {
      position: absolute;
      width: 100%;
      left: 0;
      top: 40px;
      border-radius: 0px 0px 4px 4px;
      z-index: 1;
      background: #EAEDF0;
      border: 1px solid rgba(135, 150, 167, 0.2);
      border-top: none;
      padding-top: 11px;
      padding-bottom: 6px;
      .columns {
        position: relative;
        padding: 0 5px 0 11px;
        svg {
          fill: #29394D;
          position: absolute;
          right: 20px;
          top: 9px;
        }
      }
    }
    &.gradiant {
      background: linear-gradient(to right, #F9F9F9, #EEE, #E5E5E5, #DEDEDE);
      background-size: 300% 100%;
      min-height: 41.5px;
      border: none;
    }
  }
}
