/*
This loop will create "margin helpers" and "padding helpers".
It will generate several classes such as:
.m-r-s which gives margin-right 16 pixels.
.m-r-m gives MARGIN to the RIGHT 24 pixels.
.m-t-m gives MARGIN to the TOP 24 pixels and so on.
.p-b-xs gives PADDING to the BOTTOM of 8 pixels
.p-l-l gives PADDING to the LEFT of 32 pixels
.p-x-l gives PADDING to the LEFT AND RIGHT of 32 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", "r", "x", or "y" for TOP, BOTTOM, LEFT, RIGHT, LEFT AND RIGHT, or TOP AND BOTTOM
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spacings variable below.
*/

/** supported units px | rem */
$spacing-unit: 'rem';

$spacing-xxs: if($spacing-unit == 'px', 4, .25)#{$spacing-unit};
$spacing-xs: if($spacing-unit == 'px', 8, .5)#{$spacing-unit};
$spacing-s: if($spacing-unit == 'px', 16, 1)#{$spacing-unit};
$spacing-m: if($spacing-unit == 'px', 24, 1.5)#{$spacing-unit};
$spacing-l: if($spacing-unit == 'px', 32, 2)#{$spacing-unit};
$spacing-xl: if($spacing-unit == 'px', 48, 3)#{$spacing-unit};
$spacing-xxl: if($spacing-unit == 'px', 64, 4)#{$spacing-unit};

$directions: (top, bottom, left, right, x, y, all);
$spacings: (
  xxs: $spacing-xxs,
  xs: $spacing-xs,
  s: $spacing-s,
  m: $spacing-m,
  l: $spacing-l,
  xl: $spacing-xl,
  xxl: $spacing-xxl,
);

@each $space, $value in $spacings {
  @each $direction in $directions {

    @if $direction == 'all' {
      .m-#{$space} {
        margin: $value !important;
      }

      .p-#{$space} {
        padding: $value !important;
      }
    }

    @else if $direction == 'x' {
      .m-x-#{$space} {
        margin-left: $value !important;
        margin-right: $value !important;
      }

      .p-x-#{$space} {
        padding-left: $value !important;
        padding-right: $value !important;
      }
    }

    @else if $direction == 'y' {
      .m-y-#{$space} {
        margin-top: $value !important;
        margin-bottom: $value !important;
      }

      .p-y-#{$space} {
        padding-top: $value !important;
        padding-bottom: $value !important;
      }
    }

    @else {
      .m-#{str-slice($direction, 0, 1)}-#{$space} {
        margin-#{$direction}: $value !important;
      }

      .p-#{str-slice($direction, 0, 1)}-#{$space} {
        padding-#{$direction}: $value !important;
      }
    }

  }
}


.no-padding {
  padding: 0 !important;

  &--top {
    padding-top: 0 !important;
  }

  &--left {
    padding-left: 0 !important;
  }

  &--bottom {
    padding-bottom: 0 !important;
  }

  &--right {
    padding-right: 0 !important;
  }
}

.no-margin {
  margin: 0 !important;

  &--top {
    margin-top: 0 !important;
  }

  &--left {
    margin-left: 0 !important;
  }

  &--bottom {
    margin-bottom: 0 !important;
  }

  &--right {
    margin-right: 0 !important;
  }
}
