.bounce-detail{
    p {
        color: #6D7F92;
    }
    &-item{
        display: flex;
        position: relative;
        flex-direction: column;
        border: 1px solid;
        justify-content: space-between;
        border-right: none;
        min-height: 4rem;
        padding: .8rem;
        text-align: center;
        color: #3C4E64;
        cursor: pointer;
        
        h5{
            font-size: 1rem;
            text-transform: uppercase;
        }

        &-count{
            font-size: 3rem;
        }
        &:last-child{
            border-right: 1px solid;
        }
        &:hover {
            .progress-bar-overlay{
                opacity: 1;
            }
            &-count{
                visibility: hidden;
            }
        }
    }
    .progress-bar{
        &-container{
            background-color: #e5e5e5;
            height: 8px;
        }
        &-content{
            position: relative;
            overflow: visible!important;
            border-radius: 4px;
            height: 8px;
        }
        &-percent{
            text-align: right;
            font-size: .8rem;
        }
        .bar-color-1{
            background-color: #F31431;
        }
        .bar-color-2{
            background-color: #cc3300;
        }
        .bar-color-3{
            background-color: rgb(86, 194, 76);
        }
        .bar-color-4{
            background-color: rgb(148, 148, 148);
        }
        &-overlay{
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease-in-out;
            background-color: rgba(80,80,80,.8);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            .icon{
                font-size: 2rem;
            }
        }
    }
}