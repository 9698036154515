.progress-bar {
    display: flex;
    margin-top: 1rem;
    align-items: center;
    &-container {
        border: 1px solid #FFF;
        background-color: #FFF;
        width: 600px;
        height: 10px;
        overflow: hidden;
        color: #fff;
        position: relative;
        border-radius: 50px;
    }
    &__fill {
        background-color: #18A0FB;
        height: 15px;
        &::before{
            border-color: #18A0FB;
        }
      &.animation {
        animation: move 2s linear infinite;
        background-image: linear-gradient( -45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent );
      }
    }
    &__rate {
        color: #29394D;
        margin-left: .5rem;
    }
}
@keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 600px 0px;
    }
  }