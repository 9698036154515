.iframe {
  width: 100%;
  //height: calc(100vh - 64px);
  height: calc(100% - 20px);
  z-index: 0;
  box-shadow: none;
  border: none;
  background-color: #fcfcfc;
  position: relative;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: fixed;
  top: 45%;
  left: 48%;
  z-index: 99999;
}
.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #18a0fb;
  border-color: #18a0fb transparent #18a0fb transparent;
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
