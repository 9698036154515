$page-size: 36px;
.pagination {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    padding: 0;
    line-height: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 1.5rem auto;
    li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 .1rem;
        &:last-child, &:first-child {
            display: flex;
        }
    }
    .current{
      padding: 0;
    }
    a:hover {
        background: transparent;
    }
    .next, .previous {
        text-transform: uppercase;
        background: #F8F9FA;
        border-radius: 40px;
        width: 130px;
        height: $page-size;
        font-size: 12px;
        font-weight: 500;
    }
    .next:hover , .previous:hover {
        background: #F1F2F4;
    }
    .page, .break {
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #F1F2F4;
        box-sizing: border-box;
        border-radius: 30px;
        font-size: 12px;
        color: #3C4E64;
        width: $page-size;
        height: $page-size;
        font-weight: 500;
        a {
            color: var(--Dark-3, #6D7F92);
        }
        &:hover {
            background: #F1F1F1;
        }
    }
    .current, .current:hover {
        background: var(--Dark-3, #6D7F92);
        a {
            color: #fff;
        }
    }
    .disabled {
        padding: 0;
        background: #F8F9FA;
        a {
            cursor: not-allowed !important;
            color: $slate-gray !important;
        }
    }
    .pagination li:last-child, .pagination li:first-child {
        display: flex;
    }
}
