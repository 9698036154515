.percentChart {
  @media (max-width: 1680px) {
    display: block;
  }
  min-height: 135px;
  width: 100%;
  padding: 8% 0% 8% 0%;
  background: #FFFFFF;
  border: 1px solid #F1F2F4;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
  display: flex;
  position: relative;
  min-width: 10rem;
  &__loader{
    position: absolute;
    left: 44%;
    top: 50%;
    display: ruby;
  }
  .percentBars{
    height: 90px;
    width: 66px;
    align-items: left;
    margin: 0% 0% 0% 20%;
  }
  .percentKeys_container{
    position: relative;
    display: flex;
    @media (max-width: 1680px) {
      margin: 0%;
    }
    flex-direction: column;
    align-items: center;
    margin: 0% 0% 0% 10%;
    .keyItems{
      min-width: 117px;
      li{
        column-gap: 7px;
        span,p {
          font-style: normal;
          font-size: 12px;
          line-height: 14px;
          color: #29394D;
          display: ruby;
        }
      }
    }
  }
}
