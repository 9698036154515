$checked-color: #18A0FB;
$unchecked-color: $pickled-bluewood;
$checkbox-height: 20px;
$background-color:#FFF;
$duration: .4s;

@-moz-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2; }
}

@-webkit-keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2; }
}

@keyframes dothabottomcheck{
    0% { height: 0; }
    100% { height: $checkbox-height/2;  }
}

@keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.1; }
}
@-webkit-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.1; }
}
@-moz-keyframes dothatopcheck{
    0% { height: 0; }
    50% { height: 0; }
    100% { height: $checkbox-height * 1.1; }
}

input[type=checkbox]{
    display:none;
}

.check-box-container {
    display: flex;
    align-items: center;
    margin: 0 4px 0 0;
    position: relative;

  img {
    position: absolute;
    left: 13px;
    cursor: pointer;
  }
}

.check-box {
    height: $checkbox-height;
    width: $checkbox-height;
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: border-color ease $duration/2;
    -o-transition: border-color ease $duration/2;
    -webkit-transition: border-color ease $duration/2;
    transition: border-color ease $duration/2;
    cursor:pointer;
    margin-left: 0;
    background: #F8F9FA;
    border: 2px solid #B2BCC6;
    border-radius: 3px;
}

input[type=checkbox]:checked + .check-box,
.check-box.checked{
  border-color: $checked-color;
  background: $checked-color;
}
