.setting-step {
  input {
    font-size: 14px;
  }
  .target-label {
    background: #F3FAFF;
    border: 1px solid #CDE7FC;
    box-sizing: border-box;
    border-radius: 4px;
    height: 37px;
    font-size: 14px;
    line-height: 16px;
    color: #29394D;
    padding: 14px;
    display: flex;
    align-items: center;
    cursor: not-allowed;
    span {
      background-color: #EBECF0;
      border-radius: 2em;
      color: #172B4D;
      font-size: 12px;
      padding: 0.16em 0.5em;
      margin-left: 1rem;
      text-transform: uppercase;
    }
  }
  .field-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .field {
      width: 49%;
      margin-bottom: 1rem;
    }
  }

  .is-invalid-input {
    margin-bottom: 0;
  }

  .email-field svg {
    right: 22px;
    top: 8px;
  }

  .ttp-select {
    text-transform: uppercase;
  }

  .small-6.column {
    height: 94px;
  }
}