.feed {
  position: relative;
  display: flex;
  background: #FFFFFF;
  border: 1px solid #F8F9FA;
  box-shadow: 0px 4px 5px rgba(41, 57, 77, 0.1);
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 14px;
  padding: 13px;
  height: 120px;
  cursor: pointer;
  overflow: hidden;
  .band {
    right: -25%;
    transition: right .2s;
  }
  .toggleButton, .feed-order {
    background: #fff;
    border-radius: 50%;
  }

  .feed-order {
    border: 3px solid rgba(36, 167, 255, 0.8);
    position: absolute;
    top: 39px;
    right: -25%;
    width: 44px;
    height: 44px;
    z-index: 3;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #18a0fb;
    transition: right .2s;
  }
  &-expanded {
    height: auto;
  }

  &.selected {
    .feed-order {
      right: .5rem;
    }
    .band {
      right: -25%;
    }
  }
  &:hover {
    background: #F3FAFF;
    border: 1px solid #C7E8FE;
    .feed-order {
      right: -25%;
      display: none;
    }
    .band {
      right: .5rem;
    }
    .toggleButton input {
      & + div::before, & + div::after{
        background: $dodger-blue;
      }
      & + div svg {
        stroke-width: 1;
        stroke: $dodger-blue;
      }
    }
  }
  &_content{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    &-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 126%;
      a {
        color: #29394D;
      }
    }
    &-summary {
      font-weight: 300;
      font-size: 12px;
      line-height: 140%;
      color: #3C4E64;
      margin: 0;
    }
  }
  &__media {
    width: 135px;
    height: 94px;
    border-radius: 5px;
    border: 1px solid #eee;
    background-size: cover;
    background-position: center;
  }
  &__expander {
    height: 34px;
    margin: auto 13px auto 0;
    cursor: move;
  }
  &__category {
    position: absolute;
    top: 0;
    right: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
    border-radius: 2px;
    padding: 4px 9px;
    height: 20px;
  }
  &_author {
    display: flex;
    margin-bottom: 10px;
    &-avatar {
      width: 26px;
      height: 26px;
      background-size: contain;
      border-radius: 50%;
      margin-right: 5px;
    }
    &-name {
      font-size: 10px;
      color: #3C4E64;
    }
    .date {
      font-weight: 300;
      font-size: 8px;
      line-height: 9px;
      color: #6D7F92;
    }
  }
  &-detail {

  }
  .read-more {
    position: absolute;
    bottom: 0;
    right: 0;
    text-transform: uppercase;
    font-size: 10px;
    color: #6D7F92;
    margin: 0 10px 10px;
    .down svg {
      transform: rotate(180deg);
    }
    svg {
      height: 10px;
      width: 10px;
      margin: 0 4px;
      g path {
        fill: #6D7F92;
      }
    }
  }
  &__tags{
    text-transform: uppercase;
    font-family: $h-font-family;
    min-height: 3.79rem;
    color: #6D7F92;
    display: flex;
    margin-top: 10px;

    a {
      transition: nth($transitions, 3);
      color: #22262A;
      font-size: 0.60rem;
      background: #F1F2F4;
      border-radius: 3px;
      padding: 3px 8px;
      margin-right: 6px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      &:hover {
        color: $h-color;
      }
      &:first-child:before {
        content: '';
      }


    }
  }
}
