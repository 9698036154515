.viewer-actions {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F1F2F4;
    padding: .8rem;
    align-items: center;
    h4 {
        font-size: 22px;
        color: $pickled-bluewood;
        display: inline-block;
        margin: 0 0 0 2rem;
    }

    .close-icon {
        display: flex;
        align-items: center;
        min-height: 48px;
    }

    img {
        height: 20px;
        cursor: pointer;
    }

    button {
        box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
        border-radius: 50px;
        padding: 14px 30px;
        cursor: pointer;
        outline: none;
    }

    .primary-button {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #06D9B1;
    }
    .secondary-button {
        line-height: 16px;
        background: #F3FAFF;
        color: $dodger-blue;
        font-size: 14px;
        height: 44px;
        text-transform: capitalize;
        margin: 0 1rem;
        i {
            font-weight: bold;
        }
    }
}