@import "components/sms/bills/bill-detail";
@import "components/sms/bills/sms-credit-validation-page";

#sms-bills-page {
  margin-top: 0;
  position: relative;
  min-height: calc(100vh - 154px);
  padding-bottom: 2rem;

  select:last-child {
    margin-left: 40px;
  }
  .sidebar {
    position: absolute;
    width: 396px;
    z-index: 3 !important;
    right: 0;
    padding: 48px 24px;

    &--opened {
      transform: translateX(-24px);
    }

    &__close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }

    &__header {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.06em;
      color: #6D7F92;

      &::first-letter {
        font-size: 18px;
        font-weight: 400;
      }
    }

    &__form-control {
      h6, p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.01em;
        color: #3C4E64;
      }

      p {
        font-weight: 300;
        margin-bottom: 2rem;
      }

      .unit-price {
        text-align: right;
        width: 100%;
        display: inline-block;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        color: #6D7F92;
      }

      .sms-counter {
        font-size: 22px;
        text-align: center;
        color: black;
        line-height: 26px;
        margin-top: 19px;
        span {
          color: $dodger-blue;
        }
      }
    }

    &__actions{
      margin-top: 4rem;
    }
  }
  .overlay {
    z-index: 2 !important;
  }

  .amount, .amount span {
    font-size: 1.3rem;
  }
  .table-head{
    border-bottom: 1px solid;
    font-size: medium;
    font-weight: bold;
  }
  .row-top{
    padding-top: 2rem;
  }
  .order-button{
    margin-top: 22px;
    width: 100%;
    border: 1px solid $dodger-blue;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(41, 57, 77, 0.1);
    border-radius: 4px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: $dodger-blue;
  }
  .menu-flags {
    width: 100%;
    margin-top: 1rem;
    img{
      vertical-align: unset;
    }
    .arrow-down{
      float: right;
    }
    .filterBox input{
      height: 2rem;
    }
    .flag-options{
      width: 100%;
    }
  }
  .input-price{
    position:relative;
    input{
      padding-left: 20px;
      padding-right: 20px;
      margin: 0;
    }
  }
  .static-value{
    position:absolute;
    right: 10px;
    top:9px;
  }
  .controls {
    padding: 2rem;
  }

  .icon-clock{
    color: $main-orange;
  }
  .display-unset {
    display: unset !important;
  }
  .campaign-actions {
    i {
      padding-left: 1.4rem;
      font-size: 1.1rem;
    }
  }

  .ttp-datatable {
    &__header {
      & > div {
        padding: 0 5px;
      }
    }

    &__row {
      & > div {
        padding: 0 5px;
      }

      .campaign-status {
        position: relative;
        font-weight: normal;
        font-size: 14px;
        background: #FFFFFF;
        border: 0.5px solid $athens-gray;
        border-radius: 50px;
        color: $slate-gray;
        box-shadow: none;
        text-transform: uppercase;
        padding: 4px 18px 4px 39px;

        .status-color, i {
          position: absolute;
          top: 50%;
          left: 18px;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
        }
        .status-color {
          border-radius: 50px;
          transition: left .2s, bottom .2s, height .2s, width .3s;
        }

        i {
          z-index: -1;
          color: #fff;
          height: auto;
        }
        .campaign-action {
          display: none;
        }
      }

      &:hover{
        background-color: $athens-gray;
        .show-actions {
          .status-color {
            z-index: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
          i {
            z-index: 1;
          }

          .status-label {
            display: none;
          }

          .campaign-action {
            display: inline-block;
            position: relative;
            color: #fff;
            z-index: 1;
          }
        }
      }
    }
  }
}
