.text-model-ebox {
  position: relative;
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 14px;
  padding: 13px;
  cursor: pointer;
  overflow: hidden;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #D8DDE2;

  .band {
    right: -25%;
    transition: right .2s;
    top: 4.5rem;
  }

  .toggleButton {
    background: #fff;
    border-radius: 50%;
  }

  &.current {
    background: #F3FAFF;
    border: 1px solid #12d2de;

    .band {
      right: .5rem;
      top: 4.5rem;
    }

    .toggleButton input {
      & + div::before, & + div::after {
        background: $dodger-blue;
      }

      & + div svg {
        stroke-width: 1;
        stroke: $dodger-blue;
      }
    }
  }

  &:hover {
    background: #F3FAFF;
    border: 1px solid #12d2de;

    .band {
      right: .5rem;
    }

    .toggleButton input {
      & + div::before, & + div::after {
        background: $dodger-blue;
      }

      & + div svg {
        stroke-width: 1;
        stroke: $dodger-blue;
      }
    }
  }

  &_header {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    height: 100px;
    overflow: hidden;

    &-name {
      font-weight: 500;
      font-size: 14px;
      margin-top: 1.5rem;
      color: $pickled-bluewood;
      line-height: 16px;
      text-transform: capitalize;
    }

    &-type {
        padding: 4px 0;
    }

    &-content {
      color: $slate-gray !important;
      margin: 5px 0 1.5rem 10px;
    }

    .info-label {
      font-size: 12px;
      line-height: 14px;
      text-transform: capitalize;
      color: $slate-gray;
    }

    .info-value {
      padding-left: 5px;
      color: $pickled-bluewood;
    }
  }

  &__app {
    top: 0;
    right: 0;
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(41, 57, 77, 0.1);
    border-radius: 2px;
    padding: 15px 17px;
  }

  .read-more {
    position: absolute;
    bottom: 0;
    color: #6D7F92;
    margin: 0 10px 10px;
    font-size: 12px;
    line-height: 14px;
    text-decoration: underline;

    .down svg {
      transform: rotate(180deg);
    }

    svg {
      height: 10px;
      width: 10px;
      margin: 0 4px;
    }
  }
}
