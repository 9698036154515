@import "./side-bar";
@import "./params/params-tab";
@import "./recipient/recipient-tab";
@import "./test/test-tab";
@import "./send/send-tab";

@import "./web/campaign-web";
@import "./text/campaign-text";
@import "./review/reviews-tab";
@import "./preview/preview";

$ep-bottom-bar-height: rem-calc(70px);

.campaign {
  min-height: calc(100vh - 154px);
  padding-bottom: 2rem;
}
#campaign-page {
  padding-top: 23px;
  background: #fafbfb;
  min-height: calc(100vh - 65px);
  .campaign-body {
    display: flex;
    align-items: flex-start;
    margin: 0 100px 0 23px;
    justify-content: center;
  }
  .empty-box, .empty-box input{
    background: #F8F9FA;
    color: rgba(109, 127, 146, 0.8);
    .ttp-select {
        &__control {
            background: #F8F9FA;
        }
    }
}
}

#campaign-page , #sms-campaign-page, #notification-page {
  position: relative;
  font-family: Roboto;
  font-weight: normal;

  .param__title {
    margin: 1rem 0 0.3rem;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #3C4E64;
    display: block;
    text-indent: 1.2rem;
    &:first-letter {
      text-transform: uppercase;
    }
  }

  input:disabled, input:read-only  {
    background: #F3FAFF;
  }

  input[type="text"] {
    box-shadow: none;
    font-size: .9rem;
    margin: 0;
  }

  .separator::before {
    content: ' ';
    display: block;
    width: 1px;
    height: 200%;
    margin-left: -1.5px;
    background: $light-gray;
  }

  .campaign-recipient-message{
    margin-left: 110px;
    font-size: .8rem;;
    i{
      margin-right: .4rem;
      font-size: .9rem;
    }
  }

  .bloc {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #F1F2F4;
    box-sizing: border-box;
    box-shadow: 0px 2px 20px rgba(41, 57, 77, 0.05);
    border-radius: 5px;
    padding: 25px;
    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-transform: uppercase;
      color: #2495E1;
    }
    &__sub-title {
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #8F9EAD;
      margin-bottom: 25px;
    }
  }
}

#sms-campaign-page {
  padding-bottom: $ep-bottom-bar-height + 2.2rem;
  min-height: calc(100vh - 154px);
}

div.Select-input {
  max-width: 1px;
}
