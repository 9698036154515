#campaign-test__tab {
  font-weight: normal;

  .inline-editor {
    box-sizing: border-box;
    cursor: text;
    border-bottom: 2px solid #F1F2F4;
    .DraftEditor-root {
      position: relative;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #6D7F92;
    }
    & :global(.public-DraftEditorPlaceholder-root) {
      color: #ddd;
      z-index: 0;
    }
  }

  .ttp-button{
    margin: 2rem 0 0;
  }

  .user-suggestion {
    padding: 0;
    border: 2px solid #efefef;
    border-radius: 4px;
    input {
      border: 0;
      background: #FAFBFB;
      color: #6D7F92;
    }
    a.react-tagsinput-remove {
      font-size: 1.2rem;
      color: #2495E1;
      margin-left: 280px;
      top: -20px;
      position: relative;
    }
    .react-tagsinput-tag{
      padding: .5rem 1rem 0 1rem;
      margin: 0.1rem 0;
    }
  }

  .detail {
    font-style: italic;
    font-size: .9rem;
  }

  .ttp-select {
    flex: auto;
  }

  .see-more {
    cursor: pointer;
    font-weight: 300;
    font-size: 12px;
    transition: 0.3s all ease-in-out;
    color: #18A0FB;
    float: right;
    .icon {
      margin-left: .3rem;
      font-size: .5rem;
    }
  }
  .approver-team {
    display: flex;
    &__avatar {
      span {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
        line-height: 2.5rem;
        margin-right: 0.5rem;
        border: 2px solid #fff;
        box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1), 0 0 0 1px rgba(44, 43, 63, 0.13);
      }
      .team-avatar{
        display: inline-block;
        color: #fff;
        text-align: center;
        margin-right: 1rem;
        border: 2px solid #fff;
        box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1), 0 0 0 1px rgba(44, 43, 63, 0.13);
        width: 3rem;
        height: 3rem;
        font-size: 1.4rem;
        line-height: 2.9rem;
        overflow: hidden;
        text-transform: uppercase;
        border-radius: 50%;
        font-weight: bold;
      }
    }
    &__detail {
      display: flex;
      flex-direction: column;
      span {
        font-size: .7rem;
        &:first-child {
          font-size: .9rem
        }
      }
    }
  }
}
