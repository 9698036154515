@import "./chart/PercentChart";
@import "./chart/Chart";
@import "./Filter/filter";

.dashboard {
  .chart-card {
    background: #FFFFFF;
    border: 1px solid #F1F2F4;
    border-radius: 5px;
  }
  .ttp-select{
    border-radius: 10% / 50%;
    background-color: #F1F2F4;
  }
  .dashboard_filter {
    background: #FFFFFF;
    border: 1px solid #F1F2F4;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 5px;
    padding-right: 26px;
    height: 71px;

    &__container {
      margin: 1rem 0;
      display: flex;

      .filter_date {
        display: flex;
        margin: 0% 2%;
      }

      .filter-select {
        margin-left: auto;

        .ttp-select__control {
          border-radius: 26px;
          background: #F1F2F4;
          text-transform: uppercase;
          text-align: center;
          padding-left: 10px;
          border: 0;
        }
      }
    }
  }

}
