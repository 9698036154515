@import "emails/approved-emails";

.default-settings-page {
  min-height: 100vh;

  .ttp-tab-container {
    .ttp-color-tab-selector nav {
      top: -24px;
    }

    .page-title {
      padding: 0 8rem;
    }

    .button-container {
      display: flex;
      float: right;
      padding-right: 7rem;
    }
  }

  #theme-picker {
    padding: 0 8rem 3rem;
  }
}

.form-control {
  position: relative;
}
.param__title {
  margin: 1rem 0 0.3rem;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #3C4E64;
  display: block;
  text-indent: 1.2rem;
  &:first-letter {
    text-transform: uppercase;
  }
}

.minus-add__actions {
  justify-content: flex-end;
  max-width: 68%;

  button {
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;

    &.success {
      background: $btn-success-border-color;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);

      &:hover {
        background: #02AF8E;
      }
    }
  }

  &-container {
    display: flex;
  }

  span {
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
    border-radius: 4px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .4s ease-in;
    margin-left: 11px;

    &:hover {

      svg path,
      svg rect {
        fill: #fff
      }
    }

    &.add {
      border: 0.5px solid #C7E8FE;

      &:hover {
        background: #18A0FB;
      }
    }

    &.minus {
      border: 0.5px solid rgba(254, 55, 69, 0.3);

      &:hover {
        background: #FE3745;
      }
    }

    &.minus__action {
      background: #FE3745;

      svg path,
      svg rect {
        fill: #fff
      }
    }
  }
}
