.theme-card {
  width: 200px;
  height: 260.73px;
  box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
  border-radius: 4px;
  margin: 0 auto 18px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border: 1.5px solid #FFFFFF;
  position: relative;
  overflow: hidden;

  .theme-card__status {
    background: rgba(255, 255, 255, 0.95);
    position: absolute;
    top: 198px;
    right: 0;
    left: 0;
    height: 100%;
    transition: top .3s;
    padding: 11px 15px;

    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
      color: #29394D;
      margin-bottom: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 16px;
    }

    &__tags {
      display: flex;
      span {
        height: 15px;
        background: #F1F2F4;
        border-radius: 2px;
        font-style: italic;
        font-weight: 300;
        font-size: 10px;
        line-height: 10px;
        padding: 3px 6px;
        margin-right: 4px;
        color: #6D7F92;
        min-width: 50px;
        display: flex;
        align-content: center;

        img {
          margin-right: 4px;
        }
      }
    }

    &__action {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      background: #18A0FB;
      box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
        width: 13px;
        height: 13px;
        filter: brightness(10);
        transition: width .3s, height .3s;
      }
      &:hover {
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &:hover {
    .theme-card__status {
      top: 0;

      &__title {
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
        margin-bottom: 10px;
      }
    }

    .apply-button {
      bottom: 0;
    }
  }
}
